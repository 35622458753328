import { useState } from 'react'
import styles from './styles.module.css'
import { useEventsListener, useResizeWindow } from '@/core/hooks'
import { Drawer } from 'antd'
import { Icon } from '@/core/components'
import { FaqQuestion } from '@/modules/FAQ/components'
import { transaltesFAQSConfig } from '@/modules/FAQ/config'
import { useLanguageStore } from '@/store'
import { useTranslation } from 'react-i18next'

export const FaqsListWidget = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const { width: widthWindow } = useResizeWindow()
    const { lang } = useLanguageStore()

    useEventsListener(
        'contactUs',
        data => {
            setOpen(data?.isShow)
        },
        [],
    )

    const drawerWidth = () => {
        switch (true) {
            case widthWindow < 1024:
                return '100dvw'

            default:
                return '720px'
        }
    }

    const sendEmail = () => {
        window.location.href = `mailto:support@safetravy.com`
    }
    return (
        <Drawer
            size='large'
            destroyOnClose
            placement='right'
            closable={false}
            open={open}
            classNames={{ body: styles.body }}
            styles={{
                body: {
                    overflow: 'hidden',
                    padding: 0,
                },
                content: {
                    borderRadius: widthWindow < 768 ? 0 : 24,
                    backdropFilter: 'blur(24px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                },
                wrapper: {
                    width: drawerWidth(),
                    borderRadius: 24,
                    padding: widthWindow > 767 ? '8.5px 8px' : 0,
                    boxShadow: 'none',
                },
            }}
            onClose={() => setOpen(false)}>
            <div className={styles.header}>
                <p className={styles.title}>
                    {widthWindow < 768 ? t('titleFAQ') : 'FAQ'}
                </p>

                <Icon
                    name='x'
                    size={22}
                    color='#5E626D'
                    onClick={() => setOpen(false)}
                />
            </div>
            <div className={styles.content}>
                <div className={styles.list}>
                    {transaltesFAQSConfig[lang].map(it => (
                        <FaqQuestion
                            idCard={it.id}
                            question={it.question}
                            answer={it.answer}
                        />
                    ))}
                </div>
                {/* <div>
                    <p className={styles.main_info}>
                        SafeTravy is a startup offering all-in-one guidance
                        solution for travelers. Our services are located on the
                        website platform showcasing informative content in the
                        areas of climate, social and medical situations around
                        the world as well as visa and entry requirements.
                    </p>

                    <p color='#414244' style={{ marginBottom: 16 }}>
                        We collaborate with international partners such as
                        Riskline to provide up-to-date data. Besides informative
                        content SafeTravy offers personalized AI assistance for
                        every type of travel and Push-notifications covering
                        emergency alerts in the location of our customers.
                    </p>

                    <p color='#414244' style={{ marginBottom: 16 }}>
                        Our platform works on a B2C model only and a monthly &
                        quarterly subscription basis with trial period. Our
                        payment system mediator partner is Chargebee company.
                    </p>

                    <p color='#414244' style={{ marginBottom: 16 }}>
                        Avalara is our partner covering tax compliance issues.
                    </p>

                    <p color='#414244' style={{ marginBottom: 16 }}>
                        Besides all of this SafeTravy also has a social purpose.
                        Our first social purpose lays in helping talented
                        children affected by war in Ukraine (orphans and
                        temporarily displaced children). It will be resulted in
                        tuition fees and annual grant payments for education.
                        And the second social purpose is helping to deliver
                        drinking water to countries in need.
                    </p>
                </div> */}
            </div>
            {/* <ContactUs /> */}
            <div className={styles.contact_us_container}>
                <p className={styles.contact_us_title}>{t('contactUsTitle')}</p>
                <div>
                    <p onClick={sendEmail} className={styles.contact_us_item}>
                        support@safetravy.com
                    </p>
                </div>
            </div>
        </Drawer>
    )
}
