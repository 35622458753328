/* eslint-disable no-console */
import io, { Socket } from 'socket.io-client'
import { storageService } from './storage.service'
import { config } from '@/config'
import { socketEvents, SocketEvents } from '../events'
import * as Sentry from '@sentry/react'

export class SocketIo {
    private socket: Socket
    init() {
        try {
            this.socket = io(config.SOCKET_URL, {
                transports: ['websocket', 'polling'],
                reconnection: true,
                secure: true,
                reconnectionAttempts: 10,
                auth(cb) {
                    cb({
                        accessToken: storageService.get('accessToken'),
                    })
                },
            })
            this._on('connect', () => {
                console.log('CONNECT')
                // this.initSockets()
            })
            this.initSockets()
        } catch (e) {
            Sentry.captureException(e)
            console.log('ERROR')
        }
    }
    get header() {
        return {
            authorization: 'Bearer ' + storageService.get('accessToken'),
        }
    }
    _isConnected() {
        return this.socket?.connected
    }
    _on(key: string, action: (data: unknown) => void) {
        this.socket.on(key, (data: unknown) => {
            action(data)
        })
    }

    _off(key: string) {
        this.socket.off(key)
    }
    _disconnect() {
        this.socket.disconnect()
    }
    _connect() {
        this.socket.connect()
    }
    emit(key: string, data: Record<string, unknown> = {}) {
        data.headers = this?.header
        this.socket.emit(key, data)
    }
    _onSocketSendEvent(key: keyof SocketEvents) {
        this.socket.on(key, (data: SocketEvents[typeof key]) => {
            socketEvents.emit(key as keyof SocketEvents, data)
        })
    }
    initSockets() {
        this._onSocketSendEvent('notification/new-notification'),
            this._onSocketSendEvent('stopSessions'),
            this._onSocketSendEvent('subscription/cancelled'),
            this._onSocketSendEvent('subscription/changed'),
            this._onSocketSendEvent('subscription/new')
        this._onSocketSendEvent('subscription/switch-product-application')
    }
}

export const realTimeService = new SocketIo()
