import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import { MostPopularAtom } from '../popular'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'
import { themeTariff } from '@/modules/subscribe/configs/advantes.config'

interface IProps {
    typeTariff: string
    price: string
    typePayment: string
    isPrimary: boolean
    typeSubscription: SubscriptionType
}
export const HeadertariffAtom = ({
    typePayment,
    price,
    typeTariff,
    isPrimary,
    typeSubscription,
}: IProps) => {
    const theme = themeTariff[typeSubscription]

    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            {isPrimary ? <MostPopularAtom /> : null}
            <div className={styles.row_title}>
                <img src={theme.icon} className={styles.img_tariff} />

                <div className={styles.tariff_column}>
                    <p
                        className={styles.trial}
                        style={{ color: theme.secondaryTxt }}>
                        {t('freeTrial')}
                    </p>
                    <p
                        className={styles.tariff}
                        style={{ color: theme.txtColor }}>
                        {typeTariff}
                    </p>
                </div>
            </div>
            <div className={styles.row_price}>
                <p className={styles.price} style={{ color: theme.txtColor }}>
                    €{price}
                </p>
                <div>
                    <p className={styles.vat} style={{ color: theme.vat }}>
                        +{t('vat')}
                    </p>
                    <p
                        className={styles.type_payment}
                        style={{ color: theme.secondaryTxt }}>
                        /{typePayment}
                    </p>
                </div>
            </div>
        </div>
    )
}
