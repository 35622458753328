import { Icon } from '@/core/components'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'
import { useAccountStore } from '@/store'
import { useResizeWindow } from '@/core/hooks'
import { SubscriptionStatus, SubscriptionType } from '../../typing/enums'

interface IProps {
    onClick: () => void
}
export const SubscriptionPlansBtnWhite = ({ onClick }: IProps) => {
    const { t } = useTranslation()
    const { account } = useAccountStore()
    const isTrial = account?.subscription?.status === SubscriptionStatus.Trial
    const isBasic =
        account?.subscription?.type === SubscriptionType.Basic ||
        account?.subscription?.type === SubscriptionType.Basic_3m
    const rightPositionMobile =
        !account?.subscription || (isBasic && !isTrial) ? 100 : 200
    const rightPositionWeb =
        !account?.subscription || (isBasic && !isTrial) ? 16 : 82
    const { width: windowWidth } = useResizeWindow()
    const positionBtn = () => {
        if (windowWidth < 768) {
            return rightPositionWeb
        } else {
            return rightPositionMobile
        }
    }
    return (
        <div
            className={styles.container}
            onClick={onClick}
            style={{ right: positionBtn() }}>
            <Icon
                name='magic-star'
                size={21}
                color='#FFFFFF'
                className={styles.icon_container}
            />
            <div>
                <p className={styles.label}>{t('subscriptionPlans')}</p>
                <p className={styles.sub_label}>
                    {t('exploreNow')}{' '}
                    <div className={styles.arrow_up}>
                        <Icon name='arrow-right' size={18} color='#AFB1B6' />
                    </div>
                </p>
            </div>
        </div>
    )
}
