export const PolicyEsp = () => {
    return (
        <>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                Esta Política de privacidad (esta "Política") se aplica a la
                plataforma de negociación (incluidas las aplicaciones móviles y
                los sitios web aplicables utilizados para acceder a la misma)
                (colectivamente, la "Plataforma") proporcionada por SafeTravy
                Limited (la "Empresa", "nosotros", "nos" o "nuestro"). En ella
                se describe el modo en que la Empresa recopila, utiliza y
                divulga los Datos Personales que obtenemos de los Usuarios de la
                Plataforma y de cualquier servicio de cuenta prestado a través
                de la Plataforma, así como el modo en que utilizamos y
                divulgamos dicha información. A efectos de la presente Política,
                por "Datos Personales" se entiende la información facilitada por
                un Usuario a partir de la cual puede determinarse, directa o
                indirectamente, la identidad de dicho Usuario.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                Al registrarse y utilizar la Plataforma, usted acepta que sus
                Datos Personales se traten como se describe en la presente
                Política y en los Términos y Condiciones aplicables a la
                Plataforma (el "Acuerdo de Servicio"); los términos en mayúscula
                utilizados en el presente documento tendrán el mismo significado
                que se establece en el Acuerdo de Servicio. La presente Política
                de Privacidad forma parte integrante de los
                <a href='https://docs.google.com/document/d/1g898YKsPRQ90WPHnsodOANKIGjWLUUpcJAGZ_WWFO84/edit'></a>
                <a href='https://docs.google.com/document/d/1g898YKsPRQ90WPHnsodOANKIGjWLUUpcJAGZ_WWFO84/edit'>
                    <u>Términos y Condiciones de SafeTravy</u>
                </a>
                .
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>¿Qué datos recogemos?</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                SafeTravy recopila los siguientes datos:
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Datos de <b>identificación personal </b>(nombre, dirección de
                correo electrónico, nacionalidad, etc.)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Información de <b>contacto </b>(dirección de correo electrónico)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Información
                <b>financiera </b>(datos bancarios)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Información sobre <b>transacciones </b>(datos sobre pagos
                efectuados por usted o recibidos de usted y otros datos sobre
                productos y servicios que nos haya comprado)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Información
                <b>técnica </b>(dirección del protocolo de Internet (IP), datos
                de inicio de sesión, tipo y versión del navegador, configuración
                y ubicación de la zona horaria, sistema operativo y plataforma y
                otras tecnologías de los dispositivos que utiliza para acceder a
                nuestros sitios web, productos y servicios).
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Información
                <b>sobre su perfil </b>(nombre de usuario y contraseña,
                suscripciones, intereses, preferencias, opiniones y respuestas a
                encuestas).
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Información de <b>uso </b>(incluye cómo utiliza nuestro sitio
                web, productos y servicios)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Información de <b>marketing y comunicaciones </b>(sus
                preferencias a la hora de recibir información de marketing de
                nuestra parte y sus preferencias de comunicación)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Información agregada (datos estadísticos o demográficos. Los
                datos agregados podrían derivarse de sus datos personales, pero
                no se consideran datos personales desde el punto de vista legal,
                ya que estos datos no revelarán directa ni indirectamente su
                identidad. Por ejemplo, PODRÍAMOS agregar sus Datos de uso para
                calcular el porcentaje de usuarios que acceden a una función
                específica del sitio web. Sin embargo, si combinamos o
                conectamos los Datos Agregados con sus datos personales de forma
                que puedan identificarle directa o indirectamente, trataremos
                los datos combinados como datos personales que se utilizarán de
                acuerdo con esta política de privacidad)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>
                    No recogemos ninguna Categoría Especial de Datos Personales{' '}
                </b>
                sobre usted (esto incluye detalles sobre su raza o etnia,
                creencias religiosas o filosóficas, vida sexual, orientación
                sexual, opiniones políticas, afiliación sindical, información
                sobre su salud y datos genéticos y biométricos). Tampoco
                recopilamos información sobre condenas y delitos penales.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>¿Cómo recogemos sus datos?</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Usted proporciona directamente a SafeTravy la mayoría de los
                datos que recopilamos. Recogemos datos y los procesamos cuando
                usted:
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                ● Regístrese en línea o solicite cualquiera de nuestros planes
                de suscripción.
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                ● Rellene voluntariamente una encuesta de clientes o envíe sus
                comentarios en cualquiera de nuestros tablones de anuncios o por
                correo electrónico.
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                ● Utilizar o ver nuestro sitio web a través de las cookies de su
                navegador.
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                ● Suscribirse a un servicio, publicación o boletín, o solicitar
                que se le envíe publicidad.
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                ● Póngase en contacto con nosotros directamente a través de
                nuestro sitio web, correo electrónico, teléfono u otros medios
                de comunicación.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                SafeTravy también recibirá sus datos indirectamente de las
                siguientes fuentes:
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                ● Tecnologías o interacciones automatizadas: A medida que
                interactúe con nuestro sitio web, productos y servicios,
                recopilaremos automáticamente Datos técnicos sobre su equipo,
                acciones de navegación y patrones. Recopilamos estos datos
                personales utilizando cookies, registros de servidor y otras
                tecnologías similares.
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                ● Datos técnicos de:
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                - proveedores de análisis, como Google, con sede fuera de la
                Unión Europea (UE).
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                - hosting y otros proveedores de servicios como Amazon con sede
                fuera de la UE.
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                ● Datos de contacto, financieros y de transacciones de
                proveedores de servicios técnicos, de pago y de entrega.
            </p>
            <p
                style={{
                    marginLeft: '0.75in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Cómo utilizamos su información</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Utilizamos su información, incluidos sus Datos Personales, para
                los siguientes fines:
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                ● Para proporcionarle nuestra Plataforma, facilitar las
                comunicaciones y transacciones en la Plataforma, comunicarnos
                con usted acerca de su uso de nuestra Plataforma, responder a
                sus consultas, cumplir con sus pedidos y para otros fines de
                servicio al cliente;
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                ● Para adaptar el contenido y la información que podemos
                enviarle o mostrarle, para ofrecer personalización de ubicación,
                y ayuda e instrucciones personalizadas, y para personalizar de
                otro modo sus experiencias mientras utiliza nuestra Plataforma;
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                ● Para comprender mejor cómo los usuarios acceden y utilizan la
                Plataforma, tanto de forma agregada como individualizada, con el
                fin de mejorar nuestra Plataforma y responder a los deseos y
                preferencias de los usuarios, así como para otros fines de
                investigación y análisis;
            </p>
            <p
                style={{
                    marginLeft: '0.5in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                ● Con fines promocionales y de marketing. Por ejemplo, podemos
                utilizar su información, como su dirección de correo electrónico
                o número de contacto registrado con nosotros, para enviarle
                noticias y boletines, ofertas especiales y promociones, para
                realizar sorteos y concursos, o para ponernos en contacto con
                usted sobre productos o información que pensamos que pueden
                interesarle. También podemos utilizar la información que
                obtenemos sobre usted para publicitar nuestra Plataforma en
                sitios web de terceros.
            </p>
            <p
                style={{
                    marginLeft: '0.75in',
                    textIndent: '-0.25in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Cómo compartimos su información</b>
            </p>
            <ul>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Afiliadas. Podemos divulgar la información que
                        recopilamos de usted a nuestras filiales o subsidiarias
                        con el único fin de proporcionarle la Plataforma o
                        servicios relacionados, incluidos los servicios de
                        subsitios; sin embargo, si lo hacemos, el uso y la
                        divulgación de su información de identificación personal
                        serán mantenidos por dichas filiales y subsidiarias de
                        conformidad con esta Política.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Proveedores de servicios. Podemos revelar la información
                        que recopilamos de usted a terceros vendedores,
                        proveedores de servicios, contratistas o agentes que
                        desempeñan funciones en nuestro nombre, siempre que
                        dichos terceros hayan acordado utilizar dicha
                        información únicamente para prestarnos servicios.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Transferencias comerciales. Si estamos en negociaciones
                        con otra empresa o entidad, o somos adquiridos por otra
                        empresa o entidad o nos fusionamos con ella, si se
                        transfieren sustancialmente todos nuestros activos a
                        otra empresa o entidad, o como parte de un procedimiento
                        de quiebra, podemos transferir la información que hemos
                        recopilado de usted a la otra empresa o entidad.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        En respuesta a un proceso legal. También podemos
                        divulgar la información que recopilamos sobre usted para
                        cumplir con la ley, un procedimiento judicial, una orden
                        judicial u otro proceso legal, como en respuesta a una
                        citación.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Para protegernos a nosotros y a otros. También podemos
                        revelar la información que recopilamos de usted si
                        creemos que es necesario investigar, prevenir o tomar
                        medidas en relación con actividades ilegales, sospechas
                        de fraude, situaciones que impliquen amenazas
                        potenciales para la seguridad de cualquier persona,
                        violaciones de nuestro Contrato de Servicio o de esta
                        Política, o como prueba en litigios en los que estemos
                        implicados.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Información agregada y no identificada. Podemos
                        recopilar, procesar, analizar y compartir información
                        agregada o desidentificada sobre los usuarios con
                        terceros y públicamente para el desarrollo de productos,
                        marketing, publicidad, investigación o fines similares.
                    </p>
                </li>
            </ul>
            <p
                style={{
                    marginLeft: '1in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Derechos de acceso, rectificación y supresión</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Puede acceder, editar, actualizar o eliminar su Cuenta o la
                Información Personal que hemos recopilado en cualquier momento
                accediendo a la configuración de su cuenta o enviándonos un
                correo electrónico a safetravy@gmail.com. Responderemos a sus
                solicitudes en un plazo razonable, pero no más tarde del plazo
                exigido por la ley.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                Tenga en cuenta que, a pesar de lo anterior, puede haber
                circunstancias en las que no podamos atender una solicitud de
                edición, actualización, acceso o eliminación del perfil de una
                cuenta o de Información Personal. Esto incluye, pero no se
                limita a:
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                ·cualquier fundamento por el que pueda denegarse dicha solicitud
                en virtud de la legislación aplicable;
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                ·cuando necesitemos conservar la información para cumplir las
                leyes federales, estatales o locales o con fines contables o
                fiscales;
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                ·cuando necesitemos cumplir con una indagación, investigación,
                citación o emplazamiento civil, penal o reglamentario por parte
                de las autoridades federales, estatales o locales;
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                ·cuando necesitemos cooperar con las fuerzas de seguridad en
                relación con conductas o actividades que la empresa, el
                proveedor de servicios o un tercero considere razonablemente y
                de buena fe que pueden infringir la legislación federal, estatal
                o local;
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                ·cuando necesitemos conservar información para ejercer o
                defender reclamaciones legales;
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                ·cuando la información contenga privilegios legales o
                información propiedad de terceros; o ·cuando el cumplimiento de
                la solicitud comprometa la intimidad u otros derechos legítimos
                de terceros.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                Si determinamos que no podemos responder a una solicitud en un
                caso concreto, le proporcionaremos una explicación de por qué se
                ha tomado esa determinación y un punto de contacto para
                cualquier consulta adicional. Para proteger su privacidad,
                tomaremos medidas comercialmente razonables para verificar su
                identidad antes de responder a cualquier solicitud en virtud de
                esta disposición, incluido el cumplimiento de cualquier
                requisito legal aplicable para verificar su identidad.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Transferencia de información</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Tenga en cuenta que podemos almacenar sus Datos Personales en
                servidores ubicados en cualquier tercer país. Si dicha
                jurisdicción se encuentra fuera de su jurisdicción de
                residencia, usted consiente la transferencia de su Información
                Personal a dicha jurisdicción con el fin de proporcionarle la
                Plataforma, incluso si dicha jurisdicción tiene menos
                protecciones para la Información Personal que su jurisdicción de
                residencia. Nos aseguraremos de que las disposiciones de
                seguridad sean coherentes con nuestras obligaciones de mantener
                la seguridad de su Información Personal bajo las leyes de su
                jurisdicción de residencia.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Retención</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Conservaremos sus Datos Personales mientras usted mantenga una
                Cuenta en la Plataforma. Dejaremos de conservar sus Datos
                Personales, o eliminaremos los medios por los que los Datos
                Personales pueden asociarse a personas concretas, en cuanto sea
                razonable suponer que -.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                (a) la finalidad para la que se recogieron los Datos Personales
                ya no se consigue con su conservación; y
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                (b) su conservación ya no es necesaria para fines legales,
                contables o comerciales.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Tenga en cuenta que determinadas leyes pueden obligarnos a
                conservar los registros de transacciones o cuentas durante un
                determinado periodo de tiempo.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Enlaces de terceros</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Nuestra Plataforma puede contener enlaces a sitios web de
                terceros. El acceso y uso de dichos sitios web enlazados no se
                rige por la presente Política, sino por las políticas de
                privacidad de dichos sitios web de terceros. No somos
                responsables de las prácticas de información de dichos sitios
                web de terceros.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Menores de 18 años</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Nuestra Plataforma no está diseñada para menores de 18 años. Si
                descubrimos que un menor de 18 años nos ha proporcionado
                Información Personal, eliminaremos dicha información de nuestros
                sistemas.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Cambios en nuestra política de privacidad</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Esta Política está vigente a partir de la Fecha de entrada en
                vigor indicada anteriormente. Podemos cambiar esta Política de
                vez en cuando, así que por favor asegúrese de comprobarla
                periódicamente. Publicaremos cualquier modificación de esta
                Política en la Plataforma. Si realizamos algún cambio en esta
                Política que afecte materialmente a nuestras prácticas con
                respecto a la Información Personal que hayamos recopilado
                previamente de usted, nos esforzaremos por avisarle con
                antelación de dicho cambio destacando el cambio en nuestra
                Plataforma o proporcionando una notificación push a través del
                Sitio (debe asegurarse de que la configuración de su Sitio
                permite dichas notificaciones push) o enviando un correo
                electrónico que haya proporcionado en su Cuenta, (por este
                motivo, debe asegurarse de actualizar la información de su
                cuenta rápidamente si cambia).
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Cómo ponerse en contacto con nosotros</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Si tiene alguna pregunta sobre la política de privacidad de
                SafeTravy, los datos que tenemos sobre usted o desea ejercer
                alguno de sus derechos de protección de datos, no dude en
                ponerse en contacto con nosotros:
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                por correo electrónico support@safetravy.com.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}></p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}></p>
            <p style={{ marginBottom: '0in', lineHeight: '115%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '115%' }}>
                <br />
            </p>
        </>
    )
}
