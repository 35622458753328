import { useTranslation } from 'react-i18next'
import styles from './style.module.css'
import { Modal } from 'antd'
import { PrimaryButton } from '@/core/components'

interface IProps {
    isOpen: boolean
    close: () => void
}
export const UnpaidSubscriptionModal = ({ isOpen, close }: IProps) => {
    const { t } = useTranslation()
    return (
        <Modal
            closeIcon={null}
            footer={null}
            classNames={{ content: styles.container }}
            styles={{ body: { width: '100%' } }}
            open={isOpen}>
            <div className={styles.content_modal}>
                <p className={styles.title}>{t('labelUnpaidSubscription')}</p>
                <p className={styles.secondary_txt}>
                    {t('unpaidSubscription')}
                </p>

                <PrimaryButton
                    label={'Ok'}
                    onClick={close}
                    className={styles.confirm_btn}
                />
            </div>
        </Modal>
    )
}
