export const TermsEnglish = () => {
    return (
        <>
            <p
                style={{
                    textAlign: 'center',
                    lineHeight: '115%',
                    fontSize: '13pt',
                }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Terms and Conditions of SafeTravy
                    </span>
                </strong>
            </p>
            <p></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    These Terms and Conditions (the “Terms” or this “Agreement”)
                    govern the use of the electronic platform, including any
                    website or mobile application (the “App”, together with the
                    website, the “Site”) for accessing the platform, and any
                    services provided through the platform (collectively, the
                    “Platform”) provided by SafeTravy Limited (the “Company”,
                    “we”, “us” or “our”).
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The Terms form a binding agreement between the Company and
                    you, as an individual user (“you”, “your” or “User”) for
                    your individual usage of the App and Platform. By
                    registering for and downloading the App and using the
                    Platform, you confirm your acceptance of this Agreement and
                    our associated Privacy Policy.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    If you do not agree to these Terms, you must immediately
                    uninstall the App and cease using the App and the Platform.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Securities Disclaimer: No material or any other information
                    that may be made available on the Site or Platform shall
                    constitute or be construed as a recommendation, endorsement,
                    offer, invitation or solicitation to you to make any
                    decision, all made decisions are made out of your own will
                    and experience. You further understand that none of the
                    information providers, including any Third-Party Providers
                    (as defined below) are advising you personally concerning
                    the nature, potential, value or suitability of any
                    particular security questions regarding your travels. Please
                    note that the situation in the world changes rapidly, so any
                    information, content, Third-Party Content (as defined below)
                    or other material provided on or through the Site or
                    Platform may not be complete or current, or may be
                    superseded by more current information. You rely on such
                    information at your own risk.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Our Site and Platform are not intended to provide legal,
                    insurance, or investment advice, and nothing on the Site or
                    Platform should be construed as an offer to you on how to
                    organize your travel. All this information is only
                    personalized recommendation.{' '}
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        1. Applicability
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    1.1. We, SafeTravy Limited (hereinafter “SafeTravy”), offer
                    services in the field of IT entertainment These general
                    terms and conditions apply to the contractual relationship
                    between SafeTravy and the Customer (“Customer” or “You”)
                    with respect to all services we provide.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        2. Definitions
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Account{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    means the account established by a User that has downloaded
                    the App or accessed the Site and registered with the Company
                    to use the Site and the Platform.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>App</span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    {' '}
                    means the mobile application provided by the Company to
                    access the Platform.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Authorized Individual{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    means any person that is authorized to access and use the
                    Site (including the App) and Platform on behalf of a User.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Digital Platforms
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    {' '}
                    refers to third-party distribution platforms where mobile
                    applications or other software programs can be accessed or
                    downloaded, including, but not limited to, the Apple App
                    Store and Google Play.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Governmental Authority
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    {' '}
                    means any nation or government or any province or state or
                    any other political subdivision thereof, or any entity,
                    authority or body exercising executive, legislative,
                    judicial, regulatory or administrative functions of or
                    pertaining to government, including any government
                    authority, agency, department, board, commission or
                    instrumentality or any political subdivision thereof, any
                    court, tribunal or arbitrator, and any self-regulatory
                    organization.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Material{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    means any offering material, term sheet, research report,
                    product or service documentation or any other information
                    provided through the Platform.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Personal Information{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    refers to information supplied by a User from which the
                    identity of such User may be directly or indirectly
                    ascertained.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Privacy Policy
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    {' '}
                    means the additional terms and conditions governing the
                    collection, use and disclosure of each User’s Personal
                    Information, as set out here. Each User must read and agree
                    to the Privacy Policy in order to use the App or the Site.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Service Notifications
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    {' '}
                    are one-way notifications from the Company (which may
                    include security-related notifications) via text message or
                    emails and, where applicable, push notifications through the
                    Site.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    These notifications are sent to the User in respect of
                    certain information or events relating to an account to
                    which an User has access through the Platform.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        User
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    {' '}
                    means any person that has registered with the Company to use
                    the Site and access the Platform and any Authorized
                    Individual acting on their behalf.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        User Credentials
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    {' '}
                    means the set of user identification, password, personal
                    identification number, token and any other information or
                    device provided to an User to access the Platform.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span
                        style={{
                            fontFamily: '"Times New Roman"',
                            backgroundColor: '#ffffff',
                        }}>
                        Company
                    </span>
                </strong>
                <span
                    style={{
                        fontFamily: '"Times New Roman"',
                        backgroundColor: '#ffffff',
                    }}>
                    {' '}
                    (referred to as either "the Company", "We", "Us" or "Our" in
                    this Agreement) refers to SAFETRAVY LIMITED, Suite 4, 2nd
                    floor, the West Wing, Montarik House, 3 Bedlam Court,
                    Gibraltar, GX11 1AA, GIBRALTAR, GIBRALTAR.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Device
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    {' '}
                    means any device that can access the Service such as a
                    computer, a cellphone or a digital tablet.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Free Trial{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    refers to a limited period of time that may be free when
                    purchasing a Subscription.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Service{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    refers to all the information, recommendations and
                    notifications provided by the website.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Subscriptions
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    {' '}
                    refer to the services or access to the Service offered on a
                    subscription basis by the Company to You.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Terms and Conditions{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (also referred as "Terms") mean these Terms and Conditions
                    that form the entire agreement between You and the Company
                    regarding the use of the Service.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Third-party
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    {' '}
                    Service means any services or content (including data,
                    information, products or services) provided by a third-party
                    that may be displayed, included or made available by the
                    Service.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Website
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    {' '}
                    refers to SafeTravy, accessible from www.safetravy.com
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Changes
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    We reserve the right at any time to:
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (a) modify, update or change the terms and conditions of
                    this Agreement or our Privacy Policy ;{' '}
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (b) modify, update, or change the Site and Platform,
                    including eliminating or discontinuing any content or
                    feature of the Site or Platform (all of the foregoing
                    referred to as “Changes”).
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    We may make such Changes at any time without prior notice.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Any Changes to this Agreement may be posted on our website
                    or notified to you through push notifications through the
                    Site or an email to the email address in your Account. For
                    this reason, you should check our website regularly, allow
                    the Site to receive such push notifications, and keep your
                    email address and other contact information up to date in
                    the Account. You accept any Changes if you continue to use
                    the Site and Platform after such Changes are effected.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Digital Platform Terms
                    </span>
                </strong>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The App may be available for download from one or more
                    Digital Platforms. Your download, installation, access to or
                    use of the App is also bound by the terms and conditions and
                    privacy policies of the applicable Digital Platform (the
                    “Digital Platform Terms”). If there is any conflict between
                    these Terms and the Digital Platform Terms, then these Terms
                    will prevail.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The App is independent of and is not associated, affiliated,
                    sponsored, endorsed or in any way linked to any Digital
                    Platform. You and we acknowledge that this Agreement is
                    entered into between you and us only, and not with any
                    Digital Platform, and we, not the Digital Platform, are
                    solely responsible for the App and the content thereof to
                    the extent specified in this Agreement.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You and we acknowledge and agree that the relevant Digital
                    Platform, and that Digital Platform’s subsidiaries are
                    third-party beneficiaries of these Terms, and that, upon
                    your acceptance of these Terms, that Digital Platform will
                    have the right (and will be deemed to have accepted the
                    right) to enforce these Terms against you as a third-party
                    beneficiary thereof.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Network Device and Carrier Requirements
                    </span>
                </strong>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You acknowledge that your agreement with your mobile and
                    Internet network provider (the “Network Provider”) will
                    apply to your use of the Site. You acknowledge that you may
                    be charged by your Network Provider for data services while
                    using certain features of the Site or any other third-party
                    charges as may arise and you accept sole responsibility for
                    such charges. If you are not the bill payer for the
                    mobile/Internet device being used to access the Site, you
                    will be assumed to have received permission from the bill
                    payer for using the Site. You must also ensure that your use
                    of the Site is not in violation of your mobile or Internet
                    device agreement or any wireless data service agreement.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Eligibility and Registration
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You must be at least 18 years of age to access and use the
                    Site and Platform. You further affirm that you are fully
                    able and competent to enter into the terms, conditions,
                    obligations, affirmations, representations, and warranties
                    set forth in these Terms, and to abide by and comply with
                    these Terms. You must register with the Company to use the
                    Site and the Platform; you agree to provide complete and
                    accurate information when registering to use the Site and
                    the Platform, and to keep that information updated.{' '}
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    We have the sole discretion to accept or reject your
                    registration with the Platform. Only Users whose
                    registrations are approved by us will be our customers.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Acknowledgment
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    These are the Terms and Conditions governing the use of this
                    Service and the agreement that operates between You and the
                    Company. These Terms and Conditions set out the rights and
                    obligations of all users regarding the use of the Service.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Your access to and use of the Service is conditioned on Your
                    acceptance of and compliance with these Terms and
                    Conditions. These Terms and Conditions apply to all
                    visitors, users and others who access or use the Service.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    By accessing or using the Service You agree to be bound by
                    these Terms and Conditions. If You disagree with any part of
                    these Terms and Conditions then You may not access the
                    Service.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You represent that you are over the age of 18. The Company
                    does not permit those under 18 to use the Service.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Your access to and use of the Service is also conditioned on
                    Your acceptance of and compliance with the Privacy Policy of
                    the Company. Our Privacy Policy describes Our policies and
                    procedures on the collection, use and disclosure of Your
                    personal information when You use the Application or the
                    Website and tells You about Your privacy rights and how the
                    law protects You. Please read Our Privacy Policy carefully
                    before using Our Service.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>&nbsp;</p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Subscriptions
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Subscription period
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The Service or some parts of the Service are available only
                    with a paid Subscription. You will be billed in advance on a
                    recurring and periodic basis (such as daily, weekly, monthly
                    or annually), depending on the type of Subscription plan you
                    select when purchasing the Subscription.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The Parties (Company and User) agree and understand that the
                    price of the Subscription is exclusive of taxes (VAT or
                    other taxes payable in the country of residence of the
                    User). The User hereby agrees that in cases provided for by
                    local law, in addition to paying the Subscription price, the
                    User shall pay the amount of tax levied in connection with
                    such purchase of the Subscription.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    At the end of each period, Your Subscription will
                    automatically renew under the exact same conditions unless
                    You cancel it or the Company cancels it.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Subscription Update
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    If the User uses one type of paid subscription and upgrades
                    it to another type of subscription (increases the volume of
                    the Service or the term of use of the Service), the Parties
                    hereby agree and the User and the Company agree in advance
                    that the User shall pay an additional 0.99 (ninety-nine)
                    euro cents excluding taxes (taxes are charged and paid
                    additionally) for each month of such subscription,
                    regardless of the moment of such upgrade.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    For example: The User has a paid subscription at the Basic
                    tariff for a period of one month, and after five days of
                    using such subscription, the User upgrades this subscription
                    to the Extra subscription, in which case, upon payment of
                    0.99 (ninety-nine) euro cents excluding taxes (taxes are
                    charged and paid additionally), the User will receive access
                    to all services of the Extra subscription for the period
                    until the end of the prepaid Basic subscription.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    If the User upgrades the Basic subscription paid for 3
                    months, the User shall pay the Company an additional 2.97
                    (two euros and ninety-seven euro cents) for such an upgrade.
                    The same applies to the upgrade of subscriptions paid for
                    other terms.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The Parties also agree that if the User switches from a
                    Subscription with a larger amount of Service to a
                    Subscription with a smaller amount of Service, the User will
                    receive the entire amount of Service until the end of the
                    paid term of the Subscription with the larger amount of
                    Service, and the Company will not make any refunds or
                    credits to the User in this case.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Subscription cancellations
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You may cancel Your Subscription renewal either through Your
                    Account settings page or by contacting the Company. You will
                    not receive a refund for the fees You already paid for Your
                    current Subscription period and You will be able to access
                    the Service until the end of Your current Subscription
                    period.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Billing
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You shall provide the Company with accurate and complete
                    billing information including full name, address, state,
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    and valid payment method information.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Should automatic billing fail to occur for any reason, the
                    Company will issue an electronic invoice indicating that you
                    must proceed manually, within a certain deadline date, with
                    the full payment corresponding to the billing period as
                    indicated on the invoice.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Fee Changes
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The Company, in its sole discretion and at any time, may
                    modify the Subscription fees. Any Subscription fee change
                    will become effective at the end of the then-current
                    Subscription period.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The Company will provide You with reasonable prior notice of
                    any change in Subscription fees to give You an opportunity
                    to terminate Your Subscription before such change becomes
                    effective.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Your continued use of the Service after the Subscription fee
                    change comes into effect constitutes Your agreement to pay
                    the modified Subscription fee amount.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Clause for Refunds
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Except when required by law, paid Subscription fees are
                    non-refundable.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Certain refund requests for Subscriptions may be considered
                    by the Company on a case-by-case basis and granted at the
                    sole discretion of the Company.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Free Trial
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The Company may, at its sole discretion, offer a
                    Subscription with a Free Trial for a limited period of time.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You may be required to enter Your billing information in
                    order to sign up for the Free Trial.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    If You do enter Your billing information when signing up for
                    a Free Trial, You will not be charged by the Company until
                    the Free Trial has expired. On the last day of the Free
                    Trial period, unless You cancel Your Subscription, You will
                    be automatically charged the applicable Subscription fees
                    for the type of Subscription You have selected.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    At any time and without notice, the Company reserves the
                    right to (i) modify the terms and conditions of the Free
                    Trial offer, or (ii) cancel such Free Trial offer.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        User Accounts
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    In order to use the services on the Platform, you must
                    create an account with the Platform (the “Account”).
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The Account may be registered by any individual who is over
                    18 years old.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    When You create an account with Us, You must provide Us
                    information that is accurate, complete, and current at all
                    times. Failure to do so constitutes a breach of the Terms,
                    which may result in immediate termination of Your account on
                    Our Service.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You are responsible for safeguarding the password that You
                    use to access the Service and for any activities or actions
                    under Your password, whether Your password is with Our
                    Service or a Third-Party Service.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You agree not to disclose Your password to any third party.
                    You must notify Us immediately upon becoming aware of any
                    breach of security or unauthorized use of Your account. You
                    can enter your account only from one device.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You may not use as a username the name of another person or
                    entity or that is not lawfully available for use, a name or
                    trademark that is subject to any rights of another person or
                    entity other than You without appropriate authorization, or
                    a name that is otherwise offensive, vulgar or obscene.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Each User acknowledges that each set of User Credentials is
                    non-transferable and shall only be used by the User to whom
                    it is issued. Such User Credentials shall not be disclosed
                    to or transfer to any third person without written
                    permission of the Company. We will never ask you, for any
                    reason, whether by email, regular mail or telephone, to
                    disclose your User Credentials. Password inquiries will only
                    be conducted online and only after you have signed onto the
                    Platform. We will never send you embedded links in an email
                    requesting that you sign onto the Platform by clicking such
                    a link. If you receive an embedded link by email, claiming
                    to be from us or the Platform, you shall not open or click
                    on the link.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The email is not from us and is likely fraudulent.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Authorized Individuals
                    </span>
                </strong>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Each User and Authorized Individual acknowledges that they
                    have received and accepted these Terms.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    When applying for any Authorized Individual to receive
                    access to the Platform and an Account on their behalf, the
                    User acknowledges and represents that the Authorized
                    Individual is duly authorized to (i) access and use the
                    Platform on the User’s behalf and, if applicable, to
                    exercise the same powers conferred by the User upon the
                    Authorized Individual
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    in accordance with any underlying power of attorney to the
                    same extent as is technically feasible and that services
                    offered under the Platform are analogous to services that
                    the User may utilize through other channels; (ii) accept any
                    Changes to these Terms on the User’s behalf; and (iii) apply
                    or subscribe to any of the Platform services that require
                    separate application or subscription.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Each User shall procure that each Authorized Individual
                    acting on their behalf is informed of and agrees to and
                    complies with these Terms and, as applicable, the
                    Third-Party Services Provider Terms. You shall be fully
                    liable for all acts or omissions or non-compliance of your
                    designated Authorized Individual in the access and use of
                    the Platform and any transactions conducted through your
                    Account.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You represent, undertake and confirm that you have procured
                    the consent of your Authorized Individuals to the
                    collection, use, transfer, disclosure and processing of the
                    Personal Information of such Authorized Individuals in
                    accordance with these Terms and the Privacy Policy.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You understand and agree that the information and services
                    provided by the Platform are not provided to, and may not be
                    used by or for the benefit of, any individual or entity in
                    any jurisdiction where the provision or use thereof would be
                    contrary to any applicable law, or where we are not
                    authorized to provide such Platform or information and
                    services.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>&nbsp;</p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Intellectual Property
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    All title, ownership rights and intellectual property rights
                    in or relating to the Site and Platform, any information
                    transmitted by, to or over the Platform and information
                    regarding use of the Platform will remain with the Company
                    or its licensors. Nothing on the Platform will be construed
                    as conferring on any User any license, save as expressly set
                    out herein, of any of the Company’s or any third party’s
                    title, ownership rights and/or intellectual property rights,
                    whether by estoppel, implication or otherwise.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The Platform and App may provide you access to content,
                    information, quote, videos, photos or other materials (the
                    “Third-Party Content”) supplied by certain third parties
                    (the “Third-Party Content Providers”). The Company does not
                    endorse or recommend, and is not responsible for verifying
                    the accuracy, validity or completeness of any Third-Party
                    Content provided through the Site or Platform.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    &nbsp;&nbsp;{' '}
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Your use or reliance on such Third-Party Content is at your
                    sole risk. All title, ownership rights and intellectual
                    property rights in or relating to the Third-Party Content
                    will remain with the applicable Third-Party Content
                    Provider. Nothing on the Platform will be construed as
                    conferring on any User any license, save as expressly set
                    out herein, of any Third-Party Content Provider’s title,
                    ownership rights and/or intellectual property rights,
                    whether by estoppel, implication or otherwise.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Provided you are in compliance with these Terms, you can
                    download and access the Site on a single mobile device and
                    access the Platform using properly issued User Credentials.
                    All other rights in the Site are reserved by the Company.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    In the event of your breach of these Terms, we will be
                    entitled to terminate your use and access to the Site and
                    Platform immediately.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You agree not to:
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (a) modify, adapt, reproduce, translate or create derivative
                    works of the Site or Platform, or any data or content
                    (including the Third-Party Content) provided through the
                    Site or Platform, or any portion thereof, or attempt to
                    reverse engineer, decompile, disassemble or otherwise
                    attempt to discover the source code of the Site or Platform;
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (b) remove any copyright notice, trademark, legend, logo or
                    product identification from the Site or Platform;
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (c) misrepresent the other sites as the Company’s Site by
                    co-opting the visual “look and feel” of or text from the
                    Company’s Site or otherwise violate the Company’s
                    intellectual property rights, including, without limitation,
                    “scraping” text or images from the Company’s Site or the
                    Company managed banners and/or text links, search marketing
                    or all other online and offline campaigns,
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (d) edit, modify, filter, truncate or change the order of
                    the information contained in any part of the Company’s
                    Sites, or remove, obscure, or minimize any part of the
                    Company’s Site in any way without authorization of the
                    Company; or
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (e) make any commercial use of the Site or Platform or the
                    Company’s logo, trademark or brand name in any way.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Each User authorizes the Company to use any information or
                    content provided by the User or processed in connection with
                    the use of the Site and Platform (e.g. Personal Information,
                    geographic information, device information) in the context
                    and for the purpose of providing services or products on the
                    Platform and the secure use of the Site and the Platform.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Provision of Material and Information
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You acknowledge that neither the Company nor the Platform is
                    your travel adviser or fiduciary. You further acknowledge
                    that none of the Materials we provide or made available on
                    the Platform constitutes our recommendation or solicitation
                    that you enter into any particular decision during your
                    travel.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You acknowledge that we have no duty or obligation to
                    verify, correct, complete or update any Material displayed
                    on the Platform. Materials, including without limitation,
                    news and research, may be prepared by information providers
                    that are independent of us. We do not warrant that the
                    Material will be accurate, complete or refreshed in a timely
                    manner. You should conduct further research and analysis or
                    consult a travel advisor before making decisions. Any use of
                    or reliance on materials by you is at your own risk.{' '}
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Service and Other Notifications
                    </span>
                </strong>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You agree to accept notifications regarding the App,
                    Platform, your Account and Terms through Service
                    Notifications. You agree that such Service Notifications
                    shall constitute effective notice in lieu of written, mailed
                    or other forms of notice required by applicable law.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    It is your sole responsibility to monitor the applicable
                    email account without further reminders or repeat
                    notifications from the Company. You shall immediately report
                    any unauthorized use or access of the Platform.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You release the Company from any liability for losses or
                    damages resulting from the use of the Service Notifications
                    to the extent permitted by law. The Company provides no
                    warranty or accepts no liability that the information
                    provided through Service Notifications is up-to-date,
                    correct or complete.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Personal Information
                    </span>
                </strong>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    As part of the Platform, Personal Information of the User
                    may be collected, used, transferred, disclosed or otherwise
                    processed by the Company in accordance with the Privacy
                    Policy. You should read the Privacy Policy carefully before
                    registering for and using the Site and Platform.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You consent to the collection, use and disclosure of your
                    Personal Information in accordance with these Terms and the
                    Privacy Policy, including without limitation, disclosure to
                    the Third-Party Services Provider for purposes of providing
                    services and conducting transactions in regards to the
                    Account.{' '}
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You agree to provide true, accurate, current and complete
                    Personal Information. You further agree to maintain and
                    promptly update the Personal Information to keep it true,
                    accurate, current and complete at all times during the term
                    of this Agreement.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You must promptly inform us of all changes, including, but
                    not limited to, changes in the Personal Information in
                    connection with the Platform. If you provide any information
                    that is untrue, inaccurate, not current or incomplete, or if
                    we or any of our authorized agents have reasonable grounds
                    to suspect that such information is untrue, inaccurate, not
                    current or incomplete, we have the right to suspend or
                    terminate the Account and refuse any and all current or
                    future use of the Platform and Site by you, as well as
                    subject you to civil liability or refer you to the
                    appropriate law enforcement authorities for criminal
                    prosecution.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    We shall not be liable to make any compensation, monetary or
                    otherwise, following such suspension, termination or
                    inability for you to use the Platform or the Site.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Please note that we may collect information using tracking
                    technologies regarding your device, such as IP address,
                    network provider, mobile carrier, mobile browser type,
                    timestamp, time zone, information about the speed, bearing,
                    orientation, and altitude of a device, or other
                    device-identifying information. The User consents to such
                    use of tracking technologies and acknowledges that the
                    information obtained, including Personal Information, may be
                    matched to public or private information accessible to the
                    Company or any Third-Party Services Provider. The User also
                    consents to such information being shared with the Company’s
                    and Third-Party Services Provider’s service providers for
                    the purposes of providing and maintaining the tracking
                    technologies and related services. We may also collect
                    precise geolocation data from or about your device, which
                    may be expressed by latitude-longitude coordinates obtained
                    through GPS tools, WiFi data, cell tower triangulation or
                    other techniques. Our use of such information is described
                    in our Privacy Policy.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Cookies and how do we use them
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Cookies are text files placed on your computer to collect
                    standard Internet log information and visitor behaviour
                    information. When you visit our websites, we may collect
                    information from you automatically through cookies or
                    similar technology SafeTravy uses cookies in a range of ways
                    to improve your experience on our website and other digital
                    services.{' '}
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    There are a number of different types of cookies, however,
                    our website uses:
                </span>
            </p>
            <ul
                className='awlist1'
                style={{ margin: '0pt', paddingLeft: '0pt' }}>
                <li
                    style={{
                        marginLeft: '36pt',
                        textIndent: '-18pt',
                        textAlign: 'justify',
                        fontFamily: '"Times New Roman"',
                    }}>
                    <span
                        style={{
                            width: '14.34pt',
                            font: '7pt "Times New Roman"',
                            display: 'inline-block',
                        }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    </span>
                    Account and login information
                </li>
                <li
                    style={{
                        marginLeft: '36pt',
                        textIndent: '-18pt',
                        textAlign: 'justify',
                        fontFamily: '"Times New Roman"',
                    }}>
                    <span
                        style={{
                            width: '14.34pt',
                            font: '7pt "Times New Roman"',
                            display: 'inline-block',
                        }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    </span>
                    Email newsletter subscriptions
                </li>
                <li
                    style={{
                        marginLeft: '36pt',
                        textIndent: '-18pt',
                        textAlign: 'justify',
                        fontFamily: '"Times New Roman"',
                    }}>
                    <span
                        style={{
                            width: '14.34pt',
                            font: '7pt "Times New Roman"',
                            display: 'inline-block',
                        }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    </span>
                    Surveys and forms
                </li>
                <li
                    style={{
                        marginLeft: '36pt',
                        textIndent: '-18pt',
                        textAlign: 'justify',
                        fontFamily: '"Times New Roman"',
                    }}>
                    <span
                        style={{
                            width: '14.34pt',
                            font: '7pt "Times New Roman"',
                            display: 'inline-block',
                        }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    </span>
                    Site preferences
                </li>
                <li
                    style={{
                        marginLeft: '36pt',
                        textIndent: '-18pt',
                        textAlign: 'justify',
                        fontFamily: '"Times New Roman"',
                    }}>
                    <span
                        style={{
                            width: '14.34pt',
                            font: '7pt "Times New Roman"',
                            display: 'inline-block',
                        }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    </span>
                    Functionality – SafeTravy uses these cookies so that we
                    recognize you on our website and remember your previously
                    selected preferences. These could include what language you
                    prefer and location you are in. A mix of first-party and
                    third-party cookies are used.
                </li>
                <li
                    style={{
                        marginLeft: '36pt',
                        textIndent: '-18pt',
                        textAlign: 'justify',
                        fontFamily: '"Times New Roman"',
                    }}>
                    <span
                        style={{
                            width: '14.34pt',
                            font: '7pt "Times New Roman"',
                            display: 'inline-block',
                        }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    </span>
                    Advertising – SafeTravy uses these cookies to collect
                    information about your visit to our website, the content you
                    viewed, the links you followed and information about your
                    browser, device, and your IP address. SafeTravy sometimes
                    shares some limited aspects of this data with third parties
                    for advertising purposes. We may also share online data
                    collected through cookies with our advertising partners.
                    This means that when you visit another website, you may be
                    shown advertising based on your browsing patterns on our
                    website.
                </li>
            </ul>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    You can set your browser to refuse all or some browser
                    cookies or to alert you when websites set or access cookies.
                    If you disable or refuse cookies, please note that some
                    parts of our websites, products and services may become
                    inaccessible or not function properly.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Disclaimer and Risks of Use of Platform
                    </span>
                </strong>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The Platform and Site, including all content (including
                    Third-Party Content), features and any related services are
                    provided on an “As Is” and “As Available” basis at the
                    User’s sole risk and without any representations or
                    warranties. We do not guarantee that all or any part of the
                    Platform or the App will be available or accessible by the
                    User at all times.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The use of the Platform, due to the download, installation
                    or use of the Site and the associated reference points with
                    third parties (for example, distribution platform providers,
                    network providers, device manufacturers) involves risks, in
                    particular:
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (a) disclosure of your Personal Information or other
                    information and the existence of your relationship with the
                    Third-Party Services Provider to third parties;
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (b) system outages, security-related restrictions and
                    unauthorized removal of use restrictions on the end device,
                    and other disturbances which may make use impossible; and
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (c) misuse due to manipulation by malware or unauthorized
                    use, including in the event the User’s device used to access
                    the Site or the Platform is lost or stolen.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    We are entitled to block or disable the use of the Site on
                    end devices if the security features devised by the
                    operating system or manufacturer of such device on which the
                    Site is installed have been modified at any time (for
                    example, a device that has been “jailbroken”). Accordingly,
                    we do not guarantee the functioning and operation of the App
                    on end devices which have been modified in this way or on
                    older end devices that no longer meet the technical
                    requirements for the use of the Site or access to the
                    Platform.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    ALL WARRANTIES, CONDITIONS OR TERMS (WHETHER EXPRESS,
                    IMPLIED, STATUTORY OR OTHERWISE) INCLUDING WITHOUT
                    LIMITATION RELATING TO QUALITY, MERCHANTABILITY, FITNESS FOR
                    PURPOSE, OR UNINTERRUPTED, ERROR-FREE ACCESS ARE EXPRESSLY
                    EXCLUDED FOR THE SITE AND PLATFORM TO THE FULLEST EXTENT
                    PERMITTED BY LAW.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    No representation or warranty, express or implied, can be
                    given as to the accuracy or completeness of the information
                    provided in the Platform.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Each User acknowledges and accepts the risks that may arise
                    from the travel experience. We may also use servers and
                    other computer hardware situated in any jurisdiction
                    worldwide for the provision of any portion of the Platform.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    We exclude any and all liability for loss or damage caused
                    by transmission errors, technical faults, breakdowns,
                    business interruptions or illegal interventions into
                    transmission networks, IT systems/computers of the User or
                    of any third party (including systems in the public domain).
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Termination
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    We may terminate or suspend Your Account immediately,
                    without prior notice or liability, for any reason
                    whatsoever, including without limitation if You breach these
                    Terms and Conditions.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Upon termination, Your right to use the Service will cease
                    immediately. If You wish to terminate Your Account, You may
                    simply discontinue using the Service.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Release
                    </span>
                </strong>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    To the maximum extent permitted by applicable law, you
                    hereby discharge, acquit, and otherwise release us, our
                    parent company, affiliates and subsidiaries and each of
                    their respective officers, directors, shareholders, members,
                    partners, attorneys, employees, independent contractors,
                    telecommunication providers, and agents (collectively, the
                    “Indemnified Parties”), from any and all allegations,
                    counts, charges, debts, causes of action, claims and losses,
                    relating in any way to the use of, or activities relating to
                    the use of the Site, Platform, any Account and any services
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    or Third-Party Content provided through the Site, Platform
                    or any Account, including, but not limited to, claims
                    relating to the following: negligence, gross negligence,
                    intentional interference with contract or advantageous
                    business relationship, defamation, privacy, publicity,
                    misrepresentation,
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    false identities, fraudulent acts by others, invasion of
                    privacy, release of Personal Information, failed
                    transactions, purchases or functionality of the Platform,
                    unavailability of the Site, the Platform, Third-Party
                    Content or any Account, their functions and any other
                    technical failure that may result in inaccessibility to the
                    Site, the Platform, Third-Party Content or any Account, or
                    any claim based on vicarious liability for torts committed
                    by you encountered or transacted with through the Site,
                    Platform, Third-Party Content and any Account, including,
                    but not limited to, fraud, computer hacking, theft or misuse
                    of Personal Information, assault, battery, stalking, rape,
                    cheating, perjury, manslaughter, or murder.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The above list is intended to be illustrative only, and not
                    exhaustive of the types or categories of claims released by
                    us.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    This release is intended by the parties to be interpreted
                    broadly in favor of us, and thus any ambiguity shall be
                    interpreted in a manner providing release of the broadest
                    claims.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    This release is intended to be a full release of claims, and
                    the parties acknowledge the legally binding nature of this
                    provision, and the nature of the rights given up in
                    connection therewith.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Limitation of Liability
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    SafeTravy is not liable for any information which is
                    available via and/or integrated with the SafeTravy Products.
                    Consequently, SafeTravy cannot be held liable for the
                    correctness, completeness, quality, lawfulness and
                    reliability of the information or for the results which are
                    achieved by means of such information.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The SafeTravy Services does not constitute tools to be used
                    for and support risk management and risk assessment.
                    SafeTravy is unable to warrant that all information and
                    updates are complete, adequate and error-free. Users should
                    always evaluate output and data from the service on an
                    individual basis. SafeTravy aims to reduce risk for the
                    users but shall in no way be responsible for the safety and
                    security of the users and SafeTravy assumes no liability for
                    losses, bodily injury or death of users.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    UNDER NO CIRCUMSTANCES AND UNDER NO THEORY OF LAW (TORT,
                    CONTRACT, STRICT LIABILITY OR OTHERWISE), SHALL WE OR ANY OF
                    THE INDEMNITEES BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY
                    DAMAGES ARISING FROM THE USE OR MISUSE OF, OR INABILITY TO
                    USE, THE PLATFORM, THE SITE, THIRD-PARTY CONTENT OR ANY
                    ACCOUNT, REGARDLESS OF WHETHER SUCH DAMAGES ARE DIRECT,
                    INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF
                    ANY CHARACTER, LOSS OF INFORMATION, BUSINESS INTERRUPTION OR
                    LOST PROFITS, LOST SAVINGS, OR LOSS OF DATA, OR LIABILITIES
                    UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR OTHER
                    THEORY ARISING OUT OF OR RELATING IN ANY MANNER TO THE SITE,
                    THE PLATFORM, THIRD-PARTY CONTENT OR ANY ACCOUNT OR FOR ANY
                    CLAIM OR DEMAND BY ANY THIRD PARTY, EVEN IF WE KNEW OR HAD
                    REASON TO KNOW OF THE POSSIBILITY OF SUCH DAMAGES, CLAIM OR
                    DEMAND IF THE FOREGOING DISCLAIMER AND WAIVER OF LIABILITY
                    SHOULD BE DEEMED INVALID OR INEFFECTIVE. SOME JURISDICTIONS
                    DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
                    WARRANTIES AND/OR LIABILITIES, SO CERTAIN OF THE ABOVE
                    LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    IN NO EVENT SHALL OUR LIABILITY, REGARDLESS OF THE FORM OF
                    ACTION AND DAMAGES SUFFERED BY YOU, EXCEED THE HIGHEST
                    AGGREGATE SUBSCRIPTION FEES PAID BY YOU TO US IN CONNECTION
                    WITH THE PLATFORM, OR THE SITE, OR 300 EUR, WHICHEVER IS
                    GREATER.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    We will not be liable for our failure to perform any
                    obligations under these Terms due to events beyond our
                    control, and the time provided for performing such
                    obligations shall be extended by a period of time equal to
                    the duration of such events.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Events beyond our control include, without limitation, acts
                    of God, war, riot, arson, embargoes, civil commotion,
                    strikes, labor disputes, equipment failures, bank failures,
                    virtual currency market collapse or fluctuations, credit or
                    debit card transaction processing failures, strikes, fire,
                    flood, earthquake, hurricanes, tropical storms or other
                    natural disaster or casualty, shortages of labor or
                    material, shortage of transportation, facilities, fuel,
                    energy, government regulation or restriction, acts of civil
                    or military authority or terrorism, fiber cuts, weather
                    conditions, breaches or failures to perform by third
                    parties, technical problems, including hardware and software
                    crashes and other malfunctions, failure of the
                    telecommunications or information services infrastructure,
                    hacking, SPAM or failure of any computer, server or software
                    disruptions on account of or caused by vandalism, theft,
                    phone service outages, power outage, Internet disruptions,
                    viruses, and mechanical, power or communications failures.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Changes to These Terms and Conditions
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    We reserve the right, at Our sole discretion, to modify or
                    replace these Terms at any time. If a revision is material
                    We will make reasonable efforts to provide at least 30 days'
                    notice prior to any new terms taking effect. What
                    constitutes a material change will be determined at Our sole
                    discretion.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    By continuing to access or use Our Service after those
                    revisions become effective, You agree to be bound by the
                    revised terms. If You do not agree to the new terms, in
                    whole or in part, please stop using the website and the
                    Service.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        General
                    </span>
                </strong>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    These Terms, including the Privacy Policy and other policies
                    incorporated herein, constitute the entire and only
                    agreement between you and the Company with respect to the
                    subject matter of these Terms, and supersede any and all
                    prior or contemporaneous agreements, representations,
                    warranties and understandings, written or oral, with respect
                    to the subject matter of these Terms. If any provision of
                    these Terms is found to be unlawful, void or for any reason
                    unenforceable, then that provision shall be deemed severable
                    from these Terms and shall not affect the validity and
                    enforceability of any remaining provisions. These Terms may
                    not be changed, waived or modified except by the Company as
                    provided herein. Neither these Terms nor any right,
                    obligation or remedy hereunder is assignable, transferable,
                    delegable or sublicensable by you except with our prior
                    written consent, and any attempted assignment, transfer,
                    delegation or sublicense shall be null and void. No waiver
                    by any party of any breach or default hereunder shall be
                    deemed to be a waiver of any preceding or subsequent breach
                    or default.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Any heading, caption or section title contained in these
                    Terms is inserted only as a matter of convenience and in no
                    way defines or explains any section or provision hereof.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Governing Law and Dispute Resolution
                    </span>
                </strong>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    All disputes, disagreements or claims arising under this
                    agreement or in connection with it, including those relating
                    to its conclusion, interpretation, execution, violation,
                    termination or invalidity, shall be resolved by the
                    International Commercial Arbitration Court at the Ukrainian
                    Chamber of Commerce and Industry in accordance with its
                    Regulations.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The law governing this agreement is the substantive law of
                    Gibraltar.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    The arbitration court consists of a sole arbitrator. The
                    location of the meeting of the Arbitration Court is the city
                    of Kyiv, Ukraine. The language of the arbitration
                    proceedings is English or Ukrainian.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>&nbsp;</strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Contact Us
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    If you have any questions about these Terms and Conditions,
                    You can contact us:
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    By email:{' '}
                </span>
                <a href='team@safetravy.com' style={{ textDecoration: 'none' }}>
                    <u>
                        <span
                            style={{
                                fontFamily: '"Times New Roman"',
                                color: '#1155cc',
                            }}>
                            <span className='__cf_email__'>
                                team@safetravy.com
                            </span>
                        </span>
                    </u>
                </a>
            </p>
        </>
    )
}
