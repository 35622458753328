import { http } from '@/core/services'
import {
    IPayloadV2SelectSubscription,
    IResponsSubscriptionSelect,
    ISelectSubscribeResp,
    ISubscribePayload,
} from './interface'

export const saveSubscribeReq = (data: ISubscribePayload) => {
    return http.post<ISelectSubscribeResp>('subscriptions/select', data)
}

export const cancelSubsriptionReq = () => {
    return http.post('subscriptions/cancel', {})
}

export const getSubscribeHistoryReq = () => {
    return http.get<any>('subscriptions/history')
}

export const saveSubscribeV2Req = (data: IPayloadV2SelectSubscription) => {
    return http.post<IResponsSubscriptionSelect>(
        'subscriptions/v2/select',
        data,
    )
}
