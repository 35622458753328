export const TermsFrench = () => {
    return (
        <>
            <p style={{ textAlign: 'center' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Conditions générales de SafeTravy
                    </span>
                </strong>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Les présentes conditions générales (les "conditions" ou le
                    "contrat") régissent l'utilisation de la plateforme
                    électronique, y compris tout site web ou application mobile
                    (l'"application", conjointement Окей avec le site web, le
                    "site") permettant d'accéder à la plateforme, et tout
                    service fourni par l'intermédiaire de la plateforme
                    (collectivement, la "plateforme") fournie par SafeTravy
                    Limited (la "société", "nous", "notre" ou "nos").
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Les Conditions constituent un accord contraignant entre la
                    Société et vous, en tant qu'utilisateur individuel (" vous
                    ", " votre " ou " Utilisateur ") pour votre utilisation
                    individuelle de l'Application et de la Plateforme. En vous
                    inscrivant, en téléchargeant l'application et en utilisant
                    la plate-forme, vous confirmez votre acceptation du présent
                    accord et de notre politique de confidentialité associée.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si vous n'acceptez pas ces conditions, vous devez
                    immédiatement désinstaller l'application et cesser
                    d'utiliser l'application et la plate-forme.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Avis de non-responsabilité concernant les titres : aucun
                    document ni aucune autre information pouvant être mis à
                    disposition sur le site ou la plate-forme ne constitue ou ne
                    peut être interprété comme une recommandation, une
                    approbation, une offre, une invitation ou une sollicitation
                    à prendre une quelconque décision, toutes les décisions
                    étant prises en fonction de votre propre volonté et de votre
                    expérience. Vous comprenez en outre qu'aucun des
                    fournisseurs d'informations, y compris les fournisseurs
                    tiers (tels que définis ci-dessous), ne vous conseille
                    personnellement sur la nature, le potentiel, la valeur ou
                    l'adéquation de toute question de sécurité particulière
                    concernant vos voyages. Veuillez noter que la situation dans
                    le monde évolue rapidement, de sorte que toute information,
                    tout contenu, tout contenu de tiers (tel que défini
                    ci-dessous) ou tout autre matériel fourni sur ou par
                    l'intermédiaire du site ou de la plate-forme peut ne pas
                    être complet ou actuel, ou peut être remplacé par des
                    informations plus récentes. Vous vous fiez à ces
                    informations à vos propres risques.{' '}
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Notre Site et notre Plateforme ne sont pas destinés à
                    fournir des conseils juridiques, d'assurance ou
                    d'investissement, et rien sur le Site ou la Plateforme ne
                    doit être interprété comme une offre sur la façon
                    d'organiser votre voyage. Toutes ces informations ne sont
                    que des recommandations personnalisées.{' '}
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        1. L'applicabilité
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    1.1. Nous, SafeTravy Limited (ci-après " SafeTravy "),
                    offrons des services dans le domaine du divertissement
                    informatique. Les présentes conditions générales
                    s'appliquent à la relation contractuelle entre SafeTravy et
                    le client (" Client " ou " Vous ") en ce qui concerne tous
                    les services que nous fournissons.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        2. Définitions
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Compte{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    désigne le compte établi par un utilisateur qui a téléchargé
                    l'application ou accédé au site et s'est inscrit auprès de
                    la société pour utiliser le site et la plateforme.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        App{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    désigne l'application mobile fournie par la Société pour
                    accéder à la Plateforme.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Personne autorisée{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    désigne toute personne autorisée à accéder et à utiliser le
                    Site (y compris l'App) et la Plateforme au nom d'un
                    Utilisateur.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>Les </span>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        plateformes numériques{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    désignent les plateformes de distribution tierces auxquelles
                    il est possible d'accéder ou de télécharger des applications
                    mobiles ou d'autres programmes logiciels, y compris, mais
                    sans s'y limiter, l'App Store d'Apple et Google Play.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Autorité gouvernementale{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    : toute nation ou tout gouvernement, toute province ou tout
                    État ou toute autre subdivision politique de ceux-ci, ou
                    toute entité, autorité ou organisme exerçant des fonctions
                    exécutives, législatives, judiciaires, réglementaires ou
                    administratives, y compris toute autorité gouvernementale,
                    agence, département, conseil, commission ou instrument, ou
                    toute subdivision politique de ceux-ci, toute cour, tout
                    tribunal ou tout arbitre, et tout organisme
                    d'autoréglementation.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Matériel{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    désigne tout matériel d'offre, fiche technique, rapport de
                    recherche, documentation de produit ou de service ou toute
                    autre information fournie par l'intermédiaire de la
                    Plateforme.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>Les </span>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        informations personnelles{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    désignent les informations fournies par un utilisateur à
                    partir desquelles l'identité de cet utilisateur peut être
                    directement ou indirectement déterminée.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Politique de confidentialité{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    désigne les conditions supplémentaires régissant la
                    collecte, l'utilisation et la divulgation des informations
                    personnelles de chaque utilisateur, comme indiqué ici.
                    Chaque Utilisateur doit lire et accepter la Politique de
                    confidentialité afin d'utiliser l'Application ou le Site.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>Les </span>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        notifications de service{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    sont des notifications unidirectionnelles de la Société (qui
                    peuvent inclure des notifications liées à la sécurité) par
                    le biais de messages texte ou d'e-mails et, le cas échéant,
                    de notifications push par le biais du Site.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Ces notifications sont envoyées à l'Utilisateur en ce qui
                    concerne certaines informations ou événements relatifs à un
                    compte auquel un Utilisateur a accès par le biais de la
                    Plateforme.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>L'</span>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        utilisateur{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    désigne toute personne qui s'est inscrite auprès de la
                    société pour utiliser le site et accéder à la plate-forme,
                    ainsi que toute personne autorisée agissant en son nom.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>Les </span>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        références de l'utilisateur{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    désignent l'ensemble des identifiants, mots de passe,
                    numéros d'identification personnels, jetons et toute autre
                    information ou dispositif fournis à un utilisateur pour
                    accéder à la plate-forme.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>La </span>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Société{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (désignée par " la Société ", " nous ", " notre " ou " nos "
                    dans le présent contrat) est SAFETRAVY LIMITED, Suite 4, 2nd
                    floor, the West Wing, Montarik House, 3 Bedlam Court,
                    Gibraltar, GX11 1AA, GIBRALTAR, GIBRALTAR.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Appareil{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    : tout appareil pouvant accéder au Service, tel qu'un
                    ordinateur, un téléphone portable ou une tablette numérique.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>L'</span>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        essai gratuit{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    fait référence à une période de temps limitée qui peut être
                    gratuite lors de l'achat d'un abonnement.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>Le </span>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        service{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    fait référence à toutes les informations, recommandations et
                    notifications fournies par le site web.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>Les </span>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        abonnements{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    font référence aux services ou à l'accès au Service proposés
                    par la Société sur la base d'un abonnement.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Les conditions{' '}
                </span>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        générales{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (également appelées "conditions") sont les présentes
                    conditions générales qui constituent l'intégralité de
                    l'accord entre vous et la société concernant l'utilisation
                    du service.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Service{' '}
                </span>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        tiers{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    : tout service ou contenu (y compris les données,
                    informations, produits ou services) fourni par un tiers qui
                    peut être affiché, inclus ou mis à disposition par le
                    service.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>Le </span>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        site web{' '}
                    </span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    fait référence à SafeTravy, accessible à partir de
                    www.safetravy.com
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Changements
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nous nous réservons le droit, à tout moment, de :
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (a) modifier, mettre à jour ou changer les termes et
                    conditions du présent accord ou de notre politique en
                    matière de protection de la vie privée ;{' '}
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (b) modifier, mettre à jour ou changer le Site et la
                    Plateforme, y compris éliminer ou interrompre tout contenu
                    ou fonctionnalité du Site ou de la Plateforme (tout ce qui
                    précède étant désigné par le terme "Changements").
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nous pouvons apporter de telles modifications à tout moment
                    sans préavis.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Toute modification du présent accord peut être publiée sur
                    notre site web ou vous être notifiée par le biais de
                    notifications push sur le site ou d'un courriel envoyé à
                    l'adresse électronique figurant dans votre compte. C'est
                    pourquoi vous devez consulter régulièrement notre site web,
                    autoriser le site à recevoir de telles notifications push et
                    tenir à jour votre adresse électronique et vos autres
                    informations de contact dans le compte. Vous acceptez toute
                    modification si vous continuez à utiliser le Site et la
                    Plateforme après l'entrée en vigueur de ces modifications.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Conditions d'utilisation de la plateforme numérique
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    L'application peut être disponible au téléchargement à
                    partir d'une ou plusieurs plateformes numériques. Le
                    téléchargement, l'installation, l'accès ou l'utilisation de
                    l'application sont également soumis aux conditions générales
                    et aux politiques de confidentialité de la plateforme
                    numérique concernée (les "conditions de la plateforme
                    numérique"). En cas de conflit entre les présentes
                    conditions et les conditions de la plateforme numérique, les
                    présentes conditions prévaudront.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    L'application est indépendante et n'est pas associée,
                    affiliée, parrainée, approuvée ou liée de quelque manière
                    que ce soit à une Plateforme numérique. Vous et nous
                    reconnaissons que le présent Accord est conclu entre vous et
                    nous uniquement, et non avec une Plateforme numérique, et
                    que nous, et non la Plateforme numérique, sommes seuls
                    responsables de l'application et de son contenu dans la
                    mesure spécifiée dans le présent Accord.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous et nous reconnaissons et acceptons que la Plate-forme
                    numérique concernée et les filiales de cette Plate-forme
                    numérique sont des tiers bénéficiaires des présentes
                    Conditions et que, dès votre acceptation des présentes
                    Conditions, cette Plate-forme numérique aura le droit (et
                    sera réputée avoir accepté le droit) de faire appliquer les
                    présentes Conditions à votre encontre en tant que tiers
                    bénéficiaire de celles-ci.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Exigences en matière de dispositifs de réseau et
                        d'opérateurs
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous reconnaissez que votre accord avec votre fournisseur de
                    réseau mobile et Internet (le "fournisseur de réseau")
                    s'appliquera à votre utilisation du site. Vous reconnaissez
                    que votre fournisseur de réseau peut vous facturer des
                    services de données lors de l'utilisation de certaines
                    fonctionnalités du site ou toute autre charge de tiers qui
                    pourrait survenir, et vous acceptez la responsabilité
                    exclusive de ces charges. Si vous n'êtes pas le payeur de la
                    facture de l'appareil mobile/Internet utilisé pour accéder
                    au site, vous serez supposé avoir reçu l'autorisation du
                    payeur de la facture pour utiliser le site. Vous devez
                    également vous assurer que votre utilisation du site n'est
                    pas en violation du contrat de votre appareil mobile ou
                    Internet ou de tout contrat de service de données sans fil.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Admissibilité et inscription
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous devez être âgé d'au moins 18 ans pour accéder au site
                    et à la plateforme et les utiliser. Vous affirmez en outre
                    que vous êtes pleinement capable et compétent pour conclure
                    les termes, conditions, obligations, affirmations,
                    représentations et garanties énoncés dans les présentes
                    Conditions, et pour respecter et se conformer aux présentes
                    Conditions. Vous devez vous inscrire auprès de la Société
                    pour utiliser le Site et la Plateforme ; vous acceptez de
                    fournir des informations complètes et exactes lorsque vous
                    vous inscrivez pour utiliser le Site et la Plateforme, et de
                    maintenir ces informations à jour.{' '}
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nous avons le pouvoir discrétionnaire d'accepter ou de
                    refuser votre inscription à la Plateforme. Seuls les
                    utilisateurs dont l'inscription est approuvée par nous
                    seront nos clients.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Remerciements
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Les présentes conditions générales régissent l'utilisation
                    de ce service et constituent l'accord conclu entre vous et
                    la société. Ces conditions définissent les droits et
                    obligations de tous les utilisateurs en ce qui concerne
                    l'utilisation du service.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Votre accès au service et son utilisation sont subordonnés à
                    votre acceptation et au respect des présentes conditions
                    générales. Ces conditions s'appliquent à tous les visiteurs,
                    utilisateurs et autres personnes qui accèdent au service ou
                    l'utilisent.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    En accédant au service ou en l'utilisant, vous acceptez
                    d'être lié par les présentes conditions générales. Si vous
                    n'êtes pas d'accord avec une partie de ces conditions, vous
                    ne pouvez pas accéder au service.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous déclarez être âgé de plus de 18 ans. La société
                    n'autorise pas les personnes de moins de 18 ans à utiliser
                    le service.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Votre accès et votre utilisation du Service sont également
                    conditionnés par votre acceptation et votre respect de la
                    Politique de confidentialité de la Société. Notre politique
                    de confidentialité décrit nos politiques et procédures en
                    matière de collecte, d'utilisation et de divulgation de vos
                    informations personnelles lorsque vous utilisez
                    l'application ou le site Web et vous informe de vos droits
                    en matière de confidentialité et de la manière dont la loi
                    vous protège. Veuillez lire attentivement notre politique de
                    confidentialité avant d'utiliser notre service.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Abonnements
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Période d'abonnement
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Le service ou certaines parties du service ne sont
                    disponibles que moyennant un abonnement payant. Vous serez
                    facturé à l'avance sur une base récurrente et périodique
                    (quotidienne, hebdomadaire, mensuelle ou annuelle), en
                    fonction du type de plan d'abonnement que vous aurez choisi
                    lors de l'achat de l'abonnement.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    A la fin de chaque période, votre abonnement sera
                    automatiquement renouvelé dans les mêmes conditions, à moins
                    que vous ne l'annuliez ou que la Société ne l'annule.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Mise à jour de l'abonnement
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si l'Utilisateur utilise un type d'abonnement payant et le
                    met à niveau vers un autre type d'abonnement (augmente le
                    volume du Service ou la durée d'utilisation du Service), les
                    Parties conviennent par la présente et l'Utilisateur et la
                    Société conviennent par avance que l'Utilisateur paiera 0,99
                    (quatre-vingt-dix-neuf) centimes d'euro hors taxes
                    supplémentaires (les taxes sont facturées et payées en sus)
                    pour chaque mois de cet abonnement, quel que soit le moment
                    de cette mise à niveau.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    A titre d'exemple : L'Utilisateur a un abonnement payé au
                    tarif de base pour une période d'un mois, et après cinq
                    jours d'utilisation de cet abonnement, l'Utilisateur met à
                    niveau cet abonnement vers l'abonnement Extra, auquel cas,
                    moyennant le paiement de 0,99 (quatre-vingt-dix-neuf)
                    centimes d'euro hors taxes (les taxes sont facturées et
                    payées en sus), l'Utilisateur recevra l'accès à tous les
                    services de l'abonnement Extra pour la période allant
                    jusqu'à la fin de l'abonnement de base prépayé.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si l'Utilisateur met à niveau l'abonnement de base payé pour
                    3 mois, l'Utilisateur devra payer à la Société un montant
                    supplémentaire de 2,97 (deux euros et quatre-vingt-dix-sept
                    centimes d'euro) pour cette mise à niveau. Il en va de même
                    pour la mise à niveau des abonnements payés pour d'autres
                    durées.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Les Parties conviennent également que si l'Utilisateur passe
                    d'un Abonnement avec une plus grande quantité de Service à
                    un Abonnement avec une plus petite quantité de Service,
                    l'Utilisateur recevra la totalité de la quantité de Service
                    jusqu'à la fin de la période payée de l'Abonnement avec la
                    plus grande quantité de Service, et la Société n'effectuera
                    aucun remboursement ou crédit à l'Utilisateur dans ce cas.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Résiliation d'abonnement
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous pouvez annuler le renouvellement de votre abonnement
                    soit par le biais de la page des paramètres de votre compte,
                    soit en contactant la Société. Vous ne recevrez pas de
                    remboursement pour les frais que vous avez déjà payés pour
                    la période d'abonnement en cours et vous pourrez accéder au
                    service jusqu'à la fin de la période d'abonnement en cours.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Facturation
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous devez fournir à la Société des informations de
                    facturation exactes et complètes, y compris le nom complet,
                    l'adresse, l'état, et des informations valides sur la
                    méthode de paiement.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si, pour une raison quelconque, la facturation automatique
                    n'a pas lieu, l'Entreprise émettra une facture électronique
                    indiquant que vous devez procéder manuellement, dans un
                    certain délai, au paiement intégral correspondant à la
                    période de facturation telle qu'indiquée sur la facture.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Modifications de la redevance
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    La Société peut, à sa seule discrétion et à tout moment,
                    modifier les frais d'abonnement. Toute modification des
                    frais d'abonnement entrera en vigueur à la fin de la période
                    d'abonnement en cours.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    La Société vous donnera un préavis raisonnable de toute
                    modification des frais d'abonnement afin de vous donner la
                    possibilité de résilier votre abonnement avant que cette
                    modification n'entre en vigueur.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    En continuant à utiliser le Service après l'entrée en
                    vigueur de la modification des frais d'abonnement, vous
                    acceptez de payer le montant modifié des frais d'abonnement.
                </span>
            </p>
            <p
                style={{
                    marginTop: '12pt',
                    marginBottom: '12pt',
                    textAlign: 'justify',
                }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Clause de remboursement
                    </span>
                </strong>
                <br />
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Sauf lorsque la loi l'exige, les frais d'abonnement payés ne
                    sont pas remboursables.
                </span>
                <br />
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Certaines demandes de remboursement pour des abonnements
                    peuvent être examinées par la Société au cas par cas et
                    accordées à la seule discrétion de la Société.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Remboursements
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Sauf si la loi l'exige, les frais d'abonnement payés ne sont
                    pas remboursables.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Certaines demandes de remboursement d'abonnements peuvent
                    être examinées par la Société au cas par cas et accordées à
                    la seule discrétion de la Société.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Essai gratuit
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    La Société peut, à sa seule discrétion, proposer un
                    abonnement avec un essai gratuit pour une période limitée.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Il peut vous être demandé de saisir vos informations de
                    facturation pour vous inscrire à l'essai gratuit.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si vous saisissez vos informations de facturation lors de
                    l'inscription à un essai gratuit, vous ne serez pas facturé
                    par la Société tant que l'essai gratuit n'aura pas expiré.
                    Le dernier jour de la période d'essai gratuit, à moins que
                    vous n'annuliez votre abonnement, vous serez automatiquement
                    facturé des frais d'abonnement applicables pour le type
                    d'abonnement que vous avez choisi.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    A tout moment et sans préavis, la Société se réserve le
                    droit de (i) modifier les termes et conditions de l'offre
                    d'essai gratuit, ou (ii) d'annuler cette offre d'essai
                    gratuit.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Comptes d'utilisateurs
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Afin d'utiliser les services de la Plateforme, vous devez
                    créer un compte sur la Plateforme (le "Compte").
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Le compte peut être enregistré par toute personne physique
                    âgée de plus de 18 ans.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Lorsque vous créez un compte chez nous, vous devez nous
                    fournir des informations exactes, complètes et à jour à tout
                    moment. Le non-respect de cette obligation constitue une
                    violation des Conditions, qui peut entraîner la résiliation
                    immédiate de votre compte sur notre Service.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous êtes responsable de la protection du mot de passe que
                    vous utilisez pour accéder au service et de toutes les
                    activités ou actions effectuées avec votre mot de passe, que
                    celui-ci soit lié à notre service ou à un service tiers.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous acceptez de ne pas divulguer votre mot de passe à un
                    tiers. Vous devez nous informer immédiatement dès que vous
                    avez connaissance d'une violation de la sécurité ou d'une
                    utilisation non autorisée de votre compte. Vous ne pouvez
                    accéder à votre compte qu'à partir d'un seul appareil.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous ne pouvez pas utiliser comme nom d'utilisateur le nom
                    d'une autre personne ou entité ou un nom qui n'est pas
                    légalement disponible pour l'utilisation, un nom ou une
                    marque commerciale qui est soumis aux droits d'une autre
                    personne ou entité que vous sans autorisation appropriée, ou
                    un nom qui est autrement offensant, vulgaire ou obscène.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Chaque utilisateur reconnaît que chaque série d'identifiants
                    est incessible et ne peut être utilisée que par
                    l'utilisateur auquel elle a été délivrée. Ces justificatifs
                    d'identité ne peuvent être divulgués ou transférés à une
                    tierce personne sans l'autorisation écrite de la Société.
                    Nous ne vous demanderons jamais, pour quelque raison que ce
                    soit, que ce soit par courrier électronique, par courrier
                    postal ou par téléphone, de divulguer vos informations
                    d'identification de l'utilisateur. Les demandes de mot de
                    passe ne seront effectuées qu'en ligne et seulement après
                    que vous vous soyez connecté à la plate-forme. Nous ne vous
                    enverrons jamais de liens intégrés dans un courrier
                    électronique vous demandant de vous connecter à la
                    plate-forme en cliquant sur un tel lien. Si vous recevez un
                    lien intégré par courrier électronique, prétendant provenir
                    de nous ou de la Plateforme, vous ne devez pas ouvrir ou
                    cliquer sur le lien.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    L'e-mail ne vient pas de nous et est probablement
                    frauduleux.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Personnes autorisées
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Chaque utilisateur et chaque personne autorisée reconnaît
                    avoir reçu et accepté les présentes conditions.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Lorsqu'il demande à une personne autorisée d'accéder à la
                    plate-forme et d'ouvrir un compte en son nom, l'utilisateur
                    reconnaît et déclare que la personne autorisée est dûment
                    habilitée (i) à accéder à la plate-forme et à l'utiliser en
                    son nom et, le cas échéant, à exercer les mêmes pouvoirs que
                    ceux conférés par l'utilisateur à la personne autorisée
                    conformément à toute procuration sous-jacente, dans la
                    mesure où cela est techniquement possible, et que les
                    services offerts dans le cadre de la plate-forme sont
                    analogues aux services que l'utilisateur peut utiliser par
                    d'autres voies ; (ii) accepter toute modification des
                    présentes conditions au nom de l'utilisateur ; et (iii)
                    demander ou souscrire à tout service de la plate-forme qui
                    nécessite une demande ou une souscription distincte.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Chaque utilisateur doit veiller à ce que chaque personne
                    autorisée agissant en son nom soit informée des présentes
                    conditions et, le cas échéant, des conditions du fournisseur
                    de services tiers, qu'elle les accepte et s'y conforme. Vous
                    êtes entièrement responsable de tous les actes, omissions ou
                    manquements de la personne autorisée que vous avez désignée
                    dans le cadre de l'accès et de l'utilisation de la
                    plate-forme et de toute transaction effectuée par
                    l'intermédiaire de votre compte.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous déclarez, vous vous engagez et vous confirmez que vous
                    avez obtenu le consentement de vos personnes autorisées pour
                    la collecte, l'utilisation, le transfert, la divulgation et
                    le traitement des informations personnelles de ces personnes
                    autorisées conformément aux présentes conditions et à la
                    politique de protection de la vie privée.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous comprenez et acceptez que les informations et les
                    services fournis par la Plateforme ne sont pas fournis à, et
                    ne peuvent pas être utilisés par ou pour le bénéfice de,
                    tout individu ou entité dans toute juridiction où la
                    fourniture ou l'utilisation de ceux-ci serait contraire à
                    toute loi applicable, ou où nous ne sommes pas autorisés à
                    fournir une telle Plateforme ou des informations et des
                    services.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Propriété intellectuelle
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Tous les titres, droits de propriété et droits de propriété
                    intellectuelle relatifs au site et à la plate-forme, à toute
                    information transmise par la plate-forme, à destination de
                    celle-ci ou par son intermédiaire, ainsi que les
                    informations relatives à l'utilisation de la plate-forme,
                    restent la propriété de la société ou de ses concédants de
                    licence. Rien sur la plate-forme ne peut être interprété
                    comme conférant à un utilisateur une licence, sauf dans les
                    cas expressément prévus dans les présentes, des titres,
                    droits de propriété et/ou droits de propriété intellectuelle
                    de la société ou d'un tiers, que ce soit par préclusion,
                    implication ou autre.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    La Plateforme et l'App peuvent vous donner accès à du
                    contenu, des informations, des citations, des vidéos, des
                    photos ou d'autres matériaux (le "Contenu Tiers") fournis
                    par certains tiers (les "Fournisseurs de Contenu Tiers"). La
                    Société n'approuve ni ne recommande, et n'est pas
                    responsable de la vérification de l'exactitude, de la
                    validité ou de l'exhaustivité de tout contenu tiers fourni
                    par l'intermédiaire du Site ou de la Plateforme.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    &nbsp;&nbsp;{' '}
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    L'utilisation ou la confiance que vous accordez à ce contenu
                    tiers est à vos risques et périls. Tous les titres, droits
                    de propriété et droits de propriété intellectuelle relatifs
                    au contenu de tiers restent la propriété du fournisseur de
                    contenu de tiers concerné. Rien sur la plate-forme ne peut
                    être interprété comme conférant à un utilisateur une
                    licence, sauf dans les cas expressément prévus par les
                    présentes, sur les titres, droits de propriété et/ou droits
                    de propriété intellectuelle d'un fournisseur de contenu
                    tiers, que ce soit par préclusion, implication ou autre.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si vous êtes en conformité avec les présentes conditions,
                    vous pouvez télécharger et accéder au site sur un seul
                    appareil mobile et accéder à la plate-forme en utilisant les
                    justificatifs d'identité de l'utilisateur correctement émis.
                    Tous les autres droits sur le site sont réservés par la
                    société.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    En cas de violation des présentes conditions, nous serons en
                    droit de mettre fin immédiatement à votre utilisation et à
                    votre accès au site et à la plate-forme.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous vous engagez à ne pas :
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (a) modifier, adapter, reproduire, traduire ou créer des
                    œuvres dérivées du site ou de la plate-forme, ou de toute
                    donnée ou contenu (y compris le contenu de tiers) fourni par
                    l'intermédiaire du site ou de la plate-forme, ou de toute
                    partie de ceux-ci, ou tenter de faire de l'ingénierie
                    inverse, de décompiler, de désassembler ou de tenter de
                    toute autre manière de découvrir le code source du site ou
                    de la plate-forme ;
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (b) supprimer tout avis de droit d'auteur, marque, légende,
                    logo ou identification de produit du site ou de la
                    plate-forme ;
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (c) faire passer les autres sites pour le site de la Société
                    en cooptant l'aspect visuel ou le texte du site de la
                    Société ou violer de toute autre manière les droits de
                    propriété intellectuelle de la Société, y compris, sans s'y
                    limiter, en "récupérant" du texte ou des images du site de
                    la Société ou des bannières et/ou des liens textuels gérés
                    par la Société, en faisant du marketing de recherche ou
                    toute autre campagne en ligne et hors ligne,
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (d) éditer, modifier, filtrer, tronquer ou changer l'ordre
                    des informations contenues dans toute partie des sites de la
                    société, ou supprimer, obscurcir ou minimiser toute partie
                    du site de la société de quelque manière que ce soit sans
                    l'autorisation de la société ; ou
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (e) faire un usage commercial du Site ou de la Plateforme ou
                    du logo, de la marque ou du nom de marque de la Société de
                    quelque manière que ce soit.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Chaque Utilisateur autorise la Société à utiliser toute
                    information ou contenu fourni par l'Utilisateur ou traité
                    dans le cadre de l'utilisation du Site et de la Plateforme
                    (par exemple, les Données personnelles, les informations
                    géographiques, les informations sur les appareils) dans le
                    contexte et dans le but de fournir des services ou des
                    produits sur la Plateforme et de sécuriser l'utilisation du
                    Site et de la Plateforme.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Fourniture de matériel et d'informations
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous reconnaissez que ni la Société ni la Plateforme ne sont
                    vos conseillers en voyage ou votre fiduciaire. Vous
                    reconnaissez en outre qu'aucun des documents que nous
                    fournissons ou qui sont mis à disposition sur la plateforme
                    ne constitue une recommandation ou une sollicitation de
                    notre part vous incitant à prendre une décision particulière
                    au cours de votre voyage.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous reconnaissez que nous n'avons aucun devoir ou
                    obligation de vérifier, corriger, compléter ou mettre à jour
                    tout matériel affiché sur la Plateforme. Le matériel, y
                    compris, mais sans s'y limiter, les nouvelles et les
                    recherches, peut être préparé par des fournisseurs
                    d'informations qui sont indépendants de nous. Nous ne
                    garantissons pas que le matériel sera exact, complet ou mis
                    à jour en temps utile. Vous devez effectuer des recherches
                    et des analyses plus approfondies ou consulter un conseiller
                    en voyages avant de prendre des décisions. Toute utilisation
                    ou confiance que vous accordez au matériel est à vos risques
                    et périls.{' '}
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Notifications de service et autres
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous acceptez les notifications concernant l'application, la
                    plate-forme, votre compte et les conditions par le biais des
                    notifications de service. Vous acceptez que ces
                    notifications de service constituent une notification
                    effective en lieu et place d'une notification écrite,
                    envoyée par la poste ou d'autres formes de notification
                    requises par la loi applicable.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Il est de votre seule responsabilité de surveiller le compte
                    de messagerie applicable sans autre rappel ou notification
                    répétée de la part de la Société. Vous devez immédiatement
                    signaler toute utilisation ou tout accès non autorisé à la
                    plate-forme.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous dégagez la Société de toute responsabilité en cas de
                    pertes ou de dommages résultant de l'utilisation des
                    notifications de service, dans les limites autorisées par la
                    loi. La Société ne garantit pas et n'accepte aucune
                    responsabilité quant à l'actualité, l'exactitude et
                    l'exhaustivité des informations fournies par le biais des
                    notifications de service.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Informations personnelles
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Dans le cadre de la plate-forme, les informations
                    personnelles de l'utilisateur peuvent être collectées,
                    utilisées, transférées, divulguées ou traitées d'une autre
                    manière par la société conformément à la politique de
                    confidentialité. Vous devez lire attentivement la politique
                    de confidentialité avant de vous inscrire et d'utiliser le
                    site et la plate-forme.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous consentez à la collecte, à l'utilisation et à la
                    divulgation de vos informations personnelles conformément
                    aux présentes conditions et à la politique de
                    confidentialité, y compris, mais sans s'y limiter, à la
                    divulgation au fournisseur de services tiers dans le but de
                    fournir des services et d'effectuer des transactions en
                    rapport avec le compte.{' '}
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous acceptez de fournir des informations personnelles
                    véridiques, exactes, actuelles et complètes. Vous acceptez
                    en outre de maintenir et de mettre à jour rapidement les
                    informations personnelles afin qu'elles restent véridiques,
                    exactes, actuelles et complètes à tout moment pendant la
                    durée du présent accord.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous devez nous informer rapidement de tout changement, y
                    compris, mais sans s'y limiter, des changements dans les
                    informations personnelles en rapport avec la plate-forme. Si
                    vous fournissez des informations fausses, inexactes,
                    périmées ou incomplètes, ou si nous ou l'un de nos agents
                    autorisés avons des motifs raisonnables de soupçonner que
                    ces informations sont fausses, inexactes, périmées ou
                    incomplètes, nous avons le droit de suspendre ou de résilier
                    le compte et de refuser toute utilisation actuelle ou future
                    de la plate-forme et du site par vous, ainsi que d'engager
                    votre responsabilité civile ou de vous déférer aux autorités
                    compétentes chargées de l'application de la loi pour des
                    poursuites pénales.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nous ne serons pas tenus de verser une quelconque
                    compensation, monétaire ou autre, à la suite d'une telle
                    suspension, résiliation ou impossibilité d'utiliser la
                    Plateforme ou le Site.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Veuillez noter que nous pouvons collecter des informations à
                    l'aide de technologies de suivi concernant votre appareil,
                    telles que l'adresse IP, le fournisseur de réseau,
                    l'opérateur mobile, le type de navigateur mobile,
                    l'horodatage, le fuseau horaire, des informations sur la
                    vitesse, le relèvement, l'orientation et l'altitude d'un
                    appareil, ou d'autres informations d'identification de
                    l'appareil. L'utilisateur consent à cette utilisation des
                    technologies de suivi et reconnaît que les informations
                    obtenues, y compris les informations personnelles, peuvent
                    être mises en correspondance avec des informations publiques
                    ou privées accessibles à la société ou à tout fournisseur de
                    services tiers. L'utilisateur consent également à ce que ces
                    informations soient partagées avec les fournisseurs de
                    services de la société et des fournisseurs de services tiers
                    dans le but de fournir et de maintenir les technologies de
                    suivi et les services connexes. Nous pouvons également
                    collecter des données de géolocalisation précises à partir
                    de votre appareil ou à propos de celui-ci, qui peuvent être
                    exprimées par des coordonnées de latitude et de longitude
                    obtenues par des outils GPS, des données WiFi, la
                    triangulation d'une tour de téléphonie cellulaire ou
                    d'autres techniques. L'utilisation que nous faisons de ces
                    informations est décrite dans notre politique de
                    confidentialité.
                </span>
            </p>

            <p
                style={{
                    marginTop: '12pt',
                    marginBottom: '12pt',
                    textAlign: 'justify',
                }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Cookies et comment nous les utilisons
                    </span>
                </strong>
            </p>
            <p
                style={{
                    marginTop: '12pt',
                    marginBottom: '12pt',
                    textAlign: 'justify',
                }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Les cookies sont des fichiers texte placés sur votre
                    ordinateur pour recueillir des informations standard de
                    connexion Internet et sur le comportement des visiteurs.
                    Lorsque vous visitez nos sites web, nous pouvons
                    automatiquement collecter des informations vous concernant
                    via des cookies ou une technologie similaire. SafeTravy
                    utilise des cookies de différentes façons pour améliorer
                    votre expérience sur notre site web et nos autres services
                    numériques.
                </span>
            </p>
            <p
                style={{
                    marginTop: '12pt',
                    marginBottom: '12pt',
                    textAlign: 'justify',
                }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Il existe différents types de cookies ; cependant, notre
                    site utilise :
                </span>
            </p>
            <ul
                className='awlist1'
                style={{ margin: '0pt', paddingLeft: '0pt' }}>
                <li
                    style={{
                        marginTop: '12pt',
                        marginLeft: '22.5pt',
                        textIndent: '-18pt',
                        fontFamily: '"Times New Roman"',
                    }}>
                    <span
                        style={{
                            width: '14.34pt',
                            font: '7pt "Times New Roman"',
                            display: 'inline-block',
                        }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    </span>
                    Informations de compte et de connexion
                </li>
                <li
                    style={{
                        marginLeft: '22.5pt',
                        textIndent: '-18pt',
                        fontFamily: '"Times New Roman"',
                    }}>
                    <span
                        style={{
                            width: '14.34pt',
                            font: '7pt "Times New Roman"',
                            display: 'inline-block',
                        }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    </span>
                    Abonnements à la newsletter par e-mail
                </li>
                <li
                    style={{
                        marginLeft: '22.5pt',
                        textIndent: '-18pt',
                        fontFamily: '"Times New Roman"',
                    }}>
                    <span
                        style={{
                            width: '14.34pt',
                            font: '7pt "Times New Roman"',
                            display: 'inline-block',
                        }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    </span>
                    Sondages et formulaires
                </li>
                <li
                    style={{
                        marginLeft: '22.5pt',
                        textIndent: '-18pt',
                        fontFamily: '"Times New Roman"',
                    }}>
                    <span
                        style={{
                            width: '14.34pt',
                            font: '7pt "Times New Roman"',
                            display: 'inline-block',
                        }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    </span>
                    Préférences du site
                </li>
                <li
                    style={{
                        marginLeft: '22.5pt',
                        textIndent: '-18pt',
                        fontFamily: '"Times New Roman"',
                    }}>
                    <span
                        style={{
                            width: '14.34pt',
                            font: '7pt "Times New Roman"',
                            display: 'inline-block',
                        }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    </span>
                    Fonctionnalité – SafeTravy utilise ces cookies pour vous
                    reconnaître sur notre site et se souvenir de vos préférences
                    précédemment sélectionnées. Cela peut inclure la langue que
                    vous préférez et votre emplacement. Nous utilisons un
                    mélange de cookies de première et de tierce partie.
                </li>
                <li
                    style={{
                        marginLeft: '22.5pt',
                        marginBottom: '12pt',
                        textIndent: '-18pt',
                        fontFamily: '"Times New Roman"',
                    }}>
                    <span
                        style={{
                            width: '14.34pt',
                            font: '7pt "Times New Roman"',
                            display: 'inline-block',
                        }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    </span>
                    Publicité – SafeTravy utilise ces cookies pour recueillir
                    des informations sur votre visite de notre site, le contenu
                    que vous avez consulté, les liens que vous avez suivis ainsi
                    que des informations sur votre navigateur, appareil et votre
                    adresse IP. SafeTravy partage parfois certains aspects
                    limités de ces données avec des tiers à des fins
                    publicitaires. Nous pouvons également partager les données
                    en ligne collectées via les cookies avec nos partenaires
                    publicitaires. Cela signifie que lorsque vous visitez un
                    autre site, vous pourriez voir des publicités basées sur vos
                    habitudes de navigation sur notre site.
                </li>
            </ul>
            <p
                style={{
                    marginTop: '12pt',
                    marginBottom: '12pt',
                    textAlign: 'justify',
                }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Vous pouvez configurer votre navigateur pour refuser tous ou
                    certains cookies, ou pour vous avertir lorsque des sites web
                    définissent ou accèdent à des cookies. Si vous désactivez ou
                    refusez les cookies, veuillez noter que certaines parties de
                    nos sites, produits et services peuvent devenir
                    inaccessibles ou ne pas fonctionner correctement.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Clause de non-responsabilité et risques liés à
                        l'utilisation de la plate-forme
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    La Plateforme et le Site, y compris tout le contenu (y
                    compris le contenu de tiers), les fonctionnalités et tous
                    les services connexes sont fournis "en l'état" et "en
                    fonction des disponibilités", au seul risque de
                    l'utilisateur et sans aucune déclaration ou garantie. Nous
                    ne garantissons pas que tout ou partie de la Plateforme ou
                    de l'Application sera disponible ou accessible par
                    l'Utilisateur à tout moment.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    L'utilisation de la Plateforme, du fait du téléchargement,
                    de l'installation ou de l'utilisation du Site et des points
                    de référence associés avec des tiers (par exemple, des
                    fournisseurs de plateformes de distribution, des
                    fournisseurs de réseaux, des fabricants d'appareils)
                    comporte des risques, en particulier :
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (a) la divulgation à des tiers de vos informations
                    personnelles ou d'autres informations et de l'existence de
                    votre relation avec le prestataire de services tiers ;
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (b) les pannes de système, les restrictions liées à la
                    sécurité et la suppression non autorisée des restrictions
                    d'utilisation sur l'appareil final, ainsi que d'autres
                    perturbations susceptibles de rendre l'utilisation
                    impossible ; et
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    (c) une mauvaise utilisation due à une manipulation par des
                    logiciels malveillants ou une utilisation non autorisée, y
                    compris en cas de perte ou de vol de l'appareil de
                    l'utilisateur utilisé pour accéder au site ou à la
                    plateforme.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nous sommes en droit de bloquer ou de désactiver
                    l'utilisation du Site sur les appareils finaux si les
                    dispositifs de sécurité conçus par le système d'exploitation
                    ou le fabricant de l'appareil sur lequel le Site est
                    installé ont été modifiés à tout moment (par exemple, un
                    appareil qui a été "jailbreaké"). Par conséquent, nous ne
                    garantissons pas le fonctionnement et l'exploitation de
                    l'App sur des appareils finaux qui ont été modifiés de cette
                    manière ou sur des appareils finaux plus anciens qui ne
                    répondent plus aux exigences techniques pour l'utilisation
                    du Site ou l'accès à la Plateforme.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    TOUTES LES GARANTIES, CONDITIONS OU TERMES (QU'ILS SOIENT
                    EXPLICITES, IMPLICITES, STATUTAIRES OU AUTRES), Y COMPRIS,
                    SANS S'Y LIMITER, LES GARANTIES DE QUALITÉ, DE QUALITÉ
                    MARCHANDE, D'ADÉQUATION À UN USAGE PARTICULIER OU D'ACCÈS
                    ININTERROMPU ET SANS ERREUR, SONT EXPRESSÉMENT EXCLUS DU
                    SITE ET DE LA PLATE-FORME DANS TOUTE LA MESURE PERMISE PAR
                    LA LOI.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Aucune déclaration ou garantie, expresse ou implicite, ne
                    peut être donnée quant à l'exactitude ou à l'exhaustivité
                    des informations fournies dans la plate-forme.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Chaque utilisateur reconnaît et accepte les risques qui
                    peuvent découler de l'expérience du voyage. Nous pouvons
                    également utiliser des serveurs et d'autres matériels
                    informatiques situés dans n'importe quelle juridiction dans
                    le monde entier pour la fourniture de toute partie de la
                    plate-forme.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nous excluons toute responsabilité en cas de perte ou de
                    dommage causé par des erreurs de transmission, des
                    défaillances techniques, des pannes, des interruptions
                    d'activité ou des interventions illégales dans les réseaux
                    de transmission, les systèmes informatiques de l'utilisateur
                    ou d'un tiers (y compris les systèmes du domaine public).
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Cessation d'activité
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nous pouvons résilier ou suspendre votre compte
                    immédiatement, sans préavis ni responsabilité, pour quelque
                    raison que ce soit, y compris, mais sans s'y limiter, si
                    vous enfreignez les présentes conditions générales.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    En cas de résiliation, votre droit d'utiliser le service
                    cessera immédiatement. Si vous souhaitez résilier votre
                    compte, vous pouvez simplement cesser d'utiliser le service.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Libération
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Dans toute la mesure permise par la loi applicable, vous
                    nous déchargez, nous acquittez et nous libérez de toute
                    autre manière, ainsi que notre société mère, nos sociétés
                    affiliées et nos filiales, et chacun de leurs dirigeants,
                    administrateurs, actionnaires, membres, partenaires,
                    avocats, employés, entrepreneurs indépendants, fournisseurs
                    de télécommunications et agents respectifs (collectivement,
                    les " parties indemnisées "), de toutes les allégations,
                    chefs d'accusation, charges, dettes, causes d'action,
                    réclamations et pertes liées de quelque manière que ce soit
                    à l'utilisation ou aux activités liées à l'utilisation du
                    site, de la plate-forme, de tout compte et de tout service
                    ou contenu de tiers fourni par l'intermédiaire du site, de
                    la plate-forme ou de tout compte, y compris, mais sans s'y
                    limiter, les réclamations liées à ce qui suit : négligence,
                    négligence grave, interférence intentionnelle avec un
                    contrat ou une relation commerciale avantageuse,
                    diffamation, vie privée, publicité, fausse déclaration,
                    fausses identités, actes frauduleux commis par des tiers,
                    atteinte à la vie privée, divulgation d'informations
                    personnelles, échec de transactions, d'achats ou de
                    fonctionnalités de la plateforme, indisponibilité du site,
                    de la plateforme, du contenu de tiers ou de tout compte, de
                    leurs fonctions et de toute autre défaillance technique
                    pouvant entraîner l'inaccessibilité au site, à la
                    plate-forme, au contenu de tiers ou à tout compte, ou toute
                    réclamation fondée sur la responsabilité du fait d'autrui
                    pour des délits commis par vous et rencontrés ou traités par
                    l'intermédiaire du site, de la plate-forme, du contenu de
                    tiers et de tout compte, y compris, mais sans s'y limiter,
                    la fraude, le piratage informatique, le vol ou l'utilisation
                    abusive d'informations personnelles, les coups et blessures,
                    le harcèlement, le viol, la tricherie, le parjure,
                    l'homicide involontaire ou l'assassinat.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    La liste ci-dessus n'est donnée qu'à titre d'exemple et
                    n'est pas exhaustive des types ou catégories de réclamations
                    auxquelles nous renonçons.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    La présente décharge est destinée par les parties à être
                    interprétée largement en notre faveur, et donc toute
                    ambiguïté sera interprétée de manière à permettre la
                    décharge des réclamations les plus larges.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    La présente décharge est censée être une décharge complète
                    des réclamations, et les parties reconnaissent la nature
                    juridiquement contraignante de cette disposition, ainsi que
                    la nature des droits auxquels elles renoncent dans ce cadre.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Limitation de la responsabilité
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    SafeTravy n'est pas responsable des informations disponibles
                    via et/ou intégrées aux produits SafeTravy. Par conséquent,
                    SafeTravy ne peut être tenu responsable de l'exactitude, de
                    l'exhaustivité, de la qualité, de la légalité et de la
                    fiabilité des informations ou des résultats obtenus à l'aide
                    de ces informations.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Les Parties (Société et Utilisateur) conviennent et
                    comprennent que le prix de l'Abonnement est hors taxes (TVA
                    ou autres taxes payables dans le pays de résidence de
                    l'Utilisateur). L'Utilisateur accepte par la présente que
                    dans les cas prévus par la législation locale, en plus de
                    payer le prix de l'Abonnement, l'Utilisateur devra payer le
                    montant de la taxe prélevée dans le cadre de l'achat de
                    l'Abonnement.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Les services SafeTravy ne constituent pas des outils à
                    utiliser pour la gestion et l'évaluation des risques.
                    SafeTravy n'est pas en mesure de garantir que toutes les
                    informations et mises à jour sont complètes, adéquates et
                    exemptes d'erreurs. Les utilisateurs doivent toujours
                    évaluer les résultats et les données du service sur une base
                    individuelle. SafeTravy vise à réduire les risques pour les
                    utilisateurs mais n'est en aucun cas responsable de la
                    sûreté et de la sécurité des utilisateurs et SafeTravy
                    n'assume aucune responsabilité pour les pertes, les dommages
                    corporels ou le décès des utilisateurs.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    EN AUCUN CAS ET EN VERTU D'AUCUNE THÉORIE DU DROIT (DÉLIT,
                    CONTRAT, RESPONSABILITÉ STRICTE OU AUTRE), NOUS OU L'UN DES
                    INDEMNISÉS NE SERONS RESPONSABLES ENVERS VOUS OU TOUTE AUTRE
                    PERSONNE DE TOUT DOMMAGE DÉCOULANT DE L'UTILISATION OU DE LA
                    MAUVAISE UTILISATION, OU DE L'INCAPACITÉ D'UTILISER LA
                    PLATEFORME, LE SITE, LE CONTENU DE TIERS OU TOUT COMPTE, QUE
                    CES DOMMAGES SOIENT DIRECTS, INDIRECTS, SPÉCIAUX,
                    ACCESSOIRES OU CONSÉCUTIFS DE TOUTE NATURE, DE LA PERTE
                    D'INFORMATIONS, DE L'INTERRUPTION DES ACTIVITÉS OU DE LA
                    PERTE DE BÉNÉFICES, PERTE D'ÉCONOMIES OU DE DONNÉES, OU DES
                    RESPONSABILITÉS EN VERTU DE TOUT CONTRAT, NÉGLIGENCE,
                    RESPONSABILITÉ STRICTE OU AUTRE THÉORIE DÉCOULANT DE OU LIÉE
                    DE QUELQUE MANIÈRE QUE CE SOIT AU SITE, À LA PLATE-FORME, AU
                    CONTENU DE TIERS OU À TOUT COMPTE OU POUR TOUTE RÉCLAMATION
                    OU DEMANDE PAR UN TIERS, MÊME SI NOUS CONNAISSIONS OU AVIONS
                    DES RAISONS DE CONNAÎTRE LA POSSIBILITÉ DE TELS DOMMAGES,
                    RÉCLAMATIONS OU DEMANDES SI LA CLAUSE DE NON-RESPONSABILITÉ
                    ET DE RENONCIATION À LA RESPONSABILITÉ QUI PRÉCÈDE DEVAIT
                    ÊTRE CONSIDÉRÉE COMME INVALIDE OU INEFFICACE. CERTAINES
                    JURIDICTIONS N'AUTORISENT PAS L'EXCLUSION OU LA LIMITATION
                    DE CERTAINES GARANTIES ET/OU RESPONSABILITÉS, DE SORTE QUE
                    CERTAINES DES LIMITATIONS OU EXCLUSIONS SUSMENTIONNÉES
                    PEUVENT NE PAS S'APPLIQUER À VOUS.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    EN AUCUN CAS NOTRE RESPONSABILITÉ, QUELLE QUE SOIT LA FORME
                    DE L'ACTION ET LES DOMMAGES SUBIS PAR VOUS, NE DÉPASSERA LE
                    MONTANT TOTAL LE PLUS ÉLEVÉ DES FRAIS D'ABONNEMENT QUE VOUS
                    NOUS AVEZ PAYÉS EN RAPPORT AVEC LA PLATEFORME OU LE SITE, OU
                    300 EUR, LE MONTANT LE PLUS ÉLEVÉ ÉTANT RETENU.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nous ne serons pas responsables de notre incapacité à
                    remplir les obligations prévues par les présentes conditions
                    en raison d'événements indépendants de notre volonté, et le
                    délai prévu pour remplir ces obligations sera prolongé d'une
                    période égale à la durée de ces événements.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Les événements indépendants de notre volonté comprennent,
                    sans s'y limiter, les cas de force majeure, la guerre, les
                    émeutes, les incendies volontaires, les embargos, les
                    troubles civils, les grèves, les conflits du travail, les
                    pannes d'équipement, les défaillances bancaires,
                    l'effondrement ou les fluctuations du marché des monnaies
                    virtuelles, les défaillances du traitement des transactions
                    par carte de crédit ou de débit, les grèves, les incendies,
                    les inondations, les tremblements de terre, les ouragans,
                    les tempêtes tropicales ou autres catastrophes naturelles,
                    les pénuries de main-d'œuvre ou de matériel, les pénuries de
                    transport, d'installations, de carburant, d'énergie, les
                    réglementations ou restrictions imposées par les pouvoirs
                    publics, actes d'autorité civile ou militaire ou de
                    terrorisme, coupures de fibres, conditions météorologiques,
                    violations ou manquements de la part de tiers, problèmes
                    techniques, y compris les pannes de matériel et de logiciel
                    et autres dysfonctionnements, défaillance de
                    l'infrastructure des télécommunications ou des services
                    d'information, piratage, SPAM ou défaillance de tout
                    ordinateur, serveur ou logiciel, perturbations dues ou
                    causées par le vandalisme, le vol, les coupures de service
                    téléphonique, les pannes d'électricité, les perturbations de
                    l'Internet, les virus et les pannes mécaniques, électriques
                    ou de communication.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Modifications des présentes conditions générales
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nous nous réservons le droit, à notre seule discrétion, de
                    modifier ou de remplacer les présentes conditions à tout
                    moment. Si une révision est importante, nous nous
                    efforcerons de fournir un préavis d'au moins 30 jours avant
                    l'entrée en vigueur des nouvelles conditions. La définition
                    de ce qui constitue une modification substantielle est
                    laissée à notre seule discrétion.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    En continuant à accéder à notre service ou à l'utiliser
                    après l'entrée en vigueur de ces révisions, vous acceptez
                    d'être lié par les conditions révisées. Si vous n'acceptez
                    pas les nouvelles conditions, en tout ou en partie, veuillez
                    cesser d'utiliser le site web et le service.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Général
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Les présentes conditions, y compris la politique de
                    confidentialité et les autres politiques qui y sont
                    incorporées, constituent l'intégralité et le seul accord
                    entre vous et la société en ce qui concerne l'objet des
                    présentes conditions et remplacent tous les accords,
                    déclarations, garanties et ententes antérieurs ou
                    contemporains, écrits ou oraux, en ce qui concerne l'objet
                    des présentes conditions. Si une disposition des présentes
                    conditions est jugée illégale, nulle ou inapplicable pour
                    quelque raison que ce soit, cette disposition sera
                    considérée comme dissociable des présentes conditions et
                    n'affectera pas la validité et l'applicabilité des autres
                    dispositions. Les présentes conditions ne peuvent être
                    modifiées, ni faire l'objet d'une renonciation ou d'une
                    modification, sauf par la société, conformément aux
                    dispositions des présentes. Vous ne pouvez céder,
                    transférer, déléguer ou concéder en sous-licence ni les
                    présentes conditions ni aucun droit, obligation ou recours
                    qui en découle, sauf avec notre consentement écrit
                    préalable, et toute tentative de cession, de transfert, de
                    délégation ou de concession de sous-licence sera nulle et
                    non avenue. Toute tentative de cession, de transfert, de
                    délégation ou de sous-licence sera nulle et non avenue.
                    Aucune renonciation par une partie à une violation ou à un
                    manquement aux termes des présentes ne sera considérée comme
                    une renonciation à une violation ou à un manquement
                    antérieur ou ultérieur.
                </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Tout titre, légende ou titre de section contenu dans les
                    présentes conditions n'est inséré qu'à des fins de commodité
                    et ne définit ni n'explique en aucune façon une section ou
                    une disposition des présentes.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Droit applicable et règlement des litiges
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Tous les litiges, différends ou réclamations découlant du
                    présent accord ou en rapport avec celui-ci, y compris ceux
                    relatifs à sa conclusion, son interprétation, son exécution,
                    sa violation, sa résiliation ou sa nullité, seront résolus
                    par la Cour d'arbitrage commercial international de la
                    Chambre de commerce et d'industrie ukrainienne, conformément
                    à son règlement.
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Le droit régissant cet accord est le droit matériel de
                    Gibraltar.{' '}
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    La cour d'arbitrage est composée d'un arbitre unique. Le
                    lieu de réunion de la Cour d'arbitrage est la ville de Kiev,
                    en Ukraine. La langue de la procédure d'arbitrage est
                    l'anglais ou l'ukrainien.
                </span>
            </p>

            <p style={{ textAlign: 'justify' }}>
                <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                        Nous contacter
                    </span>
                </strong>
            </p>
            <p style={{ textAlign: 'justify' }}></p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si vous avez des questions concernant nos conditions
                    générales, vous pouvez nous contacter :
                </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: '"Times New Roman"' }}>
                    Par courrier électronique :{' '}
                </span>
                <a href='team@safetravy.com' style={{ textDecoration: 'none' }}>
                    <u>
                        <span
                            style={{
                                fontFamily: '"Times New Roman"',
                                color: '#1155cc',
                            }}>
                            <span className='__cf_email__'>
                                team@safetravy.com
                            </span>
                        </span>
                    </u>
                </a>
            </p>
        </>
    )
}
