import { PrimaryButton } from '@/core/components'
import styles from './sytyles.module.css'
import { useTranslation } from 'react-i18next'

interface IProps {
    updateSubscribe?: () => void
    cancelSubscribe?: () => void
    isCanceled: boolean
}
export const MobileFooterSubscribeAtom = ({
    updateSubscribe,
    cancelSubscribe,
    isCanceled,
}: IProps) => {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <PrimaryButton
                label={t('updateSubscriptionBtn')}
                onClick={updateSubscribe}
            />

            <PrimaryButton
                disabled={isCanceled}
                labelStyle={{ color: '#262933' }}
                className={styles.cancel_btn}
                label={t('cancelSubscribtionBtn')}
                onClick={cancelSubscribe}
            />
        </div>
    )
}
