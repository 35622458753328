import { Root } from './modules/root'
import { I18nextProvider } from 'react-i18next'

import i18n from '@/i18n'
import { useEffect } from 'react'
import { storageService } from './core/services'
import { Languages } from './core/typing'
import { useLanguageStore } from './store'
import { Helmet, HelmetProvider } from 'react-helmet-async'

function App() {
    const { lang, setLang } = useLanguageStore()

    const getLanguage = () => {
        const language = storageService.get('lang')
        if (!language) {
            storageService.set('lang', Languages.EN)
        } else {
            setLang(language as Languages)
        }
    }

    useEffect(() => {
        getLanguage()
    }, [lang])

    useEffect(() => {
        i18n.changeLanguage(lang)
    }, [lang])
    return (
        <HelmetProvider>
            <I18nextProvider i18n={i18n}>
                <Helmet>
                    <script
                        async
                        src='https://www.googletagmanager.com/gtag/js?id=G-JYJYDMKDBS'></script>
                    <script>
                        {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-JYJYDMKDBS');
          `}
                    </script>
                </Helmet>
                <Root />
            </I18nextProvider>
        </HelmetProvider>
    )
}

export default App
