import { Languages } from '@/core/typing'
import { PolicyEn } from './policy_en'
import { PolicyEsp } from './policy_esp'
import { PolicyFr } from './policy_fr'
import { PolicyDe } from './policy_de'

export const policyTranslates: any = {
    [Languages.EN]: <PolicyEn />,
    [Languages.ES]: <PolicyEsp />,
    [Languages.FR]: <PolicyFr />,
    [Languages.DE]: <PolicyDe />,
}
