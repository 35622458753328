import { getArticleReq } from '../../api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Drawer } from 'antd'
import styles from './styles.module.css'
import { Icon, PrimaryButton } from '@/core/components'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { risksColor } from '@/modules/map/config'
import { SubcategoryRisks } from '@/core/typing'
import { ICategoryGroup, useAccountStore, useLanguageStore } from '@/store'
import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import { appEvents } from '@/core/events'
import * as Sentry from '@sentry/react'
import { useResizeWindow } from '@/core/hooks'
import { useTranslation } from 'react-i18next'
import { menuLabels, pdfFileNames } from '@/config/risks.config'
import html2pdf from 'html2pdf.js'

interface IProps {
    countryCode: { code: string; longName: string }
    open: boolean
    onChangeDrawer: (val: boolean) => void
    categoryGroup: ICategoryGroup
}
export const ArticleWidget = ({
    countryCode,
    open,
    onChangeDrawer,
    categoryGroup,
}: IProps) => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()
    const { account } = useAccountStore()
    const { width: widthWindow } = useResizeWindow()

    const [article, setArticle] = useState('')
    const [historyRisks, setHistory] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const isBasic =
        account?.subscription?.type === SubscriptionType.Basic ||
        account?.subscription?.type === SubscriptionType.Basic_3m

    const goTariff = () => {
        onChangeDrawer(false)
        appEvents.emit('subsribe', { isShow: true, method: 'buy' })
    }

    const getArticle = async () => {
        setIsLoading(true)
        try {
            const { data } = await getArticleReq(countryCode.code, {
                ...categoryGroup,
                lang,
            })

            setArticle(data.article.content)
            setHistory(
                data.risksLevels.sort((a: any, b: any) => b.year - a.year),
            )
        } catch (error: any) {
            Sentry.captureException(error)
            //
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getArticle()
    }, [countryCode, open, article])

    const shortArticle = useCallback(() => {
        if (
            account?.subscription?.type === SubscriptionType.Basic ||
            account?.subscription?.type === SubscriptionType.Basic_3m
        ) {
            return styles.short_atricle
        }
        return ''
    }, [account?.subscription?.type])

    const showMore = () => {
        if (
            account?.subscription?.type === SubscriptionType.Basic ||
            account?.subscription?.type === SubscriptionType.Basic_3m
        ) {
            return (
                <div className={styles.blur_overlay}>
                    <div className={styles.show_more}>
                        <PrimaryButton
                            label={t('showMore')}
                            onClick={goTariff}
                            style={{
                                width: 168,
                                boxShadow: ' 0 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                            labelStyle={{ fontSize: 14, fontWeight: '600' }}
                        />
                    </div>
                </div>
            )
        }
        return null
    }

    const risksHistory = useMemo(() => {
        if (categoryGroup?.subcategory !== SubcategoryRisks.Geopolitical)
            return null
        return (
            <div className={styles.history_container}>
                {historyRisks?.map((it: any) => {
                    return (
                        <div className={styles.risk_year}>
                            <div
                                style={{
                                    backgroundColor: risksColor[it?.riskLevel],
                                    height: 24,
                                    width: 24,
                                    borderRadius: 110,
                                }}
                            />
                            <p>{it?.year}</p>
                        </div>
                    )
                })}
            </div>
        )
    }, [historyRisks, categoryGroup?.subcategory, open])

    const drawerWidth = () => {
        switch (true) {
            case widthWindow < 767:
                return '100dvw'

            case widthWindow < 1024:
                return 'calc(100dvw - 16px)'

            default:
                return '720px'
        }
    }

    const getCategoryLabel = () => {
        if (categoryGroup?.category && categoryGroup?.subcategory) {
            return menuLabels[categoryGroup?.subcategory]
        } else {
            return menuLabels[categoryGroup?.category]
        }
    }

    const getFileName = () => {
        if (categoryGroup?.category && categoryGroup?.subcategory) {
            return pdfFileNames[categoryGroup?.subcategory]
        } else {
            return pdfFileNames[categoryGroup?.category]
        }
    }

    const convertHtmlToPdf = (): void => {
        const categoryLabel = t(`${getCategoryLabel()}`)
        const fileName = `${getFileName()}.${countryCode?.code?.toLowerCase()}.pdf`
        const container = document.createElement('div')
        container.innerHTML = container.innerHTML = `
        <div style="text-align: center; margin-bottom: 20px;">
        
            <h1 style="font-size: 24px; margin-top: 10px;">${countryCode?.longName}</h1>
            <h2 style="font-size: 20px; margin-top: 8px;">${categoryLabel}</h2>
        </div>
        <div style='line-height:20px;'>${article}</div>
         <p style=" color: #717375;
    font-size: 12px;
    font-weight: 500;">
                        * ${t('artificialIntelligenc')}
                    </p>
    `
        document.body.appendChild(container)

        const options = {
            margin: [10, 10, 10, 10],
            filename: fileName,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                scale: 2,
                logging: true,
                dpi: 192,
                letterRendering: true,
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }

        html2pdf()
            .from(container)
            .set(options)
            .save()
            .then(() => {
                document.body.removeChild(container)
            })
    }

    return (
        <Drawer
            size='large'
            destroyOnClose
            placement='right'
            closable={false}
            open={open}
            className={styles.articleDrawer}
            styles={{
                body: {
                    padding: 0,
                    paddingBottom: 113,
                    paddingLeft: 1,
                },
                wrapper: {
                    width: drawerWidth(),
                    margin: widthWindow > 767 ? '8.5px 8px' : 0,
                    boxShadow: 'none',
                },
            }}
            onClose={() => onChangeDrawer(false)}>
            <div className={styles.header}>
                <div className={styles.labelsBlock}>
                    <p className={styles.label}>{countryCode?.longName}</p>
                    <p className={styles.categoryLabel}>
                        {t(`${getCategoryLabel()}`)}
                    </p>
                </div>

                <div className={styles.buttonsBlock}>
                    {!isBasic && (
                        <div className={styles.downloadBtn}>
                            <Icon
                                name='document-download'
                                size={16}
                                color='#5E626D'
                                onClick={convertHtmlToPdf}
                            />
                        </div>
                    )}

                    <Icon
                        name='x'
                        size={22}
                        color='#5E626D'
                        onClick={() => onChangeDrawer(false)}
                    />
                </div>
            </div>
            {isLoading ? (
                <Skeleton count={13} className={styles.sceletons} />
            ) : (
                <div
                    className={`${styles.drawer_container} ${isBasic ? styles.stop_scroll : styles.allow_scroll}`}>
                    <div
                        className={`${shortArticle()} ${styles.content_atricle}`}
                        dangerouslySetInnerHTML={{
                            __html: article.split('<p><p/>')[0],
                        }}
                    />
                    {showMore()}
                </div>
            )}

            <div className={styles.sticky_footer}>
                <div className={styles.article_sticky_footer}>
                    <p className={styles.article_check}>
                        * {t('artificialIntelligenc')}
                    </p>
                </div>
                <>{risksHistory}</>
            </div>
        </Drawer>
    )
}
