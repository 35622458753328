import { Icon, PrimaryButton } from '@/core/components'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

export const BannerInsurance = () => {
    const { t } = useTranslation()
    const [show, setShow] = useState(true)

    const openInsuranceForm = () => {
        window.open('https://auras.insure/?partner_id=63#form', '_blank')
    }
    if (!show) return null
    return (
        <div className={styles.container}>
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <p className={styles.title}>{t('insuranceTitle')}</p>
                    <Icon
                        className={styles.icon_close}
                        name='x'
                        size={24}
                        color='#FFFFFF'
                        onClick={() => setShow(false)}
                    />
                </div>

                <p className={styles.txt_content}>{t('insuranceTxt')}</p>
            </div>

            <PrimaryButton
                label={t('insuranceAction')}
                labelStyle={{
                    fontSize: 12,
                    lineHeight: '14px',
                    fontWeight: '500',
                    color: '#000000',
                }}
                className={styles.action_btn}
                onClick={openInsuranceForm}
            />
        </div>
    )
}
