export const PolicyEn = () => {
    return (
        <>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                This Privacy Policy (this “Policy”) applies to the trading
                platform (including any applicable mobile applications and
                websites used to access the same) (collectively the “Platform”)
                provided by SafeTravy Limited (the “Company,” “we,” “us” or
                “our”). It describes how the Company collects, uses, and
                discloses Personal Information that we obtain from Users of the
                Platform and any account services provided through the Platform,
                and how we use and disclose that information. For purposes of
                this Policy, “Personal Information” refers to information
                supplied by a User from which the identity of such User may be
                directly or indirectly determined.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                By registering for and using the Platform, you agree that your
                Personal Information will be handled as described in this Policy
                and the Terms and Conditions applicable to the Platform (the
                “Service Agreement”); capitalized terms used herein shall have
                the same meaning as set forth in the Service Agreement. This
                Privacy Policy is an integral part of{' '}
                <a href='https://docs.google.com/document/d/1g898YKsPRQ90WPHnsodOANKIGjWLUUpcJAGZ_WWFO84/edit'>
                    <u>SafeTravy Terms and Conditions</u>
                </a>
                .
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}></p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>What data do we collect?</b>
            </p>

            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                SafeTravy collects the following data:
            </p>

            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>

            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Personal identification</b> information (Name, email address,
                citizenship,etc.)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Contact</b>
                information (email address)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Financial</b>
                information (bank account details)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Transaction</b>
                information (details about payments to and from you and other
                details of products and services you have purchased from us)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Technical</b>
                information (internet protocol (IP) address, login data, browser
                type and version, time zone setting and location, operating
                system and platform and other technology on the devices you use
                to access our websites, products and services)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Profile</b>
                information (username and password, subscription purchases made
                by you, your interests, preferences, feedback and survey
                responses)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Usage</b>
                information (includes how you use our website, products and
                services)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Marketing and Communications</b> information (your
                preferences in receiving marketing from us and your
                communication preferences)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Aggregated</b>
                information (statistical or demographic data. Aggregated data
                could be derived from your personal data but is not considered
                personal data in law as this data will not directly or
                indirectly reveal your identity. For example, we MAY aggregate
                your Usage Data to calculate the percentage of users accessing a
                specific website feature. However, if we combine or connect
                Aggregated Data with your personal data so that it can directly
                or indirectly identify you, we treat the combined data as
                personal data which will be used in accordance with this privacy
                policy)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>We do not collect any Special Categories of Personal Data</b>{' '}
                about you (this includes details about your race or ethnicity,
                religious or philosophical beliefs, sex life, sexual
                orientation, political opinions, trade union membership,
                information about your health, and genetic and biometric data).
                Nor do we collect any information about criminal convictions and
                offenses.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>How do we collect your data?</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                You directly provide SafeTravy with most of the data we collect.
                We collect data and process data when you:
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <ul>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Register online or place an order for any of our
                        subscription plans.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Voluntarily complete a customer survey or provide
                        feedback on any of our message boards or via email.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Use or view our website via your browser’s cookies.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Subscribe to a service, publication, newsletter or
                        request marketing be sent to you.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Contact us directly via our website, email, telephone or
                        other means of communication.
                    </p>
                </li>
            </ul>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                SafeTravy will also receive your data indirectly from the
                following sources:
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <ul>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Automated technologies or interactions: As you interact
                        with our website, products and services, we will
                        automatically collect Technical Data about your
                        equipment, browsing actions and patterns. We collect
                        this personal data by using cookies, server logs and
                        other similar technologies.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Technical data from:
                    </p>
                </li>
            </ul>
            <ul>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        analytics providers such as Google based outside of the
                        European Union (EU).
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        hosting and other service providers such as Amazon based
                        outside of the EU.
                    </p>
                </li>
            </ul>
            <ul>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Contact, Financial and Transaction Data from providers
                        of technical, payment and delivery services.
                    </p>
                </li>
            </ul>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>How We Use Your Information</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                We use your information, including your Personal Information,
                for the following purposes:
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <ul>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        To provide our Platform to you, to facilitate
                        communications and transactions on the Platform, to
                        communicate with you about your use of our Platform, to
                        respond to your inquiries, to fulfill your orders, and
                        for other customer service purposes;
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        To tailor the content and information that we may send
                        or display to you, to offer location customization, and
                        personalized help and instructions, and to otherwise
                        personalize your experiences while using our Platform;
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        To better understand how users access and use the
                        Platform, both on an aggregated and individualized
                        basis, in order to improve our Platform and respond to
                        user desires and preferences, and for other research and
                        analytical purposes;
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        For marketing and promotional purposes. For example, we
                        may use your information, such as your email address or
                        contact number registered with us, to send you news and
                        newsletters, special offers, and promotions, to conduct
                        sweepstakes and contests, or to otherwise contact you
                        about products or information we think may interest you.
                        We also may use the information that we learn about you
                        to assist us in advertising our Platform on third-party
                        websites.
                    </p>
                </li>
            </ul>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>How We Share Your Information</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <ul>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Affiliates. We may disclose the information we collect
                        from you to our affiliates or subsidiaries solely for
                        the purpose of providing the Platform or related
                        services including subsite services to you; however, if
                        we do so, their use and disclosure of your personally
                        identifiable information will be maintained by such
                        affiliates and subsidiaries in accordance with this
                        Policy.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Service Providers. We may disclose the information we
                        collect from you to third-party vendors, service
                        providers, contractors or agents who perform functions
                        on our behalf, provided such third parties have agreed
                        to only use such information to provide services to us.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Business Transfers. If we are in negotiations with, or
                        are acquired by or merged with another company or
                        entity, if substantially all of our assets are
                        transferred to another company or entity, or as part of
                        a bankruptcy proceeding, we may transfer the information
                        we have collected from you to the other company or
                        entity.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        In Response to Legal Process. We also may disclose the
                        information we collect from you in order to comply with
                        the law, a judicial proceeding, court order, or other
                        legal process, such as in response to a subpoena.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        To Protect Us and Others. We also may disclose the
                        information we collect from you if we believe it is
                        necessary to investigate, prevent, or take action
                        regarding illegal activities, suspected fraud,
                        situations involving potential threats to the safety of
                        any person, violations of our Service Agreement or this
                        Policy, or as evidence in litigation in which we are
                        involved.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Aggregate and De-Identified Information. We may collect,
                        process, analyze and share aggregate or de-identified
                        information about users with third parties and publicly
                        for product development, marketing, advertising,
                        research or similar purposes.
                    </p>
                </li>
            </ul>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Rights of access, correction, and deletion</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                You can access, edit, update, or delete your Account or Personal
                Information we have collected at any time by accessing your
                account settings or emailing us at{' '}
                <span style={{ fontSize: '11pt' }}>safetravy@gmail.com</span>.
                We will respond to your requests within a reasonable period of
                time, but no later than the time period required by law.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Please note that notwithstanding the foregoing, there may be
                circumstances in which we are unable to accommodate a request to
                edit, update, access, or delete an account profile or Personal
                Information. This includes but is not limited to:
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                ·any basis where such request can be denied under applicable
                law;
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                ·where we need to retain the information to comply with federal,
                state, or local laws or for accounting or tax purposes;
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                ·where we need to comply with a civil, criminal, or regulatory
                inquiry, investigation, subpoena, or summons by federal, state,
                or local authorities;
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                ·where we need to cooperate with law enforcement agencies
                concerning conduct or activity that the business, service
                provider, or third party reasonably and in good faith believes
                may violate federal, state, or local law;
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                ·where we need to retain information to exercise or defend legal
                claims;
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                ·where the information contains legal privilege or proprietary
                information of another party; or ·where complying with the
                request would compromise others’ privacy or other legitimate
                rights.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                If we determine that we cannot respond to any request in any
                particular instance, we will provide you with an explanation of
                why that determination has been made and a contact point for any
                further inquiries. To protect your privacy, we will take
                commercially reasonable steps to verify your identity before
                responding to any request under this provision, including
                complying with any applicable legal requirement for verifying
                your identity.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Transfer of Information</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Please note, we may store your Personal Information on servers
                located in any third country. If such jurisdiction is outside
                your jurisdiction of residence, you consent to the transfer of
                your Personal Information to such jurisdiction for purposes of
                providing the Platform to you, even if such other jurisdiction
                has less protections for Personal Information than your
                jurisdiction of residence. We will ensure that security
                provisions are in place consistent with our obligations to
                maintain the security of your Personal Information under the
                laws of your jurisdiction of residence.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Retention</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                We retain your Personal Information as long as you maintain an
                Account on the Platform. We will cease to retain your Personal
                Information, or remove the means by which the Personal
                Information can be associated with particular individuals, as
                soon as it is reasonable to assume that —
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                (a) the purpose for which that Personal Information was
                collected is no longer being served by its retention; and
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                (b) retention is no longer necessary for legal, accounting or
                business purposes.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Please note that certain laws may require us to retain records
                of transactions or accounts for a certain period of time.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Third-Party Links</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Our Platform may contain links to third-party websites. Any
                access to and use of such linked websites is not governed by
                this Policy, but instead is governed by the privacy policies of
                those third-party websites. We are not responsible for the
                information practices of such third-party websites.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Children Under 18</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Our Platform is not designed for children under 18. If we
                discover that a child under 18 has provided us with Personal
                Information, we will delete such information from our systems.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Changes to our privacy policy</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                This Policy is current as of the Effective Date set forth above.
                We may change this Policy from time to time, so please be sure
                to check back periodically. We will post any changes to this
                Policy on the Platform. If we make any changes to this Policy
                that materially affect our practices with regard to the Personal
                Information we have previously collected from you, we will
                endeavor to provide you with notice in advance of such change by
                highlighting the change on our Platform or providing a push
                notification through the Site (you should make sure your Site
                settings allow for such push notifications) or sending an email
                that you have provided in your Account, (for this reason you
                should make sure to update your account information promptly if
                it changes).
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>How to contact us</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                If you have any questions about SafeTravy’s privacy policy, the
                data we hold on you or you would like to exercise one of your
                data protection rights, please do not hesitate to contact us
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <span style={{ fontSize: '11pt' }}>via email </span>{' '}
                <a href='mailto:safetravy@gmail.com'>
                    <span color='#1155cc' style={{ fontSize: '11pt' }}>
                        <u>support@safetravy.com</u>
                    </span>
                </a>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
        </>
    )
}
