export const PolicyFr = () => {
    return (
        <>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                La présente politique de confidentialité (la " Politique ")
                s'applique à la plateforme de trading (y compris les
                applications mobiles et les sites web utilisés pour y accéder)
                (collectivement la " Plateforme ") fournie par SafeTravy Limited
                (la " Société ", " nous ", " notre " ou " nos "). Elle décrit la
                manière dont la Société recueille, utilise et divulgue les
                informations personnelles qu'elle obtient des utilisateurs de la
                Plateforme et de tout service de compte fourni par
                l'intermédiaire de la Plateforme, ainsi que la manière dont elle
                utilise et divulgue ces informations. Aux fins de la présente
                politique, on entend par "informations personnelles" les
                informations fournies par un utilisateur à partir desquelles
                l'identité de cet utilisateur peut être directement ou
                indirectement déterminée.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                En vous inscrivant sur la plate-forme et en l'utilisant, vous
                acceptez que vos informations personnelles soient traitées comme
                décrit dans la présente politique et dans les conditions
                générales applicables à la plate-forme (le "contrat de service")
                ; les termes en majuscules utilisés dans le présent document ont
                la même signification que celle qui leur est donnée dans le
                contrat de service. La présente politique de confidentialité
                fait partie intégrante des
                <a href='https://docs.google.com/document/d/1g898YKsPRQ90WPHnsodOANKIGjWLUUpcJAGZ_WWFO84/edit'></a>
                <a href='https://docs.google.com/document/d/1g898YKsPRQ90WPHnsodOANKIGjWLUUpcJAGZ_WWFO84/edit'>
                    <span color='#1155cc'>
                        <u>conditions générales de SafeTravy</u>
                    </span>
                </a>
                .
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Quelles sont les données collectées ?</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                SafeTravy collecte les données suivantes:
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Informations d'<b>identification personnelle </b>(nom, adresse
                électronique, nationalité, etc.)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Informations de <b>contact </b>(adresse électronique)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Informations
                <b>financières </b>(coordonnées bancaires)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Informations sur les <b>transactions </b>(détails sur les
                paiements effectués en votre faveur ou par vous et autres
                détails sur les produits et services que vous avez achetés
                auprès de nous)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Informations
                <b>techniques </b>(adresse IP, données de connexion, type et
                version du navigateur, réglage du fuseau horaire et
                localisation, système d'exploitation et plateforme et autres
                technologies sur les appareils que vous utilisez pour accéder à
                nos sites web, produits et services).
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Informations sur <b>le profil </b>(nom d'utilisateur et mot de
                passe, achats d'abonnements effectués par vous, vos intérêts,
                vos préférences, vos commentaires et vos réponses à des
                enquêtes)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Informations relatives à l<b>'utilisation </b>(notamment la
                manière dont vous utilisez notre site web, nos produits et nos
                services)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Informations relatives au{' '}
                <b>marketing et à la communication </b>(vos préférences en
                matière de marketing et de communication)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Informations
                <b>agrégées </b>(données statistiques ou démographiques). Les
                données agrégées peuvent être dérivées de vos données
                personnelles mais ne sont pas considérées comme des données
                personnelles au sens de la loi car ces données ne révèlent pas
                directement ou indirectement votre identité. Par exemple, nous
                POUVONS agréger vos données d'utilisation pour calculer le
                pourcentage d'utilisateurs accédant à une fonction spécifique du
                site web. Toutefois, si nous combinons ou connectons les données
                agrégées avec vos données personnelles de manière à ce qu'elles
                puissent directement ou indirectement vous identifier, nous
                traitons les données combinées comme des données personnelles
                qui seront utilisées conformément à la présente politique de
                confidentialité.)
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                <b>
                    Nous ne recueillons aucune catégorie particulière de données
                    à caractère personnel{' '}
                </b>
                vous concernant (notamment des informations sur votre race ou
                votre appartenance ethnique, vos croyances religieuses ou
                philosophiques, votre vie sexuelle, votre orientation sexuelle,
                vos opinions politiques, votre appartenance à un syndicat, des
                informations sur votre santé, ainsi que des données génétiques
                et biométriques). Nous ne recueillons pas non plus
                d'informations sur les condamnations pénales et les infractions.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}></p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Comment recueillons-nous vos données ?</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Vous fournissez directement à SafeTravy la plupart des données
                que nous collectons. Nous collectons et traitons des données
                lorsque vous:
            </p>
            <ul>
                <li>
                    <p
                        style={{
                            marginTop: '0.17in',
                            marginBottom: '0in',
                            lineHeight: '125%',
                        }}>
                        Inscrivez-vous en ligne ou commandez l'une de nos
                        formules d'abonnement.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Répondre volontairement à une enquête auprès des clients
                        ou fournir un retour d'information sur l'un de nos
                        tableaux d'affichage ou par courrier électronique.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Utiliser ou consulter notre site web via les cookies de
                        votre navigateur.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        S'abonner à un service, à une publication, à une lettre
                        d'information ou demander à ce que des actions de
                        marketing vous soient envoyées.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0.17in', lineHeight: '125%' }}>
                        Nous contacter directement via notre site web, par
                        courriel, par téléphone ou par d'autres moyens de
                        communication.
                    </p>
                </li>
            </ul>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                SafeTravy recevra également vos données indirectement des
                sources suivantes:
            </p>
            <ul>
                <li>
                    <p
                        style={{
                            marginTop: '0.17in',
                            marginBottom: '0in',
                            lineHeight: '125%',
                        }}>
                        Technologies ou interactions automatisées : Lorsque vous
                        interagissez avec notre site web, nos produits et nos
                        services, nous recueillons automatiquement des données
                        techniques sur votre équipement, vos actions et vos
                        habitudes de navigation. Nous collectons ces données
                        personnelles en utilisant des cookies, des journaux de
                        serveur et d'autres technologies similaires.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Données techniques de :
                    </p>
                    <ul>
                        <li>
                            <p
                                style={{
                                    marginBottom: '0in',
                                    lineHeight: '125%',
                                }}>
                                des fournisseurs de services d'analyse, tels que
                                Google, basés en dehors de l'Union européenne
                                (UE).
                            </p>
                        </li>
                        <li>
                            <p
                                style={{
                                    marginBottom: '0in',
                                    lineHeight: '125%',
                                }}>
                                l'hébergement et d'autres fournisseurs de
                                services tels qu'Amazon basés en dehors de l'UE.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p style={{ marginBottom: '0.17in', lineHeight: '125%' }}>
                        Données de contact, financières et de transaction
                        provenant de fournisseurs de services techniques, de
                        paiement et de livraison.
                    </p>
                </li>
            </ul>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Comment nous utilisons vos informations</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Nous utilisons vos informations, y compris vos données
                personnelles, aux fins suivantes :
            </p>
            <ul>
                <li>
                    <p
                        style={{
                            marginTop: '0.17in',
                            marginBottom: '0in',
                            lineHeight: '125%',
                        }}>
                        Pour vous fournir notre plate-forme, pour faciliter les
                        communications et les transactions sur la plate-forme,
                        pour communiquer avec vous au sujet de votre utilisation
                        de notre plate-forme, pour répondre à vos demandes, pour
                        exécuter vos commandes et à d'autres fins de service à
                        la clientèle ;
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Pour adapter le contenu et les informations que nous
                        pouvons vous envoyer ou afficher, pour offrir une
                        personnalisation de l'emplacement, une aide et des
                        instructions personnalisées, et pour personnaliser vos
                        expériences lors de l'utilisation de notre Plateforme ;
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Mieux comprendre comment les utilisateurs accèdent à la
                        plate-forme et l'utilisent, à la fois sur une base
                        agrégée et individualisée, afin d'améliorer notre
                        plate-forme et de répondre aux souhaits et préférences
                        des utilisateurs, ainsi qu'à d'autres fins de recherche
                        et d'analyse ;
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0.17in', lineHeight: '125%' }}>
                        À des fins de marketing et de promotion. Par exemple,
                        nous pouvons utiliser vos informations, telles que votre
                        adresse électronique ou le numéro de contact que vous
                        avez enregistré chez nous, pour vous envoyer des
                        nouvelles et des bulletins d'information, des offres
                        spéciales et des promotions, pour organiser des tirages
                        au sort et des concours, ou pour vous contacter à propos
                        de produits ou d'informations que nous pensons
                        susceptibles de vous intéresser. Nous pouvons également
                        utiliser les informations que nous obtenons à votre
                        sujet pour nous aider à faire de la publicité pour notre
                        plate-forme sur des sites web de tiers.
                    </p>
                </li>
            </ul>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                <b>Comment nous partageons vos informations</b>
            </p>
            <ul>
                <li>
                    <p
                        style={{
                            marginTop: '0.17in',
                            marginBottom: '0in',
                            lineHeight: '125%',
                        }}>
                        Sociétés affiliées. Nous pouvons divulguer les
                        informations que nous recueillons auprès de vous à nos
                        sociétés affiliées ou filiales dans le seul but de vous
                        fournir la plate-forme ou des services connexes, y
                        compris des services de sous-site ; toutefois, si nous
                        le faisons, l'utilisation et la divulgation de vos
                        informations personnellement identifiables seront
                        maintenues par ces sociétés affiliées et filiales
                        conformément à la présente politique.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Prestataires de services. Nous pouvons divulguer les
                        informations que nous recueillons auprès de vous à des
                        fournisseurs, prestataires de services, sous-traitants
                        ou agents tiers qui exercent des fonctions en notre nom,
                        à condition que ces tiers aient accepté de n'utiliser
                        ces informations que pour nous fournir des services.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Transferts d'activités. Si nous sommes en négociation
                        avec une autre société ou entité, si nous sommes acquis
                        par une autre société ou entité ou si nous fusionnons
                        avec elle, si la quasi-totalité de nos actifs sont
                        transférés à une autre société ou entité, ou dans le
                        cadre d'une procédure de faillite, nous pouvons
                        transférer les informations que nous avons collectées
                        auprès de vous à l'autre société ou entité.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        En réponse à une procédure judiciaire. Nous pouvons
                        également divulguer les informations que nous
                        recueillons auprès de vous afin de nous conformer à la
                        loi, à une procédure judiciaire, à une ordonnance d'un
                        tribunal ou à toute autre procédure légale, par exemple
                        en réponse à une citation à comparaître.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Pour nous protéger et protéger les autres. Nous pouvons
                        également divulguer les informations que nous
                        recueillons auprès de vous si nous estimons qu'il est
                        nécessaire d'enquêter, de prévenir ou de prendre des
                        mesures concernant des activités illégales, des soupçons
                        de fraude, des situations impliquant des menaces
                        potentielles pour la sécurité de toute personne, des
                        violations de notre accord de service ou de la présente
                        politique, ou comme preuve dans le cadre d'un litige
                        dans lequel nous sommes impliqués.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0.17in', lineHeight: '125%' }}>
                        Informations globales et dépersonnalisées. Nous pouvons
                        collecter, traiter, analyser et partager des
                        informations globales ou dépersonnalisées sur les
                        utilisateurs avec des tiers et publiquement pour le
                        développement de produits, le marketing, la publicité,
                        la recherche ou d'autres objectifs similaires.
                    </p>
                </li>
            </ul>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Droits d'accès, de correction et de suppression</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Vous pouvez accéder, modifier, mettre à jour ou supprimer votre
                compte ou les informations personnelles que nous avons
                collectées à tout moment en accédant aux paramètres de votre
                compte ou en nous envoyant un courrier électronique à l'adresse
                safetravy@gmail.com. Nous répondrons à vos demandes dans un
                délai raisonnable, mais au plus tard dans le délai requis par la
                loi.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                Veuillez noter que, nonobstant ce qui précède, il peut y avoir
                des circonstances dans lesquelles nous ne sommes pas en mesure
                d'accéder à une demande de modification, de mise à jour, d'accès
                ou de suppression d'un profil de compte ou d'informations
                personnelles. Ceci inclut, mais n'est pas limité à :
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                ·tout motif pour lequel une telle demande peut être refusée en
                vertu du droit applicable ;
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                ·lorsque nous devons conserver les informations pour nous
                conformer aux lois fédérales, nationales ou locales ou à des
                fins comptables ou fiscales;
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                ·lorsque nous devons nous conformer à une enquête civile,
                criminelle ou réglementaire, à une citation à comparaître ou à
                une assignation par les autorités fédérales, étatiques ou
                locales ;
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                ·lorsque nous devons coopérer avec des organismes chargés de
                l'application de la loi concernant une conduite ou une activité
                dont l'entreprise, le fournisseur de services ou un tiers estime
                raisonnablement et en toute bonne foi qu'elle peut enfreindre la
                législation fédérale, nationale ou locale;
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                ·lorsque nous devons conserver des informations pour exercer ou
                défendre des droits légaux ;
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                ·lorsque les informations contiennent un privilège légal ou des
                informations exclusives d'une autre partie ; ou - lorsque le
                respect de la demande compromettrait la vie privée ou d'autres
                droits légitimes d'autrui.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                Si nous décidons que nous ne pouvons pas répondre à une demande
                dans un cas particulier, nous vous expliquerons les raisons de
                cette décision et vous indiquerons un point de contact pour
                toute autre question. Afin de protéger votre vie privée, nous
                prendrons des mesures commercialement raisonnables pour vérifier
                votre identité avant de répondre à toute demande en vertu de
                cette disposition, y compris en nous conformant à toute exigence
                légale applicable en matière de vérification de votre identité.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                <b>Transfert d'informations</b>
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                Veuillez noter que nous pouvons stocker vos informations
                personnelles sur des serveurs situés dans les pays où se
                trouvent nos bureaux principaux. Si cette juridiction est située
                en dehors de votre juridiction de résidence, vous consentez au
                transfert de vos informations personnelles vers cette
                juridiction afin de vous fournir la plate-forme, même si cette
                autre juridiction dispose de moins de protections pour les
                informations personnelles que votre juridiction de résidence.
                Nous veillerons à ce que des dispositions de sécurité soient en
                place conformément à nos obligations de maintenir la sécurité de
                vos informations personnelles en vertu des lois de votre
                juridiction de résidence.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Rétention</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Nous conservons vos informations personnelles aussi longtemps
                que vous avez un compte sur la plate-forme. Nous cesserons de
                conserver vos informations personnelles, ou supprimerons les
                moyens par lesquels les informations personnelles peuvent être
                associées à des individus particuliers, dès qu'il est
                raisonnable de supposer que les informations personnelles ne
                sont plus associées à des individus particuliers.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                (a) la finalité pour laquelle ces informations personnelles ont
                été collectées n'est plus servie par leur conservation ; et
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                (b) la conservation n'est plus nécessaire à des fins juridiques,
                comptables ou commerciales.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Veuillez noter que certaines lois peuvent nous obliger à
                conserver des enregistrements de transactions ou de comptes
                pendant une certaine période.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Liens avec des tiers</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Notre Plateforme peut contenir des liens vers des sites web de
                tiers. L'accès à ces sites web et leur utilisation ne sont pas
                régis par la présente politique, mais par les politiques de
                confidentialité de ces sites web tiers. Nous ne sommes pas
                responsables des pratiques d'information de ces sites web tiers.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Enfants de moins de 18 ans</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Notre plateforme n'est pas conçue pour les enfants de moins de
                18 ans. Si nous découvrons qu'un enfant de moins de 18 ans nous
                a fourni des informations personnelles, nous supprimerons ces
                informations de nos systèmes.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Modifications de notre politique de confidentialité</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                La politique est en vigueur à la date d'entrée en vigueur
                indiquée ci-dessus. Nous pouvons modifier la politique de temps
                à autre, veuillez donc la consulter régulièrement. Nous
                publierons toute modification de la présente politique sur la
                plate-forme. Si nous apportons des modifications à la présente
                Politique qui affectent matériellement nos pratiques en ce qui
                concerne les Informations personnelles que nous avons
                précédemment collectées auprès de vous, nous nous efforcerons de
                vous avertir à l'avance de ces modifications en les mettant en
                évidence sur notre Plateforme ou en fournissant une notification
                push via le Site (vous devez vous assurer que les paramètres de
                votre Site autorisent de telles notifications push) ou en
                envoyant un e-mail que vous avez fourni dans votre Compte (pour
                cette raison, vous devez vous assurer de mettre à jour
                rapidement les informations de votre compte si elles changent).
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Comment nous contacter</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Si vous avez des questions concernant la politique de
                confidentialité de SafeTravy, les données que nous détenons sur
                vous ou si vous souhaitez exercer l'un de vos droits en matière
                de protection des données, n'hésitez pas à nous contacter: par
                courriel support@safetravy.com.
            </p>
        </>
    )
}
