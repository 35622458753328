import { Modal } from 'antd'
import styles from './styles.module.css'
import { Icon } from '@/core/components'
import { useTranslation } from 'react-i18next'

interface IProps {
    isOpen: boolean
    close: () => void
}

export const ModalQuestionsSuccess = ({ isOpen, close }: IProps) => {
    const { t } = useTranslation()
    return (
        <Modal
            styles={{ body: { width: '100%' } }}
            closeIcon={null}
            footer={null}
            classNames={{ content: styles.container }}
            open={isOpen}>
            <Icon
                onClick={close}
                name='x'
                size={24}
                color='#CED3E1'
                style={{ position: 'absolute', top: 20, right: 20 }}
            />
            <div className={styles.content_modal}>
                <div>
                    <p className={styles.title}>{t('thank')}</p>
                    <p className={styles.sub_text}>{t('thanYouMessage')}</p>
                </div>
            </div>
        </Modal>
    )
}
