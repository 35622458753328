const errorText = {
    color: '#FC9494',
    'line-height': '14px',
    'font-size': '12px',
    'margin-top': '5px',
    '.triangle': {
        display: 'none',
    },
}

const labelField = {
    color: '#97999A',
    'font-weight': '400',
    'line-height': '15.6px',
}

const field = {
    'border-color': '#c8d6e5',
    color: '#222f3e',
    ':focus': {
        'border-color': '#0D8F5B',
    },
}

export const styleCheckount = {
    submit_button: {
        'background-color': '#0D8F5B',
        'border-radius': '48px',
        'font-size': '16px',
        'font-weight': '500',
        ':hover': {
            'background-color': '#0D8F5B',
        },
        form_body: {
            'font-family': 'Helvetica Neue',
        },
        '.title': {
            'text-transform': 'none !Important',
        },
        '.title-icon': {
            display: 'none',
        },
    },

    form_title: {
        display: 'flex',
        width: '100%',
        'justify-content': 'center',
        'font-weight': '500',
        'font-size': '28px',
        'line-height': '30px',
        color: '#3D4251',
    },

    card_number: {
        '.error-text': {
            ...errorText,
        },
        input: {
            ...field,
        },
        '.label': {
            'font-size': '17px',
            ...labelField,
        },
    },
    card_cvv: {
        '.error-text': {
            ...errorText,
        },
        input: {
            ...field,
        },
        '.label': {
            'font-size': '17px',
            ...labelField,
        },
    },
    expiry_date: {
        '.error-text': {
            ...errorText,
        },
        input: {
            ...field,
        },
        '.label': {
            'font-size': '17px',
            ...labelField,
        },
    },
    zip_code: {
        '.error-text': {
            ...errorText,
        },
        input: {
            ...field,
        },
        '.label': {
            'font-size': '17px',
            ...labelField,
        },
    },
    card_holder: {
        '.error-text': {
            ...errorText,
        },
        input: {
            ...field,
        },
        '.label': {
            ...labelField,
        },
    },
    email: {
        '.input-block': {
            'margin-top': '15px',
        },

        '.error-text': {
            ...errorText,
        },
        input: {
            ...field,
        },
        '.label': {
            'font-size': '17px',
            color: '#97999A',
        },
    },
}
