import { GoogleTagsEvent } from '../events'

export const sendEvent = <T extends keyof GoogleTagsEvent>(
    eventName: T,
    params: GoogleTagsEvent[T],
) => {
    if (window.gtag) {
        window.gtag('event', eventName, params)
    } else {
        return
    }
}
