import { AccountForm } from '../../components/account-form'
import _ from 'lodash'
import { useAccount } from '../../hooks'
import { BannerInsurance } from '@/modules/banners'
import styles from './style.module.css'

export const AccountWidget = () => {
    const { getAccount, account } = useAccount()
    const missingEmail = account && !account?.email
    return (
        <div className={styles.container}>
            {account?.promocodes && <BannerInsurance />}

            <AccountForm
                reloadAccount={getAccount}
                avatarUrl={account?.avatarUrl}
                data={account}
                editEmail={missingEmail}
            />
        </div>
    )
}
