export const PolicyDe = () => {
    return (
        <>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                Diese Datenschutzrichtlinie (diese "Richtlinie") gilt für die
                von SafeTravy Limited (das "Unternehmen", "wir", "uns" oder
                "unser") zur Verfügung gestellte Handelsplattform
                (einschließlich etwaiger mobiler Anwendungen und Websites, die
                für den Zugriff auf dieselbe verwendet werden) (zusammen die
                "Plattform"). Sie beschreibt, wie das Unternehmen
                personenbezogene Daten, die wir von Nutzern der Plattform und
                von über die Plattform bereitgestellten Kontodiensten erhalten,
                sammelt, verwendet und offenlegt und wie wir diese Daten
                verwenden und offenlegen. Für die Zwecke dieser Richtlinie
                bezieht sich der Begriff "personenbezogene Daten" auf
                Informationen, die von einem Nutzer bereitgestellt werden und
                aus denen die Identität dieses Nutzers direkt oder indirekt
                ermittelt werden kann.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                Durch die Registrierung für die Plattform und deren Nutzung
                erklären Sie sich damit einverstanden, dass Ihre persönlichen
                Daten wie in dieser Richtlinie und den für die Plattform
                geltenden Geschäftsbedingungen (der "Dienstleistungsvertrag")
                beschrieben behandelt werden; hier verwendete Begriffe in
                Großbuchstaben haben dieselbe Bedeutung wie in dem
                Dienstleistungsvertrag. Diese Datenschutzrichtlinie ist ein
                integraler Bestandteil der
                <a href='https://docs.google.com/document/d/1g898YKsPRQ90WPHnsodOANKIGjWLUUpcJAGZ_WWFO84/edit'></a>
                <a href='https://docs.google.com/document/d/1g898YKsPRQ90WPHnsodOANKIGjWLUUpcJAGZ_WWFO84/edit'>
                    <span color='#1155cc'>
                        <u>Allgemeinen Geschäftsbedingungen von SafeTravy</u>
                    </span>
                </a>
                .
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Welche Daten sammeln wir?</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                SafeTravy sammelt die folgenden Daten:
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Persönliche</b>Identifikationsdaten (Name, E-Mail-Adresse,
                Staatsangehörigkeit usw.)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Kontaktinformationen (E-Mail Adresse)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Finanzielle</b>Informationen (Kontodaten)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Transaktionsinformationen (Details über Zahlungen an und von
                Ihnen und andere Details über Produkte und Dienstleistungen, die
                Sie bei uns gekauft haben)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Technische</b>Informationen (Internetprotokoll (IP)-Adresse,
                Anmeldedaten, Browsertyp und -version, Zeitzoneneinstellung und
                Standort, Betriebssystem und Plattform sowie andere Technologien
                auf den Geräten, die Sie für den Zugriff auf unsere Websites,
                Produkte und Dienstleistungen verwenden)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Profilinformationen (Benutzername und Passwort, von Ihnen
                gekaufte Abonnements, Ihre Interessen, Vorlieben, Feedback und
                Umfrageantworten)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Nutzungsdaten (z. B. wie Sie unsere Website, Produkte und
                Dienstleistungen nutzen)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Marketing- und </b>Kommunikationsinformationen (Ihre
                Präferenzen für den Erhalt von Marketingmaterial von uns und
                Ihre Kommunikationspräferenzen)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Aggregierte</b>Informationen (statistische oder demografische
                Daten). Zusammengefasste Daten können aus Ihren
                personenbezogenen Daten abgeleitet werden, gelten aber rechtlich
                nicht als personenbezogene Daten, da diese Daten weder direkt
                noch indirekt Ihre Identität preisgeben. Wir KÖNNEN zum Beispiel
                Ihre Nutzungsdaten zusammenfassen, um den Prozentsatz der Nutzer
                zu berechnen, die auf eine bestimmte Funktion der Website
                zugreifen. Wenn wir jedoch zusammengefasste Daten mit Ihren
                personenbezogenen Daten kombinieren oder verbinden, so dass sie
                Sie direkt oder indirekt identifizieren können, behandeln wir
                die kombinierten Daten als personenbezogene Daten, die in
                Übereinstimmung mit dieser Datenschutzrichtlinie verwendet
                werden)
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>
                    Wir erheben keine besonderen Kategorien personenbezogener
                    Daten{' '}
                </b>
                über Sie (dazu gehören Angaben zu Ihrer Ethnie, Ihren religiösen
                oder philosophischen Überzeugungen, Ihrem Sexualleben, Ihrer
                sexuellen Orientierung, Ihren politischen Ansichten, Ihrer
                Mitgliedschaft in einer Gewerkschaft, Informationen über Ihre
                Gesundheit sowie genetische und biometrische Daten). Auch
                sammeln wir keine Informationen über strafrechtliche
                Verurteilungen und Straftaten.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                <b>Wie sammeln wir Ihre Daten?</b>
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                Die meisten der von uns erfassten Daten werden SafeTravy direkt
                von Ihnen zur Verfügung gestellt. Wir sammeln Daten und
                verarbeiten Daten, wenn Sie:
            </p>
            <ul>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Registrieren Sie sich online oder bestellen Sie eines
                        unserer Abonnements.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Freiwillig eine Kundenumfrage ausfüllen oder auf einem
                        unserer Messageboards oder per E-Mail Feedback geben.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Sie können unsere Website über die Cookies Ihres
                        Browsers nutzen oder ansehen.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Abonnieren Sie einen Dienst, eine Veröffentlichung,
                        einen Newsletter oder fordern Sie die Zusendung von
                        Marketingmaterial an.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0.17in', lineHeight: '125%' }}>
                        Kontaktieren Sie uns direkt über unsere Website, per
                        E-Mail, Telefon oder andere Kommunikationsmittel.
                    </p>
                </li>
            </ul>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                SafeTravy erhält Ihre Daten auch indirekt aus den folgenden
                Quellen:
            </p>
            <ul>
                <li>
                    <p
                        style={{
                            marginTop: '0.17in',
                            marginBottom: '0in',
                            lineHeight: '125%',
                        }}>
                        Automatisierte Technologien oder Interaktionen: Wenn Sie
                        mit unserer Website, unseren Produkten und Diensten
                        interagieren, sammeln wir automatisch technische Daten
                        über Ihre Geräte, Browsing-Aktionen und Muster. Wir
                        erfassen diese personenbezogenen Daten mithilfe von
                        Cookies, Serverprotokollen und anderen ähnlichen
                        Technologien.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Technische Daten von:
                    </p>
                    <ul>
                        <li>
                            <p
                                style={{
                                    marginBottom: '0in',
                                    lineHeight: '125%',
                                }}>
                                Analytik-Anbieter wie Google mit Sitz außerhalb
                                der Europäischen Union (EU).
                            </p>
                        </li>
                        <li>
                            <p
                                style={{
                                    marginBottom: '0in',
                                    lineHeight: '125%',
                                }}>
                                Hosting- und andere Dienstleistungsanbieter wie
                                Amazon mit Sitz außerhalb der EU.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p style={{ marginBottom: '0.17in', lineHeight: '125%' }}>
                        Kontakt-, Finanz- und Transaktionsdaten von Anbietern
                        von technischen, Zahlungs- und Lieferdiensten.
                    </p>
                </li>
            </ul>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Wie wir Ihre Informationen verwenden</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Wir verwenden Ihre Daten, einschließlich Ihrer persönlichen
                Daten, für die folgenden Zwecke:
            </p>
            <ul>
                <li>
                    <p
                        style={{
                            marginTop: '0.17in',
                            marginBottom: '0in',
                            lineHeight: '125%',
                        }}>
                        Um Ihnen unsere Plattform zur Verfügung zu stellen, um
                        die Kommunikation und Transaktionen auf der Plattform zu
                        erleichtern, um mit Ihnen über Ihre Nutzung unserer
                        Plattform zu kommunizieren, um Ihre Anfragen zu
                        beantworten, um Ihre Bestellungen auszuführen und für
                        andere Kundendienstzwecke;
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Um die Inhalte und Informationen, die wir an Sie senden
                        oder anzeigen, auf Sie zuzuschneiden, um eine
                        individuelle Standortanpassung und personalisierte Hilfe
                        und Anweisungen anzubieten und um Ihre Erfahrungen bei
                        der Nutzung unserer Plattform anderweitig zu
                        personalisieren;
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Um besser zu verstehen, wie Nutzer auf die Plattform
                        zugreifen und sie nutzen, sowohl auf aggregierter als
                        auch auf individueller Basis, um unsere Plattform zu
                        verbessern und auf die Wünsche und Vorlieben der Nutzer
                        zu reagieren, sowie für andere Forschungs- und
                        Analysezwecke;
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0.17in', lineHeight: '125%' }}>
                        Für Marketing- und Werbezwecke. Wir können Ihre Daten,
                        wie z. B. Ihre E-Mail-Adresse oder Ihre bei uns
                        registrierte Kontaktnummer, verwenden, um Ihnen
                        Nachrichten und Newsletter, Sonderangebote und
                        Werbeaktionen zuzusenden, Verlosungen und Wettbewerbe
                        durchzuführen oder Sie anderweitig über Produkte oder
                        Informationen zu informieren, die Sie interessieren
                        könnten. Wir können die Informationen, die wir über Sie
                        erfahren, auch dazu verwenden, um unsere Plattform auf
                        Websites Dritter zu bewerben.
                    </p>
                </li>
            </ul>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                <b>Wie wir Ihre Informationen weitergeben</b>
            </p>
            <ul>
                <li>
                    <p
                        style={{
                            marginTop: '0.17in',
                            marginBottom: '0in',
                            lineHeight: '125%',
                        }}>
                        Verbundene Unternehmen. Wir können die Informationen,
                        die wir von Ihnen erfassen, an unsere verbundenen
                        Unternehmen oder Tochtergesellschaften weitergeben, und
                        zwar ausschließlich zu dem Zweck, Ihnen die Plattform
                        oder damit verbundene Dienste, einschließlich
                        Untersite-Dienste, zur Verfügung zu stellen; wenn wir
                        dies jedoch tun, wird die Nutzung und Weitergabe Ihrer
                        personenbezogenen Daten durch diese verbundenen
                        Unternehmen und Tochtergesellschaften in Übereinstimmung
                        mit dieser Richtlinie erfolgen.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Dienstanbieter. Wir können die von Ihnen gesammelten
                        Informationen an Drittanbieter, Dienstleister,
                        Auftragnehmer oder Vertreter weitergeben, die in unserem
                        Auftrag Funktionen ausführen, vorausgesetzt, diese
                        Dritten haben zugestimmt, diese Informationen nur zur
                        Erbringung von Dienstleistungen für uns zu verwenden.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Geschäftsübertragungen. Wenn wir in Verhandlungen mit
                        einem anderen Unternehmen oder einer anderen
                        Körperschaft stehen, von einem anderen Unternehmen oder
                        einer anderen Körperschaft übernommen werden oder mit
                        einem anderen Unternehmen oder einer anderen
                        Körperschaft fusionieren, wenn im Wesentlichen alle
                        unsere Vermögenswerte auf ein anderes Unternehmen oder
                        eine andere Körperschaft übertragen werden oder als Teil
                        eines Konkursverfahrens, können wir die von Ihnen
                        erfassten Daten an das andere Unternehmen oder die
                        andere Körperschaft übertragen.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        In Reaktion auf rechtliche Verfahren. Wir können die von
                        Ihnen erfassten Informationen auch offenlegen, um dem
                        Gesetz, einem Gerichtsverfahren, einem Gerichtsbeschluss
                        oder einem anderen rechtlichen Verfahren nachzukommen,
                        z. B. als Antwort auf eine Vorladung.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                        Um uns und andere zu schützen. Wir können die von Ihnen
                        erfassten Informationen auch offenlegen, wenn wir
                        glauben, dass dies notwendig ist, um illegale
                        Aktivitäten, mutmaßlichen Betrug, Situationen, die eine
                        potenzielle Bedrohung für die Sicherheit von Personen
                        darstellen, Verstöße gegen unsere
                        Dienstleistungsvereinbarung oder diese Richtlinie zu
                        untersuchen, zu verhindern oder Maßnahmen zu ergreifen,
                        oder als Beweismittel in Rechtsstreitigkeiten, an denen
                        wir beteiligt sind.
                    </p>
                </li>
                <li>
                    <p style={{ marginBottom: '0.17in', lineHeight: '125%' }}>
                        Zusammengefasste und de-identifizierte Informationen.
                        Wir können zusammengefasste oder nicht identifizierte
                        Informationen über Nutzer sammeln, verarbeiten,
                        analysieren und mit Dritten und der Öffentlichkeit zu
                        Zwecken der Produktentwicklung, des Marketings, der
                        Werbung, der Forschung oder ähnlichen Zwecken teilen.
                    </p>
                </li>
            </ul>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}></p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Recht auf Zugang, Berichtigung und Löschung</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Sie können Ihr Konto oder die von uns erfassten persönlichen
                Daten jederzeit einsehen, bearbeiten, aktualisieren oder
                löschen, indem Sie auf Ihre Kontoeinstellungen zugreifen oder
                uns eine E-Mail an safetravy@gmail.com senden. Wir werden Ihre
                Anfragen innerhalb eines angemessenen Zeitraums beantworten,
                spätestens jedoch innerhalb des gesetzlich vorgeschriebenen
                Zeitraums.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                Bitte beachten Sie, dass wir unter bestimmten Umständen nicht in
                der Lage sind, einer Anfrage zur Bearbeitung, Aktualisierung,
                zum Zugriff oder zur Löschung eines Kontoprofils oder
                persönlicher Daten nachzukommen. Dies schließt ein, ist aber
                nicht beschränkt auf:
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                ·jede Grundlage, auf der ein solcher Antrag nach geltendem Recht
                abgelehnt werden kann;
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                ·Wenn wir die Informationen aufbewahren müssen, um Bundes-,
                Landes- oder lokale Gesetze einzuhalten oder für Buchhaltungs-
                oder Steuerzwecke;
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                ·wenn wir einer zivil-, strafrechtlichen oder behördlichen
                Anfrage, Untersuchung, Vorladung oder Vorladung durch Bundes-,
                Landes- oder Kommunalbehörden nachkommen müssen;
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                ·wenn wir mit den Strafverfolgungsbehörden zusammenarbeiten
                müssen, weil das Unternehmen, der Dienstleister oder ein Dritter
                in gutem Glauben davon ausgeht, dass die Daten gegen Bundes-,
                Landes- oder lokale Gesetze verstoßen;
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                ·wenn wir Informationen aufbewahren müssen, um rechtliche
                Ansprüche geltend zu machen oder zu verteidigen;
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                ·wenn die Informationen ein gesetzliches Privileg oder
                geschützte Informationen einer anderen Partei enthalten; oder -
                wenn die Befolgung des Ersuchens die Privatsphäre oder andere
                legitime Rechte anderer beeinträchtigen würde.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}>
                Wenn wir feststellen, dass wir eine Anfrage in einem bestimmten
                Fall nicht beantworten können, teilen wir Ihnen die Gründe für
                diese Entscheidung mit und nennen Ihnen eine Kontaktstelle für
                eventuelle Rückfragen. Um Ihre Privatsphäre zu schützen, werden
                wir wirtschaftlich angemessene Schritte unternehmen, um Ihre
                Identität zu überprüfen, bevor wir auf eine Anfrage gemäß dieser
                Bestimmung antworten, einschließlich der Einhaltung aller
                anwendbaren gesetzlichen Anforderungen zur Überprüfung Ihrer
                Identität.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Übermittlung von Informationen</b>
            </p>
            <p style={{ marginBottom: '0.14in', lineHeight: '125%' }}>
                Bitte beachten Sie, dass wir Ihre persönlichen Daten auf Servern
                in einem beliebigen Drittland speichern können. Wenn diese
                Gerichtsbarkeit außerhalb Ihres Wohnsitzes liegt, stimmen Sie
                der Übertragung Ihrer personenbezogenen Daten in diese
                Gerichtsbarkeit zu, um Ihnen die Plattform zur Verfügung stellen
                zu können, auch wenn diese andere Gerichtsbarkeit weniger Schutz
                für personenbezogene Daten bietet als Ihre
                Wohnsitzgerichtsbarkeit. Wir stellen sicher, dass
                Sicherheitsvorkehrungen getroffen werden, die mit unseren
                Verpflichtungen zur Wahrung der Sicherheit Ihrer
                personenbezogenen Daten gemäß den Gesetzen Ihres Wohnsitzes
                übereinstimmen.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '115%' }}>
                <b>Vorratsspeicherung</b>
            </p>
            <p style={{ marginBottom: '0.14in', lineHeight: '115%' }}>
                Wir bewahren Ihre personenbezogenen Daten so lange auf, wie Sie
                ein Konto auf der Plattform unterhalten. Wir hören auf, Ihre
                personenbezogenen Daten aufzubewahren, oder entfernen die
                Mittel, mit denen die personenbezogenen Daten mit bestimmten
                Personen in Verbindung gebracht werden können, sobald es
                vernünftig ist, anzunehmen, dass -
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '115%' }}>
                (a) der Zweck, für den die personenbezogenen Daten erhoben
                wurden, durch die Aufbewahrung nicht mehr erfüllt wird; und
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '115%' }}>
                (b) die Aufbewahrung aus rechtlichen, buchhalterischen oder
                geschäftlichen Gründen nicht mehr erforderlich ist.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Bitte beachten Sie, dass wir aufgrund bestimmter Gesetze
                verpflichtet sein können, Aufzeichnungen über Transaktionen oder
                Konten für einen bestimmten Zeitraum aufzubewahren.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Links von Drittanbietern</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Unsere Plattform kann Links zu Websites Dritter enthalten. Der
                Zugriff auf und die Nutzung von solchen verlinkten Websites
                unterliegt nicht dieser Richtlinie, sondern den
                Datenschutzrichtlinien dieser Websites Dritter. Wir sind nicht
                verantwortlich für die Informationspraktiken solcher Websites
                Dritter.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Kinder unter 18 Jahren</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Unsere Plattform ist nicht für Kinder unter 18 Jahren bestimmt.
                Wenn wir feststellen, dass ein Kind unter 18 Jahren uns
                personenbezogene Daten zur Verfügung gestellt hat, werden wir
                diese Daten aus unseren Systemen löschen.
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                <b>Änderungen an unserer Datenschutzpolitik</b>
            </p>
            <p style={{ marginBottom: '0in', lineHeight: '125%' }}>
                Diese Richtlinie ist ab dem oben angegebenen Datum des
                Inkrafttretens gültig. Wir können diese Richtlinie von Zeit zu
                Zeit ändern, daher sollten Sie sie regelmäßig überprüfen. Wir
                werden alle Änderungen an dieser Richtlinie auf der Plattform
                veröffentlichen. Wenn wir Änderungen an dieser Richtlinie
                vornehmen, die sich wesentlich auf unsere Praktiken in Bezug auf
                die personenbezogenen Daten auswirken, die wir zuvor von Ihnen
                erfasst haben, werden wir uns bemühen, Sie im Voraus über eine
                solche Änderung zu informieren, indem wir die Änderung auf
                unserer Plattform hervorheben oder eine Push-Benachrichtigung
                über die Website bereitstellen (Sie sollten sicherstellen, dass
                Ihre Website-Einstellungen solche Push-Benachrichtigungen
                zulassen) oder eine E-Mail senden, die Sie in Ihrem Konto
                angegeben haben (aus diesem Grund sollten Sie sicherstellen,
                dass Sie Ihre Kontoinformationen umgehend aktualisieren, wenn
                sie sich ändern).
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                <b>Wie Sie uns kontaktieren können</b>
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0in',
                    lineHeight: '125%',
                }}>
                Wenn Sie Fragen zur Datenschutzpolitik von SafeTravy haben, zu
                den Daten, die wir über Sie speichern, oder wenn Sie eines Ihrer
                Datenschutzrechte ausüben möchten, zögern Sie bitte nicht, uns
                zu kontaktieren: per E-Mail safetravy@gmail.com.
            </p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}></p>
            <p
                style={{
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                    lineHeight: '125%',
                }}></p>
        </>
    )
}
