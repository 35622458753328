export const TermsGerman = () => {
    return (
        <>
            <p style={{ marginBottom: '0in' }}>
                <b>Bedingungen und Konditionen von SafeTravy</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Diese Allgemeinen Geschäftsbedingungen (die "Bedingungen" oder
                diese "Vereinbarung") regeln die Nutzung der elektronischen
                Plattform, einschließlich jeglicher Website oder mobiler
                Anwendung (die "App", zusammen mit der Website die "Website")
                für den Zugriff auf die Plattform und jegliche über die
                Plattform bereitgestellten Dienste (zusammen die "Plattform"),
                die von SafeTravy Limited (das "Unternehmen", "wir", "uns" oder
                "unser") bereitgestellt werden. Die Bedingungen bilden eine
                verbindliche Vereinbarung zwischen dem Unternehmen und Ihnen als
                individuellem Nutzer ("Sie", "Ihr" oder "Nutzer") für Ihre
                individuelle Nutzung der App und der Plattform. Indem Sie sich
                für die App registrieren und diese herunterladen und die
                Plattform nutzen, bestätigen Sie, dass Sie diese Vereinbarung
                und unsere zugehörige Datenschutzrichtlinie akzeptieren. Wenn
                Sie mit diesen Bedingungen nicht einverstanden sind, müssen Sie
                die App sofort deinstallieren und die Nutzung der App und der
                Plattform einstellen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Haftungsausschluss für Wertpapiere: Kein Material und keine
                anderen Informationen, die auf der Website oder der Plattform
                zur Verfügung gestellt werden, stellen eine Empfehlung,
                Befürwortung, ein Angebot, eine Einladung oder eine Aufforderung
                an Sie dar, eine Entscheidung zu treffen; alle Entscheidungen
                treffen Sie aus eigenem Willen und eigener Erfahrung. Sie nehmen
                ferner zur Kenntnis, dass keiner der Informationsanbieter,
                einschließlich der Drittanbieter (wie unten definiert), Sie
                persönlich über die Art, das Potenzial, den Wert oder die
                Eignung bestimmter Sicherheitsfragen in Bezug auf Ihre Reisen
                berät. Bitte beachten Sie, dass sich die Situation in der Welt
                schnell ändert, so dass alle Informationen, Inhalte, Inhalte von
                Drittanbietern (wie unten definiert) oder andere Materialien,
                die auf oder über die Website oder Plattform bereitgestellt
                werden, möglicherweise nicht vollständig oder aktuell sind oder
                durch aktuellere Informationen ersetzt werden können. Sie
                verlassen sich auf diese Informationen auf Ihr eigenes Risiko.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Unsere Website und Plattform sind nicht dazu gedacht, Rechts-,
                Versicherungs- oder Anlageberatung zu leisten, und nichts auf
                der Website oder Plattform sollte als Angebot an Sie zur
                Organisation Ihrer Reise ausgelegt werden. Alle diese
                Informationen sind lediglich personalisierte Empfehlungen.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>1. Anwendbarkeit</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                1.1. Wir, SafeTravy Limited (im Folgenden "SafeTravy"), bieten
                Dienstleistungen im Bereich der IT-Unterhaltung an Diese
                allgemeinen Geschäftsbedingungen gelten für das
                Vertragsverhältnis zwischen SafeTravy und dem Kunden ("Kunde"
                oder "Sie") in Bezug auf alle von uns erbrachten
                Dienstleistungen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>2. Begriffsbestimmungen</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Konto</b>
                bezeichnet das von einem Nutzer eingerichtete Konto, der die App
                heruntergeladen oder auf die Website zugegriffen hat und sich
                bei dem Unternehmen zur Nutzung der Website und der Plattform
                registriert hat.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>App</b>
                bezeichnet die vom Unternehmen bereitgestellte mobile Anwendung
                für den Zugriff auf die Plattform.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Autorisierte Person </b>
                bezeichnet jede Person, die berechtigt ist, im Namen eines
                Nutzers auf die Website (einschließlich der App) und die
                Plattform zuzugreifen und diese zu nutzen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Digitale Plattformen </b>
                beziehen sich auf Vertriebsplattformen von Drittanbietern, über
                die mobile Anwendungen oder andere Softwareprogramme abgerufen
                oder heruntergeladen werden können, einschließlich, aber nicht
                beschränkt auf den Apple App Store und Google Play.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Regierungsbehörde</b>
                bedeutet eine Nation oder Regierung oder eine Provinz oder ein
                Bundesland oder eine andere politische Untereinheit davon oder
                eine Einrichtung, Behörde oder ein Gremium, das Exekutiv-,
                Legislativ-, Gerichts-, Regulierungs- oder Verwaltungsfunktionen
                der Regierung ausübt oder mit ihr in Verbindung steht,
                einschließlich einer Regierungsbehörde, einer Agentur, einer
                Abteilung, eines Gremiums, einer Kommission oder eines
                Instruments oder einer politischen Untereinheit davon, eines
                Gerichts, eines Tribunals oder eines Schiedsrichters und einer
                Selbstregulierungsorganisation.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Material</b>
                ist jegliches Angebotsmaterial, Term Sheet, Forschungsbericht,
                Produkt- oder Dienstleistungsdokumentation oder jede andere über
                die Plattform bereitgestellte Information.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Personenbezogene Daten </b>
                sind Informationen, die von einem Nutzer zur Verfügung gestellt
                werden und aus denen die Identität des Nutzers direkt oder
                indirekt abgeleitet werden kann.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Datenschutzrichtlinie</b>
                bezeichnet die zusätzlichen Bedingungen, die die Sammlung,
                Verwendung und Offenlegung der persönlichen Daten jedes Nutzers
                regeln, wie hier dargelegt. Jeder Nutzer muss die
                Datenschutzrichtlinie lesen und ihr zustimmen, um die App oder
                die Website nutzen zu können.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Service-Benachrichtigungen</b>
                sind Einweg-Benachrichtigungen des Unternehmens (die auch
                sicherheitsrelevante Benachrichtigungen enthalten können) per
                Textnachricht oder E-Mail und ggf. Push-Benachrichtigungen über
                die Website. Diese Benachrichtigungen werden dem Nutzer in Bezug
                auf bestimmte Informationen oder Ereignisse im Zusammenhang mit
                einem Konto, auf das der Nutzer über die Plattform Zugriff hat,
                zugesandt.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Nutzer</b>
                bezeichnet jede Person, die sich beim Unternehmen registriert
                hat, um die Website zu nutzen und auf die Plattform zuzugreifen,
                sowie jede autorisierte Person, die in deren Namen handelt.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Benutzer-Credentials</b>
                sind die Benutzeridentifikation, das Passwort, die persönliche
                Identifikationsnummer, das Token und alle anderen Informationen
                oder Geräte, die einem Benutzer für den Zugriff auf die
                Plattform zur Verfügung gestellt werden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Das Unternehmen </b>
                (in diesem Vertrag entweder als "das Unternehmen", "wir", "uns"
                oder "unser" bezeichnet) bezieht sich auf SAFETRAVY LIMITED,
                Suite 4, 2nd floor, the West Wing, Montarik House, 3 Bedlam
                Court, Gibraltar, GX11 1AA, GIBRALTAR, GIBRALTAR.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Gerät</b>
                ist jedes Gerät, das auf den Dienst zugreifen kann, wie z. B.
                ein Computer, ein Mobiltelefon oder ein digitales Tablet.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Eine kostenlose Testversion </b>
                bezieht sich auf einen begrenzten Zeitraum, der beim Kauf eines
                Abonnements kostenlos sein kann.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Der</b>
                Begriff "<b>Service"</b>
                bezieht sich auf alle Informationen, Empfehlungen und
                Benachrichtigungen, die auf der Website bereitgestellt werden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Abonnements</b>
                beziehen sich auf die Dienste oder den Zugang zum Dienst, die
                Ihnen vom Unternehmen auf Abonnementbasis angeboten werden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Allgemeine Geschäftsbedingungen </b>
                (auch als "Bedingungen" bezeichnet) bezeichnen diese Allgemeinen
                Geschäftsbedingungen, die die gesamte Vereinbarung zwischen
                Ihnen und dem Unternehmen bezüglich der Nutzung des Dienstes
                bilden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Dienste
                <b>von Dritten </b>
                sind alle Dienste oder Inhalte (einschließlich Daten,
                Informationen, Produkte oder Dienste), die von Dritten
                bereitgestellt werden und die durch den Dienst angezeigt,
                einbezogen oder verfügbar gemacht werden können.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Die Website </b>
                verweist auf SafeTravy, zugänglich über{' '}
                <a href='http://www.safetravy.com/'>
                    <u>www.safetravy.com</u>
                </a>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Änderungen</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Wir behalten uns das Recht vor, jederzeit:
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                (a) die Bedingungen dieser Vereinbarung oder unserer
                Datenschutzrichtlinie zu modifizieren, zu aktualisieren oder zu
                ändern;{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                (b) die Website und die Plattform zu modifizieren, zu
                aktualisieren oder zu ändern, einschließlich der Beseitigung
                oder Einstellung von Inhalten oder Funktionen der Website oder
                der Plattform (alle vorgenannten Punkte werden als "Änderungen"
                bezeichnet).
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Wir können solche Änderungen jederzeit ohne vorherige
                Ankündigung vornehmen. Änderungen an dieser Vereinbarung können
                auf unserer Website veröffentlicht oder Ihnen durch
                Push-Benachrichtigungen auf der Website oder per E-Mail an die
                E-Mail-Adresse in Ihrem Konto mitgeteilt werden. Aus diesem
                Grund sollten Sie unsere Website regelmäßig überprüfen, den
                Empfang solcher Push-Benachrichtigungen auf der Website zulassen
                und Ihre E-Mail-Adresse und andere Kontaktinformationen in Ihrem
                Konto auf dem neuesten Stand halten. Sie akzeptieren alle
                Änderungen, wenn Sie die Website und die Plattform nach dem
                Inkrafttreten dieser Änderungen weiterhin nutzen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Begriffe der digitalen Plattform</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Die App kann zum Herunterladen von einer oder mehreren digitalen
                Plattformen verfügbar sein. Ihr Download, Ihre Installation, Ihr
                Zugriff auf oder Ihre Nutzung der App ist auch an die
                Bedingungen und Datenschutzrichtlinien der jeweiligen digitalen
                Plattform (die "Bedingungen der digitalen Plattform") gebunden.
                Im Falle eines Widerspruchs zwischen diesen Bedingungen und den
                Bedingungen der digitalen Plattform haben diese Bedingungen
                Vorrang.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Die App ist unabhängig von einer Digitalen Plattform und ist
                nicht mit dieser verbunden, angegliedert, gesponsert, gebilligt
                oder in irgendeiner Weise mit ihr verknüpft. Sie und wir
                erkennen an, dass diese Vereinbarung nur zwischen Ihnen und uns
                und nicht mit einer Digitalen Plattform geschlossen wird, und
                dass wir, nicht die Digitale Plattform, allein für die App und
                deren Inhalt in dem in dieser Vereinbarung angegebenen Umfang
                verantwortlich sind.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie und wir erkennen an und erklären uns damit einverstanden,
                dass die betreffende Digitale Plattform und die
                Tochtergesellschaften der Digitalen Plattform Drittbegünstigte
                dieser Bedingungen sind und dass die Digitale Plattform nach
                Ihrer Zustimmung zu diesen Bedingungen das Recht hat (und es
                wird davon ausgegangen, dass sie das Recht akzeptiert hat),
                diese Bedingungen gegen Sie als Drittbegünstigte durchzusetzen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Anforderungen an Netzwerkgeräte und Netzbetreiber</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie erkennen an, dass Ihre Vereinbarung mit Ihrem Mobilfunk- und
                Internetanbieter (der "Netzanbieter") für Ihre Nutzung der
                Website gilt. Sie erkennen an, dass Ihnen von Ihrem
                Netzbetreiber bei der Nutzung bestimmter Funktionen der Website
                Datendienste oder andere Gebühren von Dritten in Rechnung
                gestellt werden können, und Sie übernehmen die alleinige
                Verantwortung für diese Gebühren. Wenn Sie nicht der
                Rechnungszahler für das Mobil-/Internetgerät sind, mit dem Sie
                auf die Website zugreifen, wird davon ausgegangen, dass Sie die
                Erlaubnis des Rechnungszahlers zur Nutzung der Website erhalten
                haben. Sie müssen auch sicherstellen, dass Ihre Nutzung der
                Website nicht gegen den Vertrag Ihres Mobil- oder Internetgeräts
                oder einen Vertrag über einen drahtlosen Datendienst verstößt.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Teilnahmeberechtigung und Registrierung</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie müssen mindestens 18 Jahre alt sein, um auf die Website und
                die Plattform zuzugreifen und diese zu nutzen. Sie bestätigen
                außerdem, dass Sie voll und ganz in der Lage und fähig sind, die
                Bedingungen, Verpflichtungen, Bestätigungen, Zusicherungen und
                Garantien, die in diesen Bedingungen festgelegt sind, einzugehen
                und diese Bedingungen einzuhalten. Sie müssen sich bei dem
                Unternehmen registrieren, um die Website und die Plattform
                nutzen zu können; Sie verpflichten sich, bei der Registrierung
                zur Nutzung der Website und der Plattform vollständige und
                genaue Angaben zu machen und diese Angaben auf dem neuesten
                Stand zu halten.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Es liegt in unserem alleinigen Ermessen, Ihre Registrierung auf
                der Plattform zu akzeptieren oder abzulehnen. Nur Nutzer, deren
                Registrierungen von uns genehmigt werden, sind unsere Kunden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Danksagung</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Dies sind die Allgemeinen Geschäftsbedingungen, die die Nutzung
                dieses Dienstes regeln und die Vereinbarung zwischen Ihnen und
                dem Unternehmen. Diese Allgemeinen Geschäftsbedingungen legen
                die Rechte und Pflichten aller Nutzer in Bezug auf die Nutzung
                des Dienstes fest.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Ihr Zugang zu und Ihre Nutzung des Dienstes setzt voraus, dass
                Sie diese Allgemeinen Geschäftsbedingungen akzeptieren und
                einhalten. Diese Allgemeinen Geschäftsbedingungen gelten für
                alle Besucher, Nutzer und andere, die auf den Dienst zugreifen
                oder ihn nutzen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Indem Sie auf den Dienst zugreifen oder ihn nutzen, erklären Sie
                sich mit diesen Allgemeinen Geschäftsbedingungen einverstanden.
                Wenn Sie mit einem Teil dieser Bedingungen nicht einverstanden
                sind, dürfen Sie den Dienst nicht nutzen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie versichern, dass Sie über 18 Jahre alt sind. Das Unternehmen
                erlaubt es Personen unter 18 Jahren nicht, den Service zu
                nutzen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Ihr Zugang zu und Ihre Nutzung des Dienstes ist auch davon
                abhängig, dass Sie die Datenschutzrichtlinie des Unternehmens
                akzeptieren und einhalten. Unsere Datenschutzrichtlinie
                beschreibt unsere Richtlinien und Verfahren für die Erfassung,
                Verwendung und Offenlegung Ihrer persönlichen Daten, wenn Sie
                die Anwendung oder die Website nutzen, und informiert Sie über
                Ihre Datenschutzrechte und darüber, wie das Gesetz Sie schützt.
                Bitte lesen Sie unsere Datenschutzrichtlinie sorgfältig durch,
                bevor Sie unseren Service nutzen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Abonnements</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Dauer des Abonnements</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                Der Dienst oder einige Teile des Dienstes sind nur mit einem
                bezahlten Abonnement verfügbar. Die Abrechnung erfolgt im Voraus
                auf wiederkehrender und regelmäßiger Basis (z. B. täglich,
                wöchentlich, monatlich oder jährlich), je nach der Art des
                Abonnementplans, den Sie beim Kauf des Abonnements auswählen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Die Parteien (Unternehmen und Nutzer) sind sich darüber einig,
                dass der Preis des Abonnements keine Steuern enthält
                (Mehrwertsteuer oder andere im Wohnsitzland des Nutzers zu
                zahlende Steuern). Der Nutzer erklärt sich damit einverstanden,
                dass er in den gesetzlich vorgesehenen Fällen zusätzlich zur
                Zahlung des Abonnementpreises den Betrag der im Zusammenhang mit
                dem Kauf des Abonnements erhobenen Steuern entrichtet.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Am Ende eines jeden Zeitraums verlängert sich Ihr Abonnement
                automatisch zu denselben Bedingungen, es sei denn, Sie kündigen
                es oder das Unternehmen kündigt es.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Aktualisierung des Abonnements</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                Wenn der Nutzer eine Art von bezahltem Abonnement nutzt und
                dieses auf eine andere Art von Abonnement aufrüstet (das Volumen
                des Dienstes oder die Nutzungsdauer des Dienstes erhöht),
                vereinbaren die Parteien hiermit und der Nutzer und das
                Unternehmen vereinbaren im Voraus, dass der Nutzer für jeden
                Monat eines solchen Abonnements, unabhängig vom Zeitpunkt einer
                solchen Aufrüstung, zusätzlich 0,99 (neunundneunzig) Eurocent
                ohne Steuern (Steuern werden zusätzlich berechnet und bezahlt)
                zahlt.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                Ein Beispiel: Der Nutzer hat ein bezahltes Abonnement zum
                Basistarif für einen Zeitraum von einem Monat, und nach fünf
                Tagen der Nutzung dieses Abonnements aktualisiert der Nutzer
                dieses Abonnement auf das Extra-Abonnement; in diesem Fall
                erhält der Nutzer gegen Zahlung von 0,99 (neunundneunzig)
                Eurocent ohne Steuern (Steuern werden zusätzlich berechnet und
                gezahlt) Zugang zu allen Diensten des Extra-Abonnements für den
                Zeitraum bis zum Ende des vorausbezahlten Basis-Abonnements.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                Wenn der Nutzer ein Upgrade des für 3 Monate bezahlten
                Basis-Abonnements vornimmt, zahlt der Nutzer dem Unternehmen für
                ein solches Upgrade zusätzlich 2,97 (zwei Euro und
                siebenundneunzig Eurocent). Dasselbe gilt für das Upgrade von
                Abonnements, die für andere Laufzeiten bezahlt wurden.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                Die Parteien vereinbaren außerdem, dass der Nutzer, wenn er von
                einem Abonnement mit einem größeren Leistungsumfang zu einem
                Abonnement mit einem geringeren Leistungsumfang wechselt, den
                gesamten Leistungsumfang bis zum Ende der bezahlten Laufzeit des
                Abonnements mit dem größeren Leistungsumfang erhält, und dass
                das Unternehmen dem Nutzer in diesem Fall keine Rückerstattung
                oder Gutschrift gewährt.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Kündigungen von Abonnements</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie können die Verlängerung Ihres Abonnements entweder über die
                Seite mit den Kontoeinstellungen oder durch Kontaktaufnahme mit
                dem Unternehmen kündigen. Sie erhalten keine Rückerstattung für
                die Gebühren, die Sie bereits für Ihren aktuellen
                Abonnementzeitraum bezahlt haben, und Sie können bis zum Ende
                Ihres aktuellen Abonnementzeitraums auf den Dienst zugreifen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Rechnungsstellung</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie müssen dem Unternehmen korrekte und vollständige
                Rechnungsdaten zur Verfügung stellen, einschließlich
                vollständiger Name, Adresse, Bundesland, und gültige
                Zahlungsinformationen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sollte die automatische Rechnungsstellung aus irgendeinem Grund
                ausbleiben, stellt das Unternehmen eine elektronische Rechnung
                aus, in der Sie darauf hingewiesen werden, dass Sie innerhalb
                eines bestimmten Termins die Zahlung des vollen Betrags für den
                auf der Rechnung angegebenen Abrechnungszeitraum manuell
                vornehmen müssen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Gebührenänderungen</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                Das Unternehmen kann die Abonnementgebühren nach eigenem
                Ermessen und zu jeder Zeit ändern. Jede Änderung der
                Abonnementgebühren wird am Ende des aktuellen
                Abonnementzeitraums wirksam.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Das Unternehmen wird Sie mit angemessener Frist über Änderungen
                der Abonnementgebühren informieren, um Ihnen die Möglichkeit zu
                geben, Ihr Abonnement zu kündigen, bevor die Änderung in Kraft
                tritt.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Ihre fortgesetzte Nutzung des Dienstes nach Inkrafttreten der
                Änderung der Abonnementgebühr gilt als Ihr Einverständnis, den
                geänderten Betrag der Abonnementgebühr zu zahlen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Erstattungen</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                Die gezahlten Abonnementgebühren werden nicht zurückerstattet,
                es sei denn, dies ist gesetzlich vorgeschrieben.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Bestimmte Rückerstattungsanträge für Abonnements können vom
                Unternehmen von Fall zu Fall geprüft und nach alleinigem
                Ermessen des Unternehmens gewährt werden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Rückerstattungsklausel</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                Mit Ausnahme gesetzlich vorgeschriebener Fälle sind bezahlte
                Abonnementgebühren nicht erstattungsfähig.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Bestimmte Rückerstattungsanfragen für Abonnements können von der
                Gesellschaft von Fall zu Fall geprüft und nach alleinigem
                Ermessen der Gesellschaft gewährt werden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Kostenlose Testversion</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                Das Unternehmen kann nach eigenem Ermessen ein Abonnement mit
                einer kostenlosen Testversion für einen begrenzten Zeitraum
                anbieten.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie müssen möglicherweise Ihre Rechnungsdaten eingeben, um sich
                für die kostenlose Testversion anzumelden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Wenn Sie bei der Anmeldung für eine kostenlose Testversion Ihre
                Rechnungsdaten eingeben, werden Ihnen diese erst nach Ablauf der
                kostenlosen Testversion vom Unternehmen in Rechnung gestellt. Am
                letzten Tag des kostenlosen Testzeitraums werden Ihnen, sofern
                Sie Ihr Abonnement nicht kündigen, automatisch die
                entsprechenden Abonnementgebühren für die von Ihnen gewählte Art
                des Abonnements in Rechnung gestellt.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Das Unternehmen behält sich das Recht vor, jederzeit und ohne
                Vorankündigung (i) die Bedingungen des kostenlosen Testangebots
                zu ändern oder (ii) das kostenlose Testangebot zu stornieren.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Benutzerkonten</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Um die Dienste der Plattform nutzen zu können, müssen Sie ein
                Konto auf der Plattform erstellen (das "Konto"). Das Konto kann
                von jeder Person registriert werden, die über 18 Jahre alt ist.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                Wenn Sie ein Konto bei uns erstellen, müssen Sie uns
                Informationen zur Verfügung stellen, die jederzeit korrekt,
                vollständig und aktuell sind. Wenn Sie dies nicht tun, stellt
                dies einen Verstoß gegen die Bedingungen dar, der zur sofortigen
                Kündigung Ihres Kontos bei unserem Service führen kann.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie sind verantwortlich für den Schutz des Passworts, das Sie
                für den Zugriff auf den Dienst verwenden, sowie für alle
                Aktivitäten oder Handlungen unter Ihrem Passwort, unabhängig
                davon, ob Ihr Passwort für unseren Dienst oder einen Dienst
                eines Drittanbieters verwendet wird.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie verpflichten sich, Ihr Passwort nicht an Dritte
                weiterzugeben. Sie müssen uns sofort benachrichtigen, wenn Sie
                von einer Verletzung der Sicherheit oder einer unbefugten
                Nutzung Ihres Kontos erfahren. Sie können Ihr Konto nur von
                einem Gerät aus betreten.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie dürfen als Benutzernamen nicht den Namen einer anderen
                natürlichen oder juristischen Person oder einen Namen verwenden,
                der nicht rechtmäßig zur Verfügung steht, einen Namen oder eine
                Marke, der/die ohne entsprechende Genehmigung den Rechten einer
                anderen natürlichen oder juristischen Person als Ihnen
                unterliegt, oder einen Namen, der anderweitig beleidigend,
                vulgär oder obszön ist.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Jeder Benutzer erkennt an, dass jeder Satz von
                Benutzeranmeldeinformationen nicht übertragbar ist und nur von
                dem Benutzer verwendet werden darf, dem er ausgestellt wurde.
                Solche Benutzeranmeldeinformationen dürfen ohne schriftliche
                Genehmigung des Unternehmens nicht an Dritte weitergegeben
                werden. Wir werden Sie niemals, aus welchem Grund auch immer,
                per E-Mail, Post oder Telefon auffordern, Ihre Benutzerdaten
                preiszugeben. Passwortabfragen erfolgen nur online und nur,
                nachdem Sie sich auf der Plattform angemeldet haben. Wir werden
                Ihnen niemals eingebettete Links in einer E-Mail senden und Sie
                auffordern, sich durch Anklicken eines solchen Links auf der
                Plattform anzumelden. Wenn Sie einen eingebetteten Link per
                E-Mail erhalten, der vorgibt, von uns oder der Plattform zu
                stammen, dürfen Sie diesen Link nicht öffnen oder anklicken. Die
                E-Mail stammt nicht von uns und ist wahrscheinlich betrügerisch.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Autorisierte Personen</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Jeder Nutzer und jede autorisierte Person bestätigt, dass sie
                diese Bedingungen erhalten und akzeptiert haben. Mit der
                Beantragung eines Zugangs zur Plattform und eines Kontos für
                eine autorisierte Person erkennt der Nutzer an und sichert zu,
                dass die autorisierte Person ordnungsgemäß bevollmächtigt ist,
                (i) im Namen des Nutzers auf die Plattform zuzugreifen und diese
                zu nutzen und, falls zutreffend, die gleichen Befugnisse
                auszuüben, die der Nutzer der autorisierten Person gemäß einer
                zugrunde liegenden Vollmacht übertragen hat, soweit dies
                technisch möglich ist, und dass die auf der Plattform
                angebotenen Dienste den Diensten entsprechen, die der Nutzer
                über andere Kanäle in Anspruch nehmen kann; (ii) Änderungen
                dieser Bedingungen im Namen des Nutzers zu akzeptieren; und
                (iii) Dienstleistungen der Plattform zu beantragen oder zu
                abonnieren, die eine separate Beantragung oder ein Abonnement
                erfordern.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Jeder Nutzer hat dafür zu sorgen, dass jede autorisierte Person,
                die in seinem Namen handelt, über diese Bedingungen und
                gegebenenfalls die Bedingungen des Drittanbieters von
                Dienstleistungen informiert ist und diesen zustimmt und sie
                einhält. Sie haften in vollem Umfang für alle Handlungen,
                Unterlassungen oder Verstöße der von Ihnen benannten
                bevollmächtigten Personen beim Zugang und der Nutzung der
                Plattform und bei allen über Ihr Konto durchgeführten
                Transaktionen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie erklären, verpflichten sich und bestätigen, dass Sie die
                Zustimmung Ihrer autorisierten Personen zur Sammlung,
                Verwendung, Übertragung, Offenlegung und Verarbeitung der
                persönlichen Daten dieser autorisierten Personen in
                Übereinstimmung mit diesen Bedingungen und der
                Datenschutzrichtlinie eingeholt haben.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie nehmen zur Kenntnis und erklären sich damit einverstanden,
                dass die von der Plattform bereitgestellten Informationen und
                Dienste nicht für natürliche oder juristische Personen in
                Ländern bereitgestellt werden, in denen deren Bereitstellung
                oder Nutzung gegen geltendes Recht verstößt oder in denen wir
                nicht befugt sind, diese Plattform oder Informationen und
                Dienste bereitzustellen, und dass sie von diesen Personen oder
                Organisationen nicht genutzt werden dürfen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Geistiges Eigentum</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Alle Titel, Eigentumsrechte und geistigen Eigentumsrechte an
                oder in Bezug auf die Website und die Plattform, jegliche
                Informationen, die von, an oder über die Plattform übertragen
                werden, sowie Informationen bezüglich der Nutzung der Plattform
                verbleiben beim Unternehmen oder seinen Lizenzgebern. Nichts auf
                der Plattform ist so auszulegen, dass einem Nutzer eine Lizenz
                an den Eigentumsrechten, Eigentumsrechten und/oder geistigen
                Eigentumsrechten des Unternehmens oder eines Dritten übertragen
                wird, es sei denn, dies ist hier ausdrücklich festgelegt, sei es
                durch Rechtsverwirkung, stillschweigend oder anderweitig.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Die Plattform und die App können Ihnen Zugang zu Inhalten,
                Informationen, Zitaten, Videos, Fotos oder anderen Materialien
                (die "Inhalte Dritter") bieten, die von bestimmten Dritten (den
                "Anbietern von Inhalten Dritter") bereitgestellt werden. Das
                Unternehmen befürwortet oder empfiehlt nicht die Richtigkeit,
                Gültigkeit oder Vollständigkeit von Inhalten Dritter, die über
                die Website oder Plattform bereitgestellt werden, und ist nicht
                dafür verantwortlich, diese zu überprüfen. Die Nutzung solcher
                Inhalte von Drittanbietern oder Ihr Vertrauen in diese erfolgt
                auf Ihr eigenes Risiko. Alle Titel, Eigentumsrechte und
                geistigen Eigentumsrechte an oder in Bezug auf die Inhalte
                Dritter verbleiben bei dem jeweiligen Anbieter der Inhalte
                Dritter. Nichts auf der Plattform ist so auszulegen, dass einem
                Nutzer eine Lizenz für das Eigentum, die Eigentumsrechte
                und/oder die Rechte am geistigen Eigentum eines Drittanbieters
                von Inhalten übertragen wird, es sei denn, dies ist hier
                ausdrücklich vorgesehen, sei es durch Rechtsverwirkung,
                stillschweigend oder anderweitig.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Unter der Voraussetzung, dass Sie diese Bedingungen einhalten,
                können Sie die Website auf ein einziges mobiles Gerät
                herunterladen und darauf zugreifen und mit ordnungsgemäß
                ausgestellten Benutzerberechtigungsnachweisen auf die Plattform
                zugreifen. Alle anderen Rechte an der Website sind dem
                Unternehmen vorbehalten. Sollten Sie gegen diese Bedingungen
                verstoßen, sind wir berechtigt, Ihre Nutzung und Ihren Zugang
                zur Website und zur Plattform sofort zu beenden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie stimmen zu, dies nicht zu tun:
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                (a) die Website oder die Plattform oder Daten oder Inhalte
                (einschließlich der Inhalte Dritter), die über die Website oder
                die Plattform bereitgestellt werden, oder Teile davon zu
                verändern, anzupassen, zu reproduzieren, zu übersetzen oder
                davon abgeleitete Werke zu erstellen oder zu versuchen, den
                Quellcode der Website oder der Plattform zurückzuentwickeln, zu
                dekompilieren, zu disassemblieren oder anderweitig zu versuchen,
                ihn zu ermitteln;
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                (b) Urheberrechtsvermerke, Marken, Legenden, Logos oder
                Produktkennzeichnungen von der Website oder Plattform zu
                entfernen;
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                (c) die anderen Sites fälschlicherweise als die Site des
                Unternehmens auszugeben, indem sie das visuelle "Look and Feel"
                oder den Text der Site des Unternehmens übernehmen oder
                anderweitig die geistigen Eigentumsrechte des Unternehmens
                verletzen, einschließlich, aber nicht beschränkt auf das
                "Scraping" von Text oder Bildern von der Site des Unternehmens
                oder von dem Unternehmen verwalteten Bannern und/oder Textlinks,
                Suchmaschinenmarketing oder allen anderen Online- und
                Offline-Kampagnen,
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                (d) die Reihenfolge der auf den Websites des Unternehmens
                enthaltenen Informationen zu bearbeiten, zu modifizieren, zu
                filtern, zu kürzen oder zu ändern oder Teile der Website des
                Unternehmens ohne Genehmigung des Unternehmens zu entfernen,
                unkenntlich zu machen oder zu minimieren; oder
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                (e) die Website oder Plattform oder das Logo, die Marke oder den
                Markennamen des Unternehmens in irgendeiner Weise kommerziell zu
                nutzen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Jeder Nutzer ermächtigt das Unternehmen, alle Informationen oder
                Inhalte, die der Nutzer zur Verfügung stellt oder die im
                Zusammenhang mit der Nutzung der Website und der Plattform
                verarbeitet werden (z. B. persönliche Informationen,
                geografische Informationen, Geräteinformationen), im
                Zusammenhang und zum Zweck der Bereitstellung von
                Dienstleistungen oder Produkten auf der Plattform und der
                sicheren Nutzung der Website und der Plattform zu verwenden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Bereitstellung von Material und Informationen</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie erkennen an, dass weder das Unternehmen noch die Plattform
                Ihr Reiseberater oder Treuhänder ist. Sie erkennen ferner an,
                dass keines der von uns bereitgestellten oder auf der Plattform
                zur Verfügung gestellten Materialien eine Empfehlung oder
                Aufforderung an Sie darstellt, eine bestimmte Entscheidung
                während Ihrer Reise zu treffen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie erkennen an, dass wir nicht verpflichtet sind, die auf der
                Plattform angezeigten Materialien zu überprüfen, zu korrigieren,
                zu ergänzen oder zu aktualisieren. Die Materialien, insbesondere
                Nachrichten und Forschungsergebnisse, können von
                Informationsanbietern erstellt werden, die von uns unabhängig
                sind. Wir übernehmen keine Gewähr für die Richtigkeit,
                Vollständigkeit oder rechtzeitige Aktualisierung des Materials.
                Sie sollten weitere Recherchen und Analysen durchführen oder
                einen Reiseberater konsultieren, bevor Sie Entscheidungen
                treffen. Jegliche Nutzung des Materials oder das Vertrauen
                darauf erfolgt auf Ihr eigenes Risiko.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Service und andere Benachrichtigungen</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie erklären sich damit einverstanden, Benachrichtigungen über
                die App, die Plattform, Ihr Konto und die Bedingungen durch
                Service-Benachrichtigungen zu akzeptieren. Sie erklären sich
                damit einverstanden, dass solche Service-Benachrichtigungen eine
                wirksame Benachrichtigung anstelle von schriftlichen, per Post
                versandten oder anderen Formen der Benachrichtigung darstellen,
                die nach geltendem Recht erforderlich sind.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Es liegt in Ihrer alleinigen Verantwortung, das betreffende
                E-Mail-Konto ohne weitere Erinnerungen oder wiederholte
                Benachrichtigungen durch das Unternehmen zu überwachen. Sie
                müssen jede unbefugte Nutzung oder jeden unbefugten Zugriff auf
                die Plattform unverzüglich melden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie entbinden das Unternehmen von jeglicher Haftung für Verluste
                oder Schäden, die sich aus der Nutzung der
                Service-Benachrichtigungen ergeben, soweit dies gesetzlich
                zulässig ist. Das Unternehmen übernimmt keine Garantie oder
                Haftung dafür, dass die über die Service-Benachrichtigungen
                bereitgestellten Informationen aktuell, richtig oder vollständig
                sind.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Persönliche Informationen</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Im Rahmen der Plattform können personenbezogene Daten des
                Nutzers vom Unternehmen in Übereinstimmung mit der
                Datenschutzrichtlinie erfasst, verwendet, übertragen,
                offengelegt oder anderweitig verarbeitet werden. Sie sollten die
                Datenschutzrichtlinie sorgfältig lesen, bevor Sie sich auf der
                Website und der Plattform registrieren und diese nutzen. Sie
                stimmen der Erfassung, Verwendung und Offenlegung Ihrer
                persönlichen Daten in Übereinstimmung mit diesen Bedingungen und
                der Datenschutzrichtlinie zu, einschließlich, aber nicht
                beschränkt auf die Offenlegung gegenüber dem Drittanbieter von
                Dienstleistungen zum Zwecke der Bereitstellung von
                Dienstleistungen und der Durchführung von Transaktionen im
                Zusammenhang mit dem Konto.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie verpflichten sich, wahrheitsgemäße, genaue, aktuelle und
                vollständige persönliche Daten anzugeben. Sie verpflichten sich
                ferner, die persönlichen Daten zu pflegen und unverzüglich zu
                aktualisieren, damit sie während der Laufzeit dieser
                Vereinbarung jederzeit wahr, genau, aktuell und vollständig
                sind.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Sie müssen uns unverzüglich über alle Änderungen informieren,
                einschließlich, aber nicht beschränkt auf Änderungen der
                persönlichen Daten in Verbindung mit der Plattform. Wenn Sie
                unwahre, ungenaue, nicht aktuelle oder unvollständige Angaben
                machen oder wenn wir oder einer unserer bevollmächtigten
                Vertreter den begründeten Verdacht haben, dass diese Angaben
                unwahr, ungenau, nicht aktuell oder unvollständig sind, haben
                wir das Recht, das Konto zu sperren oder zu kündigen und Ihnen
                jegliche derzeitige oder künftige Nutzung der Plattform und der
                Website zu verweigern sowie Sie zivilrechtlich haftbar zu machen
                oder Sie an die zuständigen Strafverfolgungsbehörden zur
                strafrechtlichen Verfolgung zu verweisen. Wir sind nicht
                verpflichtet, nach einer solchen Aussetzung, Beendigung oder
                Unfähigkeit zur Nutzung der Plattform oder der Website eine
                Entschädigung zu leisten, weder in finanzieller noch in anderer
                Form.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Bitte beachten Sie, dass wir mithilfe von Tracking-Technologien
                Informationen über Ihr Gerät sammeln können, wie z. B.
                IP-Adresse, Netzbetreiber, Mobilfunkanbieter, Typ des mobilen
                Browsers, Zeitstempel, Zeitzone, Informationen über
                Geschwindigkeit, Peilung, Ausrichtung und Höhe eines Geräts oder
                andere geräteidentifizierende Informationen. Der Nutzer stimmt
                einer solchen Verwendung von Tracking-Technologien zu und
                erkennt an, dass die erhaltenen Informationen, einschließlich
                persönlicher Daten, mit öffentlichen oder privaten Informationen
                abgeglichen werden können, die dem Unternehmen oder einem
                Drittanbieter von Dienstleistungen zugänglich sind. Der Nutzer
                erklärt sich außerdem damit einverstanden, dass solche
                Informationen an die Dienstleister des Unternehmens und des
                Drittanbieters weitergegeben werden, um die
                Tracking-Technologien und die damit verbundenen Dienste
                bereitzustellen und zu pflegen. Wir können auch genaue
                Geolokalisierungsdaten von oder über Ihr Gerät sammeln, die
                durch Breiten- und Längenkoordinaten ausgedrückt werden können,
                die durch GPS-Tools, WiFi-Daten, Triangulation von
                Mobilfunkmasten oder andere Techniken erhalten werden. Unsere
                Verwendung solcher Informationen wird in unserer
                Datenschutzrichtlinie beschrieben.
            </p>
            <p
                style={{
                    lineHeight: '100%',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Cookies und wie wir sie verwenden</b>
            </p>
            <p
                style={{
                    lineHeight: '100%',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Cookies sind Textdateien, die auf Ihrem Computer platziert
                werden, um Standardinformationen zum Internetprotokoll und
                Informationen zum Verhalten der Besucher zu sammeln. Wenn Sie
                unsere Websites besuchen, können wir automatisch Informationen
                über Sie mithilfe von Cookies oder ähnlicher Technologie
                sammeln. SafeTravy verwendet Cookies in einer Vielzahl von
                Möglichkeiten, um Ihre Erfahrung auf unserer Website und in
                anderen digitalen Diensten zu verbessern.
            </p>
            <p
                style={{
                    lineHeight: '100%',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Es gibt verschiedene Arten von Cookies; jedoch verwendet unsere
                Website:
            </p>
            <ul>
                <li>
                    <p
                        style={{
                            lineHeight: '100%',
                            marginTop: '0.17in',
                            marginBottom: '0in',
                        }}>
                        Konto- und Anmeldeinformationen
                    </p>
                </li>
                <li>
                    <p style={{ lineHeight: '100%', marginBottom: '0in' }}>
                        E-Mail-Newsletter-Abonnements
                    </p>
                </li>
                <li>
                    <p style={{ lineHeight: '100%', marginBottom: '0in' }}>
                        Umfragen und Formulare
                    </p>
                </li>
                <li>
                    <p style={{ lineHeight: '100%', marginBottom: '0in' }}>
                        Website-Einstellungen
                    </p>
                </li>
                <li>
                    <p style={{ lineHeight: '100%', marginBottom: '0in' }}>
                        Funktionalität – SafeTravy verwendet diese Cookies, um
                        Sie auf unserer Website wiederzuerkennen und sich an
                        Ihre zuvor ausgewählten Einstellungen zu erinnern. Diese
                        können Ihre bevorzugte Sprache und Ihren Standort
                        umfassen. Eine Mischung aus Erstanbieter- und
                        Drittanbieter-Cookies wird verwendet.
                    </p>
                </li>
                <li>
                    <p style={{ lineHeight: '100%', marginBottom: '0.17in' }}>
                        Werbung – SafeTravy verwendet diese Cookies, um
                        Informationen über Ihren Besuch auf unserer Website, die
                        von Ihnen angesehenen Inhalte, die von Ihnen gefolgten
                        Links sowie Informationen zu Ihrem Browser, Gerät und
                        Ihrer IP-Adresse zu sammeln. SafeTravy teilt manchmal
                        einige begrenzte Aspekte dieser Daten mit Dritten zu
                        Werbezwecken. Wir können auch Online-Daten, die durch
                        Cookies gesammelt wurden, mit unseren Werbepartnern
                        teilen. Dies bedeutet, dass Sie, wenn Sie eine andere
                        Website besuchen, Werbung basierend auf Ihrem
                        Surfverhalten auf unserer Website angezeigt bekommen
                        können.
                    </p>
                </li>
            </ul>
            <p
                style={{
                    lineHeight: '100%',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Sie können Ihren Browser so einstellen, dass er alle oder einige
                Cookies ablehnt oder Sie benachrichtigt, wenn Websites Cookies
                setzen oder darauf zugreifen. Wenn Sie Cookies deaktivieren oder
                ablehnen, beachten Sie bitte, dass einige Teile unserer
                Websites, Produkte und Dienstleistungen möglicherweise
                unzugänglich werden oder nicht richtig funktionieren.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Haftungsausschluss und Risiken der Nutzung der Plattform</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                Die Plattform und die Website, einschließlich aller Inhalte
                (einschließlich der Inhalte Dritter), Funktionen und aller damit
                verbundenen Dienstleistungen werden auf alleiniges Risiko des
                Nutzers und ohne jegliche Zusicherungen oder Gewährleistungen
                "wie besehen" und "wie verfügbar" bereitgestellt. Wir
                garantieren nicht, dass die Plattform oder die App ganz oder
                teilweise zu jeder Zeit für den Nutzer verfügbar oder zugänglich
                sein wird.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Die Nutzung der Plattform birgt insbesondere durch das
                Herunterladen, die Installation oder die Nutzung der Website und
                der damit verbundenen Verweise auf Dritte (z.B. Anbieter von
                Vertriebsplattformen, Netzbetreiber, Gerätehersteller) Risiken:
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                (a) Offenlegung Ihrer persönlichen Daten oder anderer
                Informationen und des Bestehens Ihrer Beziehung zum
                Drittanbieter von Dienstleistungen gegenüber Dritten;
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                (b) Systemausfälle, sicherheitsbedingte Einschränkungen und
                unbefugte Aufhebung von Nutzungsbeschränkungen auf dem Endgerät
                sowie andere Störungen, die eine Nutzung unmöglich machen
                können, und
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                (c) Missbrauch aufgrund von Manipulationen durch Schadsoftware
                oder unbefugte Nutzung, einschließlich des Verlusts oder
                Diebstahls des Geräts des Nutzers, das für den Zugriff auf die
                Website oder die Plattform verwendet wird.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Wir sind berechtigt, die Nutzung der Website auf Endgeräten zu
                sperren oder zu deaktivieren, wenn die Sicherheitsmerkmale des
                Betriebssystems oder des Herstellers des Geräts, auf dem die
                Website installiert ist, zu irgendeinem Zeitpunkt verändert
                wurden (z.B. ein Gerät, das "jailbroken" wurde). Dementsprechend
                übernehmen wir keine Gewähr für die Funktionsfähigkeit und den
                Betrieb der App auf derartig veränderten Endgeräten oder auf
                älteren Endgeräten, die nicht mehr den technischen Anforderungen
                für die Nutzung der Website oder den Zugang zur Plattform
                entsprechen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                ALLE GARANTIEN, BEDINGUNGEN ODER BESTIMMUNGEN (OB AUSDRÜCKLICH,
                STILLSCHWEIGEND, GESETZLICH ODER ANDERWEITIG), EINSCHLIESSLICH
                UND OHNE EINSCHRÄNKUNG IN BEZUG AUF QUALITÄT, MARKTGÄNGIGKEIT,
                EIGNUNG FÜR EINEN BESTIMMTEN ZWECK ODER UNUNTERBROCHENEN,
                FEHLERFREIEN ZUGANG, WERDEN FÜR DIE WEBSITE UND DIE PLATTFORM
                AUSDRÜCKLICH AUSGESCHLOSSEN, SOWEIT DIES GESETZLICH ZULÄSSIG
                IST.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Es kann keine ausdrückliche oder stillschweigende Zusicherung
                oder Gewährleistung hinsichtlich der Richtigkeit oder
                Vollständigkeit der auf der Plattform bereitgestellten
                Informationen gegeben werden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Jeder Nutzer erkennt die Risiken an, die sich aus dem
                Reiseerlebnis ergeben können, und akzeptiert diese. Wir können
                für die Bereitstellung eines Teils der Plattform auch Server und
                andere Computer-Hardware verwenden, die sich in jeder beliebigen
                Gerichtsbarkeit weltweit befinden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Wir schließen jegliche Haftung für Verluste oder Schäden aus,
                die durch Übermittlungsfehler, technische Mängel,
                Betriebsstörungen, Betriebsunterbrechungen oder rechtswidrige
                Eingriffe in Übermittlungsnetze, EDV-Systeme/Rechner des Nutzers
                oder Dritter (einschließlich öffentlich zugänglicher Systeme)
                verursacht werden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Terminierung</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Wir können Ihr Konto sofort und ohne vorherige Ankündigung oder
                Haftung kündigen oder aussetzen, aus welchem Grund auch immer,
                einschließlich und ohne Einschränkung, wenn Sie gegen diese
                Allgemeinen Geschäftsbedingungen verstoßen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Mit der Kündigung erlischt Ihr Recht zur Nutzung des Dienstes
                sofort. Wenn Sie Ihr Konto kündigen möchten, können Sie die
                Nutzung des Dienstes einfach einstellen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Freigabe</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Soweit dies nach geltendem Recht zulässig ist, entbinden Sie
                uns, unsere Muttergesellschaft, verbundene Unternehmen und
                Tochtergesellschaften sowie deren jeweilige Führungskräfte,
                Direktoren, Anteilseigner, Mitglieder, Partner, Anwälte,
                Mitarbeiter, unabhängige Auftragnehmer,
                Telekommunikationsanbieter und Vertreter (zusammen die
                "entschädigten Parteien") hiermit von allen Vorwürfen, Klagen,
                Forderungen, Ansprüchen und Verlusten in Bezug auf die Nutzung
                der Website, der Plattform, eines Kontos und von
                Dienstleistungen und Aktivitäten im Zusammenhang mit der Nutzung
                der Plattform, von allen Behauptungen, Anklagen, Belastungen,
                Schulden, Klagegründen, Ansprüchen und Verlusten, die in
                irgendeiner Weise mit der Nutzung oder Aktivitäten im
                Zusammenhang mit der Nutzung der Website, der Plattform, eines
                Kontos und jeglicher Dienstleistungen oder Inhalte Dritter, die
                über die Website, die Plattform oder ein Konto bereitgestellt
                werden, zusammenhängen, einschließlich, aber nicht beschränkt
                auf Ansprüche in Bezug auf Folgendes: Fahrlässigkeit, grobe
                Fahrlässigkeit, vorsätzliche Beeinträchtigung von Verträgen oder
                vorteilhaften Geschäftsbeziehungen, Verleumdung, Datenschutz,
                Werbung, Falschdarstellung, falsche Identitäten, betrügerische
                Handlungen durch andere, Verletzung der Privatsphäre, Freigabe
                persönlicher Daten, fehlgeschlagene Transaktionen, Käufe oder
                Funktionalität der Plattform, Nichtverfügbarkeit der Website,
                der Plattform, von Inhalten Dritter oder eines Kontos, deren
                Funktionen und jedes andere technische Versagen, das zu einer
                Unzugänglichkeit der Website, der Plattform, der Inhalte Dritter
                oder eines Kontos führen kann, oder jeder Anspruch, der auf der
                Haftung für Handlungen beruht, die von Ihnen begangen wurden und
                mit denen Sie über die Website, die Plattform, die Inhalte
                Dritter und ein Konto in Berührung gekommen sind,
                einschließlich, aber nicht beschränkt auf Betrug,
                Computer-Hacking, Diebstahl oder Missbrauch von persönlichen
                Daten, Körperverletzung, Stalking, Vergewaltigung, Betrug,
                Meineid, Totschlag oder Mord. Die obige Liste dient lediglich
                der Veranschaulichung und ist nicht erschöpfend in Bezug auf die
                Arten oder Kategorien von Ansprüchen, die von uns freigegeben
                werden. Die Parteien beabsichtigen, diese Freigabe weit zu
                unseren Gunsten auszulegen, so dass jede Zweideutigkeit so
                ausgelegt wird, dass die weitestgehende Freigabe der Ansprüche
                erfolgt. Diese Freistellung soll eine vollständige Freistellung
                von Ansprüchen sein, und die Parteien erkennen die
                rechtsverbindliche Natur dieser Bestimmung und die Art der damit
                aufgegebenen Rechte an.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Beschränkung der Haftung</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                SafeTravy haftet nicht für Informationen, die über die
                SafeTravy-Produkte verfügbar und/oder in diese integriert sind.
                Folglich kann SafeTravy nicht für die Richtigkeit,
                Vollständigkeit, Qualität, Rechtmäßigkeit und Zuverlässigkeit
                der Informationen oder für die Ergebnisse, die mit Hilfe dieser
                Informationen erzielt werden, haftbar gemacht werden.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                Bei den SafeTravy-Diensten handelt es sich nicht um Instrumente,
                die für das Risikomanagement und die Risikobewertung verwendet
                werden und diese unterstützen. SafeTravy kann nicht garantieren,
                dass alle Informationen und Aktualisierungen vollständig,
                angemessen und fehlerfrei sind. Die Nutzer sollten die
                Ergebnisse und Daten des Dienstes stets auf individueller Basis
                bewerten. SafeTravy ist bestrebt, das Risiko für die Nutzer zu
                verringern, ist jedoch in keiner Weise für die Sicherheit der
                Nutzer verantwortlich, und SafeTravy übernimmt keine Haftung für
                Verluste, Körperverletzungen oder Tod von Nutzern.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                UNTER KEINEN UMSTÄNDEN UND UNTER KEINER RECHTSTHEORIE
                (UNERLAUBTE HANDLUNG, VERTRAG, VERSCHULDENSUNABHÄNGIGE HAFTUNG
                ODER SONSTIGES) HAFTEN WIR ODER EINER DER
                SCHADENSERSATZPFLICHTIGEN GEGENÜBER IHNEN ODER EINER ANDEREN
                PERSON FÜR SCHÄDEN, DIE SICH AUS DER NUTZUNG ODER
                MISSBRÄUCHLICHEN NUTZUNG ODER DER UNFÄHIGKEIT ZUR NUTZUNG DER
                PLATTFORM, DER SITE, DER INHALTE DRITTER ODER EINES KONTOS
                ERGEBEN, UNABHÄNGIG DAVON, OB ES SICH UM DIREKTE, INDIREKTE,
                BESONDERE, ZUFÄLLIGE ODER FOLGESCHÄDEN JEGLICHER ART,
                INFORMATIONSVERLUSTE, BETRIEBSUNTERBRECHUNGEN ODER ENTGANGENE
                GEWINNE HANDELT, SCHÄDEN JEGLICHER ART, VERLUST VON
                INFORMATIONEN, GESCHÄFTSUNTERBRECHUNGEN ODER ENTGANGENE GEWINNE,
                ENTGANGENE EINSPARUNGEN ODER DATENVERLUSTE ODER HAFTUNGEN
                AUFGRUND VON VERTRÄGEN, FAHRLÄSSIGKEIT, VERSCHULDENSUNABHÄNGIGER
                HAFTUNG ODER ANDEREN THEORIEN, DIE SICH IN IRGENDEINER WEISE AUS
                DER WEBSITE, DER PLATTFORM, DEN INHALTEN DRITTER ODER EINEM
                KONTO ERGEBEN ODER MIT DIESEN IN ZUSAMMENHANG STEHEN, ODER FÜR
                ANSPRÜCHE ODER FORDERUNGEN DRITTER, SELBST WENN WIR VON DER
                MÖGLICHKEIT SOLCHER SCHÄDEN, FORDERUNGEN ODER ANSPRÜCHE WUSSTEN
                ODER WISSEN MUSSTEN, FALLS DER VORSTEHENDE HAFTUNGSAUSSCHLUSS
                UND -VERZICHT ALS UNGÜLTIG ODER UNWIRKSAM ERACHTET WERDEN
                SOLLTE. IN EINIGEN GERICHTSBARKEITEN IST DER AUSSCHLUSS ODER DIE
                BESCHRÄNKUNG BESTIMMTER GARANTIEN UND/ODER HAFTUNGEN NICHT
                ZULÄSSIG, SO DASS BESTIMMTE DER OBEN GENANNTEN BESCHRÄNKUNGEN
                ODER AUSSCHLÜSSE FÜR SIE MÖGLICHERWEISE NICHT GELTEN.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                IN KEINEM FALL ÜBERSTEIGT UNSERE HAFTUNG, UNABHÄNGIG VON DER ART
                DER KLAGE UND DES VON IHNEN ERLITTENEN SCHADENS, DEN
                HÖCHSTBETRAG DER VON IHNEN AN UNS IN VERBINDUNG MIT DER
                PLATTFORM ODER DER SITE GEZAHLTEN ABONNEMENTGEBÜHREN ODER 300
                EUR, JE NACHDEM, WELCHER BETRAG HÖHER IST.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Wir haften nicht für die Nichterfüllung von Verpflichtungen
                gemäß diesen Bedingungen aufgrund von Ereignissen, die sich
                unserer Kontrolle entziehen, und die für die Erfüllung solcher
                Verpflichtungen vorgesehene Zeit wird um einen Zeitraum
                verlängert, der der Dauer solcher Ereignisse entspricht. Zu den
                Ereignissen, die sich unserer Kontrolle entziehen, gehören unter
                anderem höhere Gewalt, Krieg, Aufruhr, Brandstiftung, Embargos,
                zivile Unruhen, Streiks, Arbeitskämpfe, Ausrüstungsausfälle,
                Bankausfälle, Zusammenbruch oder Schwankungen des Marktes für
                virtuelle Währungen, Ausfälle bei der Verarbeitung von Kredit-
                oder Debitkartentransaktionen, Streiks, Feuer, Überschwemmungen,
                Erdbeben, Wirbelstürme, tropische Stürme oder andere
                Naturkatastrophen oder Unfälle, Arbeitskräfte- oder
                Materialmangel, Mangel an Transportmitteln, Einrichtungen,
                Treibstoff, Energie, staatliche Vorschriften oder
                Einschränkungen, Handlungen ziviler oder militärischer Behörden
                oder Terrorismus, Unterbrechungen von Glasfaserkabeln,
                Wetterbedingungen, Verstöße oder Versäumnisse Dritter,
                technische Probleme, einschließlich Hardware- und
                Softwareabstürze und andere Fehlfunktionen, Versagen der
                Infrastruktur von Telekommunikations- oder Informationsdiensten,
                Hacking, SPAM oder Versagen von Computern, Servern oder
                Softwareunterbrechungen aufgrund von Vandalismus, Diebstahl,
                Ausfällen von Telefondiensten, Stromausfällen,
                Internetunterbrechungen, Viren und mechanischen, Strom- oder
                Kommunikationsausfällen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Änderungen an diesen Bedingungen und Konditionen</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Wir behalten uns das Recht vor, diese Bedingungen jederzeit nach
                unserem alleinigen Ermessen zu ändern oder zu ersetzen. Wenn
                eine Änderung wesentlich ist, werden wir uns angemessen bemühen,
                Sie mindestens 30 Tage vor Inkrafttreten der neuen Bedingungen
                zu informieren. Was eine wesentliche Änderung darstellt, wird
                nach unserem alleinigen Ermessen bestimmt.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Indem Sie nach Inkrafttreten dieser Änderungen weiterhin auf
                unseren Dienst zugreifen oder ihn nutzen, erklären Sie sich mit
                den überarbeiteten Bedingungen einverstanden. Wenn Sie mit den
                neuen Bedingungen ganz oder teilweise nicht einverstanden sind,
                beenden Sie bitte die Nutzung der Website und des Dienstes.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Allgemein</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Diese Bedingungen, einschließlich der Datenschutzrichtlinie und
                anderer hierin enthaltener Richtlinien, stellen die gesamte und
                einzige Vereinbarung zwischen Ihnen und dem Unternehmen in Bezug
                auf den Gegenstand dieser Bedingungen dar und ersetzen alle
                früheren oder gleichzeitigen Vereinbarungen, Zusicherungen,
                Garantien und Absprachen, ob schriftlich oder mündlich, in Bezug
                auf den Gegenstand dieser Bedingungen. Sollte sich eine
                Bestimmung dieser Bedingungen als ungesetzlich, ungültig oder
                aus irgendeinem Grund nicht durchsetzbar erweisen, so gilt diese
                Bestimmung als von diesen Bedingungen abtrennbar und berührt
                nicht die Gültigkeit und Durchsetzbarkeit der übrigen
                Bestimmungen. Diese Bedingungen können nicht geändert,
                aufgehoben oder modifiziert werden, außer durch das Unternehmen,
                wie hierin vorgesehen. Weder diese Bedingungen noch irgendein
                Recht, eine Verpflichtung oder ein Rechtsbehelf hierunter sind
                von Ihnen abtretbar, übertragbar, delegierbar oder
                unterlizenzierbar, außer mit unserer vorherigen schriftlichen
                Zustimmung, und jeder Versuch einer Abtretung, Übertragung,
                Delegation oder Unterlizenzierung ist null und nichtig. Der
                Verzicht einer Partei auf eine Vertragsverletzung oder ein
                Versäumnis im Rahmen dieser Vereinbarung gilt nicht als Verzicht
                auf eine vorhergehende oder nachfolgende Vertragsverletzung oder
                ein Versäumnis. Überschriften, Titel oder
                Abschnittsüberschriften in diesen Bedingungen dienen nur der
                Übersichtlichkeit und definieren oder erklären in keiner Weise
                einen Abschnitt oder eine Bestimmung dieser Bedingungen.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Geltendes Recht und Streitbeilegung</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Alle Streitigkeiten, Meinungsverschiedenheiten oder Ansprüche,
                die sich aus diesem Vertrag oder im Zusammenhang mit ihm
                ergeben, einschließlich solcher, die sich auf seinen Abschluss,
                seine Auslegung, seine Durchführung, seine Verletzung, seine
                Beendigung oder seine Ungültigkeit beziehen, werden vom
                internationalen Handelsschiedsgericht bei der ukrainischen
                Industrie- und Handelskammer in Übereinstimmung mit dessen
                Bestimmungen entschieden.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Das für diesen Vertrag maßgebliche Recht ist das materielle
                Recht von Gibraltar.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                Das Schiedsgericht besteht aus einem Einzelschiedsrichter.
                Sitzungsort des Schiedsgerichts ist die Stadt Kiew, Ukraine. Die
                Sprache des Schiedsgerichtsverfahrens ist Englisch oder
                Ukrainisch.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Kontakt</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0in' }}>
                Wenn Sie Fragen zu diesen Allgemeinen Geschäftsbedingungen
                haben, können Sie uns kontaktieren:
            </p>
            <p style={{ marginBottom: '0in' }}>
                Per E-Mail:
                <a href='mailto:team@safetravy.com'>
                    <u>team@safetravy.com</u>
                </a>
            </p>
        </>
    )
}
