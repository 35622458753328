export const TermsSpanish = () => {
    return (
        <>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Términos y condiciones de SafeTravy</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Estos Términos y Condiciones (los "Términos" o este "Acuerdo")
                rigen el uso de la plataforma electrónica, incluyendo cualquier
                sitio web o aplicación móvil (la "Aplicación", junto con el
                sitio web, el "Sitio") para acceder a la plataforma, y cualquier
                servicio prestado a través de la plataforma (colectivamente, la
                "Plataforma") proporcionada por SafeTravy Limited (la "Empresa",
                "nosotros", "nos" o "nuestro"). Las Condiciones constituyen un
                acuerdo vinculante entre la Empresa y usted, como usuario
                individual ("usted", "su" o "Usuario") para su uso individual de
                la Aplicación y la Plataforma. Al registrarse y descargar la
                Aplicación y utilizar la Plataforma, usted confirma su
                aceptación del presente Acuerdo y de nuestra Política de
                Privacidad asociada. Si no acepta las presentes Condiciones,
                deberá desinstalar inmediatamente la Aplicación y dejar de
                utilizar la Aplicación y la Plataforma.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Descargo de responsabilidad en materia de valores: Ningún
                material ni ninguna otra información que pueda estar disponible
                en el Sitio o en la Plataforma constituirá ni se interpretará
                como una recomendación, aprobación, oferta, invitación o
                solicitud para que usted tome decisión alguna; todas las
                decisiones se toman por voluntad y experiencia propias. Además,
                usted entiende que ninguno de los proveedores de información,
                incluidos los Proveedores Terceros (tal y como se definen más
                adelante), le está asesorando personalmente sobre la naturaleza,
                el potencial, el valor o la idoneidad de ninguna cuestión de
                seguridad concreta en relación con sus viajes. Tenga en cuenta
                que la situación en el mundo cambia rápidamente, por lo que
                cualquier información, contenido, Contenido de Terceros (como se
                define más adelante) u otro material proporcionado en o a través
                del Sitio o la Plataforma puede no ser completo o actual, o
                puede ser sustituido por información más actual. Usted confía en
                dicha información por su cuenta y riesgo.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Nuestro Sitio y nuestra Plataforma no pretenden ofrecer
                asesoramiento jurídico, sobre seguros o inversiones, y nada de
                lo que aparece en el Sitio o en la Plataforma debe interpretarse
                como una oferta sobre cómo organizar su viaje. Toda esta
                información es solo una recomendación personalizada.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>1. Aplicabilidad</b>
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                1.1. Nosotros, SafeTravy Limited (en adelante "SafeTravy"),
                ofrecemos servicios en el ámbito del entretenimiento informático
                Estas condiciones generales se aplican a la relación contractual
                entre SafeTravy y el Cliente ("Cliente" o "Usted") con respecto
                a todos los servicios que prestamos.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <br />
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>2. Definiciones</b>
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Cuenta</b>
                significa la cuenta establecida por un Usuario que ha descargado
                la App o accedido al Sitio y se ha registrado en la Empresa para
                utilizar el Sitio y la Plataforma.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>App</b>
                significa la aplicación móvil proporcionada por la Empresa para
                acceder a la Plataforma.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Persona Autorizada </b>
                significa cualquier persona autorizada a acceder y utilizar el
                Sitio (incluida la Aplicación) y la Plataforma en nombre de un
                Usuario.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Plataformas digitales </b>
                se refiere a plataformas de distribución de terceros en las que
                se puede acceder o descargar aplicaciones móviles u otros
                programas de software, incluidas, entre otras, Apple App Store y
                Google Play.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Autoridad gubernamental </b>
                significa cualquier nación o gobierno o cualquier provincia o
                estado o cualquier otra subdivisión política del mismo, o
                cualquier entidad, autoridad u organismo que ejerza funciones
                ejecutivas, legislativas, judiciales, reguladoras o
                administrativas de o relativas al gobierno, incluyendo cualquier
                autoridad gubernamental, agencia, departamento, junta, comisión
                o instrumento o cualquier subdivisión política del mismo,
                cualquier juzgado, tribunal o árbitro, y cualquier organización
                autorreguladora.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Material</b>
                significa cualquier material de oferta, hoja de términos,
                informe de investigación, documentación de productos o servicios
                o cualquier otra información proporcionada a través de la
                Plataforma.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Por
                <b>Datos Personales </b>
                se entiende la información suministrada por un Usuario a partir
                de la cual puede determinarse, directa o indirectamente, la
                identidad de dicho Usuario.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Política de Privacidad </b>
                significa los términos y condiciones adicionales que rigen la
                recopilación, uso y divulgación de la Información Personal de
                cada Usuario, tal y como se establece aquí. Cada Usuario debe
                leer y aceptar la Política de Privacidad para poder utilizar la
                Aplicación o el Sitio.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Las Notificaciones de Servicio </b>
                son notificaciones unidireccionales de la Empresa (que pueden
                incluir notificaciones relacionadas con la seguridad) a través
                de mensajes de texto o correos electrónicos y, en su caso,
                notificaciones push a través del Sitio. Estas notificaciones se
                envían al Usuario en relación con determinada información o
                eventos relacionados con una cuenta a la que el Usuario tiene
                acceso a través de la Plataforma.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Usuario</b>
                significa cualquier persona que se haya registrado en la Empresa
                para utilizar el Sitio y acceder a la Plataforma y cualquier
                Persona Autorizada que actúe en su nombre.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Credenciales de usuario </b>
                significa el conjunto de identificación de usuario, contraseña,
                número de identificación personal, token y cualquier otra
                información o dispositivo proporcionado a un Usuario para
                acceder a la Plataforma.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>La Empresa </b>
                (denominada "la Empresa", "Nosotros", "Nos" o "Nuestro" en el
                presente Contrato) se refiere a SAFETRAVY LIMITED, Suite 4, 2nd
                floor, the West Wing, Montarik House, 3 Bedlam Court, Gibraltar,
                GX11 1AA, GIBRALTAR, GIBRALTAR.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Dispositivo</b>: cualquier aparato que pueda acceder al
                Servicio, como un ordenador, un teléfono móvil o una tableta
                digital.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Prueba gratuita </b>
                se refiere a un periodo de tiempo limitado que puede ser
                gratuito al adquirir una Suscripción.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Servicio</b>
                se refiere a toda la información, recomendaciones y
                notificaciones proporcionadas por el sitio web.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Las suscripciones </b>
                hacen referencia a los servicios o al acceso al Servicio que la
                Empresa le ofrece a Usted mediante suscripción.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Términos y Condiciones </b>
                (también referidos como "Términos") significan estos Términos y
                Condiciones que forman el acuerdo completo entre Usted y la
                Compañía con respecto al uso del Servicio.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Por Servicio de
                <b>terceros </b>
                se entiende cualquier servicio o contenido (incluidos datos,
                información, productos o servicios) proporcionado por un tercero
                que pueda mostrarse, incluirse o ponerse a disposición a través
                del Servicio.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>El sitio web </b>
                remite a SafeTravy, accesible desde www.safetravy.com
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}></p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Cambios</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Nos reservamos el derecho a:
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                (a) modificar, actualizar o cambiar los términos y condiciones
                del presente Acuerdo o de nuestra Política de Privacidad ;
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                (b) modificar, actualizar o cambiar el Sitio y la Plataforma,
                incluida la eliminación o interrupción de cualquier contenido o
                característica del Sitio o la Plataforma (todo lo anterior
                denominado "Cambios").
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Podemos realizar dichos Cambios en cualquier momento sin previo
                aviso. Cualquier Cambio en este Acuerdo puede ser publicado en
                nuestro sitio web o notificado a usted a través de
                notificaciones push a través del Sitio o un correo electrónico a
                la dirección de correo electrónico en su Cuenta. Por este
                motivo, debe consultar nuestro sitio web con regularidad,
                permitir que el Sitio reciba dichas notificaciones push y
                mantener su dirección de correo electrónico y otra información
                de contacto actualizada en la Cuenta. Usted acepta cualquier
                Cambio si continúa utilizando el Sitio y la Plataforma después
                de que dichos Cambios se hayan efectuado.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Términos de la plataforma digital</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                La App puede estar disponible para su descarga desde una o más
                Plataformas Digitales. Su descarga, instalación, acceso o uso de
                la Aplicación también están sujetos a los términos y condiciones
                y a las políticas de privacidad de la Plataforma Digital
                aplicable (los "Términos de la Plataforma Digital"). En caso de
                conflicto entre las presentes Condiciones y las Condiciones de
                la Plataforma Digital, prevalecerán las presentes Condiciones.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                La Aplicación es independiente de cualquier Plataforma Digital y
                no está asociada, afiliada, patrocinada, respaldada ni vinculada
                de modo alguno a ninguna de ellas. Usted y nosotros reconocemos
                que el presente Acuerdo se celebra únicamente entre usted y
                nosotros, y no con ninguna Plataforma Digital, y nosotros, y no
                la Plataforma Digital, somos los únicos responsables de la App y
                de su contenido en la medida especificada en el presente
                Acuerdo.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Usted y nosotros reconocemos y aceptamos que la Plataforma
                Digital correspondiente, y las filiales de dicha Plataforma
                Digital son terceros beneficiarios de estas Condiciones, y que,
                tras su aceptación de estas Condiciones, dicha Plataforma
                Digital tendrá derecho (y se considerará que ha aceptado el
                derecho) a hacer cumplir estas Condiciones frente a usted como
                tercero beneficiario de las mismas.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}></p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}></p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Requisitos del dispositivo de red y del operador</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Usted reconoce que su acuerdo con su proveedor de red móvil y de
                Internet (el "Proveedor de Red") se aplicará a su uso del Sitio.
                Usted reconoce que su proveedor de red puede cobrarle por los
                servicios de datos mientras utiliza determinadas funciones del
                Sitio o por cualquier otro cargo de terceros que pueda surgir, y
                usted acepta la responsabilidad exclusiva de dichos cargos. Si
                usted no es el pagador de la factura del dispositivo móvil o de
                Internet utilizado para acceder al Sitio, se asumirá que ha
                recibido permiso del pagador de la factura para utilizar el
                Sitio. También debe asegurarse de que el uso que hace del Sitio
                no infringe el contrato de su dispositivo móvil o de Internet ni
                ningún contrato de servicio de datos inalámbricos.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Requisitos e inscripción</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Usted debe tener al menos 18 años de edad para acceder y
                utilizar el Sitio y la Plataforma. Asimismo, afirma que es
                plenamente capaz y competente para aceptar los términos,
                condiciones, obligaciones, afirmaciones, declaraciones y
                garantías establecidos en estos Términos, y para acatar y
                cumplir estos Términos. Debe registrarse en la Empresa para
                utilizar el Sitio y la Plataforma; se compromete a proporcionar
                información completa y precisa al registrarse para utilizar el
                Sitio y la Plataforma, y a mantener dicha información
                actualizada.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Tenemos la facultad exclusiva de aceptar o rechazar su registro
                en la Plataforma. Solo los Usuarios cuyos registros sean
                aprobados por nosotros serán nuestros clientes.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Acuse de recibo</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Estos son los Términos y Condiciones que rigen el uso de este
                Servicio y el acuerdo que opera entre Usted y la Compañía. Estos
                Términos y Condiciones establecen los derechos y obligaciones de
                todos los usuarios en relación con el uso del Servicio.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Su acceso y uso del Servicio están condicionados a su aceptación
                y cumplimiento de estos Términos y Condiciones. Estas
                Condiciones se aplican a todos los visitantes, usuarios y demás
                personas que accedan o utilicen el Servicio.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Al acceder o utilizar el Servicio, Usted acepta quedar vinculado
                por las presentes Condiciones Generales. Si no está de acuerdo
                con alguna parte de estas Condiciones, no podrá acceder al
                Servicio.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Usted declara que es mayor de 18 años. La Empresa no permite que
                los menores de 18 años utilicen el Servicio.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Su acceso y uso del Servicio también están condicionados a Su
                aceptación y cumplimiento de la Política de Privacidad de la
                Empresa. Nuestra Política de Privacidad describe Nuestras
                políticas y procedimientos sobre la recopilación, uso y
                divulgación de Su información personal cuando Usted utiliza la
                Aplicación o el Sitio Web y le informa sobre Sus derechos de
                privacidad y cómo le protege la ley. Lea atentamente nuestra
                Política de Privacidad antes de utilizar nuestro Servicio.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Suscripciones</b>
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Periodo de suscripción</b>
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                El Servicio o algunas partes del Servicio sólo están disponibles
                con una Suscripción de pago. Se le facturará por adelantado de
                forma recurrente y periódica (por ejemplo, diaria, semanal,
                mensual o anualmente), en función del tipo de plan de
                Suscripción que seleccione al adquirir la Suscripción.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Las Partes (Empresa y Usuario) acuerdan y entienden que el
                precio de la Suscripción no incluye impuestos (IVA u otros
                impuestos pagaderos en el país de residencia del Usuario). Por
                la presente, el Usuario acepta que, en los casos previstos por
                la legislación local, además de pagar el precio de la
                Suscripción, deberá abonar el importe de los impuestos
                recaudados en relación con dicha compra de la Suscripción.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Al final de cada periodo, Su Suscripción se renovará
                automáticamente en las mismas condiciones, a menos que Usted la
                cancele o la Empresa la cancele.
            </p>
            <p style={{ marginBottom: '0in' }}>
                <b>Actualización de la suscripción</b>
            </p>
            <p style={{ marginBottom: '0in' }}>
                Si el Usuario utiliza un tipo de suscripción de pago y la
                actualiza a otro tipo de suscripción (aumenta el volumen del
                Servicio o el plazo de uso del Servicio), las Partes acuerdan
                por la presente y el Usuario y la Empresa acuerdan por
                adelantado que el Usuario pagará 0,99 (noventa y nueve) céntimos
                de euro adicionales sin impuestos (los impuestos se cobran y
                pagan adicionalmente) por cada mes de dicha suscripción,
                independientemente del momento de dicha actualización.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                Por ejemplo: El Usuario tiene una suscripción pagada a la tarifa
                Básica por un período de un mes, y después de cinco días de
                utilizar dicha suscripción, el Usuario actualiza esta
                suscripción a la suscripción Extra, en cuyo caso, previo pago de
                0,99 (noventa y nueve) céntimos de euro sin impuestos (los
                impuestos se cobran y pagan adicionalmente), el Usuario recibirá
                acceso a todos los servicios de la suscripción Extra por el
                período hasta el final de la suscripción Básica prepagada.{' '}
            </p>
            <p style={{ marginBottom: '0in' }}>
                Si el Usuario actualiza la suscripción Básica pagada durante 3
                meses, deberá pagar a la Empresa 2,97 (dos euros con noventa y
                siete céntimos de euro) adicionales por dicha actualización. Lo
                mismo se aplica a la actualización de suscripciones pagadas por
                otros plazos.
            </p>
            <p style={{ marginBottom: '0in' }}>
                Las Partes también acuerdan que si el Usuario cambia de una
                Suscripción con una cantidad mayor de Servicio a una Suscripción
                con una cantidad menor de Servicio, el Usuario recibirá la
                cantidad total de Servicio hasta el final del plazo pagado de la
                Suscripción con la cantidad mayor de Servicio, y la Empresa no
                hará ningún reembolso o crédito al Usuario en este caso.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Cancelación de suscripciones</b>
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Puede cancelar la renovación de su Suscripción a través de la
                página de configuración de su Cuenta o poniéndose en contacto
                con la Empresa. No se le reembolsarán las cuotas que ya haya
                abonado por el periodo de Suscripción actual y podrá acceder al
                Servicio hasta que finalice dicho periodo.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Facturación</b>
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Deberá proporcionar a la Empresa información de facturación
                precisa y completa, incluyendo nombre completo, dirección,
                estado,[1] e información válida sobre el método de pago.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                En caso de que, por cualquier motivo, no se produzca la
                facturación automática, la Empresa emitirá una factura
                electrónica en la que se le indicará que debe proceder
                manualmente, dentro de un plazo determinado, al pago íntegro
                correspondiente al periodo de facturación indicado en la
                factura.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Cambios en las tasas</b>
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                La Empresa, a su entera discreción y en cualquier momento, puede
                modificar las cuotas de Suscripción. Cualquier cambio en las
                cuotas de Suscripción entrará en vigor al final del periodo de
                Suscripción vigente en ese momento.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                La Empresa le avisará con una antelación razonable de cualquier
                cambio en las cuotas de Suscripción para darle la oportunidad de
                cancelar su Suscripción antes de que dicho cambio entre en
                vigor.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                El uso continuado del Servicio por parte del usuario tras la
                entrada en vigor de la modificación de la cuota de suscripción
                constituye su aceptación del pago de la cuota de suscripción
                modificada.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Reembolsos</b>
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Salvo en los casos en que la ley lo exija, las cuotas de
                suscripción pagadas no son reembolsables.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                La Empresa podrá estudiar caso por caso determinadas solicitudes
                de reembolso de las Suscripciones y concederlas a su entera
                discreción.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Cláusula de reembolsos</b>
                <br />
                Excepto cuando lo exija la ley, las tarifas de suscripción
                pagadas no son reembolsables.
                <br />
                Ciertas solicitudes de reembolso de suscripciones pueden ser
                consideradas por la Compañía caso por caso y concedidas a la
                única discreción de la Compañía.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                <b>Prueba gratuita</b>
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                La Empresa podrá, a su entera discreción, ofrecer una
                Suscripción con una Prueba Gratuita durante un período de tiempo
                limitado.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Es posible que tenga que introducir sus datos de facturación
                para inscribirse en la prueba gratuita.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Si introduce sus datos de facturación al suscribirse a una
                Prueba Gratuita, la Empresa no le cobrará hasta que la Prueba
                Gratuita haya caducado. El último día del periodo de Prueba
                Gratuita, a menos que cancele su Suscripción, se le cobrarán
                automáticamente las tarifas de Suscripción aplicables al tipo de
                Suscripción que haya seleccionado.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                En cualquier momento y sin previo aviso, la Empresa se reserva
                el derecho a (i) modificar los términos y condiciones de la
                oferta de Prueba Gratuita, o (ii) cancelar dicha oferta de
                Prueba Gratuita.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Cuentas de usuario</b>
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Para utilizar los servicios de la Plataforma, debe crear una
                cuenta en la Plataforma (la "Cuenta"). La Cuenta puede ser
                registrada por cualquier persona física mayor de 18 años.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Al crear una cuenta con nosotros, debe proporcionarnos
                información precisa, completa y actualizada en todo momento. No
                hacerlo constituye un incumplimiento de las Condiciones, que
                puede dar lugar a la cancelación inmediata de su cuenta en
                nuestro Servicio.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Usted es responsable de salvaguardar la contraseña que utiliza
                para acceder al Servicio y de cualquier actividad o acción
                realizada con su contraseña, ya sea con nuestro Servicio o con
                un Servicio de terceros.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Usted se compromete a no revelar su contraseña a terceros. Debe
                notificarnos inmediatamente cualquier violación de la seguridad
                o uso no autorizado de su cuenta. Sólo puede acceder a su cuenta
                desde un dispositivo.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Usted no puede utilizar como nombre de usuario el nombre de otra
                persona o entidad o que no esté legalmente disponible para su
                uso, un nombre o marca comercial que esté sujeto a cualquier
                derecho de otra persona o entidad que no sea Usted sin la debida
                autorización, o un nombre que sea ofensivo, vulgar u obsceno.
            </p>
            <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                Cada Usuario reconoce que cada conjunto de Credenciales de
                Usuario es intransferible y sólo podrá ser utilizado por el
                Usuario al que se le haya expedido. Dichas Credenciales de
                Usuario no se revelarán ni transferirán a terceros sin el
                permiso por escrito de la Empresa. Nunca le pediremos, por
                ningún motivo, ya sea por correo electrónico, correo ordinario o
                teléfono, que revele sus Credenciales de Usuario. Las
                solicitudes de contraseña sólo se realizarán en línea y una vez
                que haya iniciado sesión en la Plataforma. Nunca le enviaremos
                enlaces incrustados en un correo electrónico solicitándole que
                se registre en la Plataforma haciendo clic en dicho enlace. Si
                recibe un enlace incrustado por correo electrónico que dice
                proceder de nosotros o de la Plataforma, no lo abra ni haga clic
                en él. El correo electrónico no procede de nosotros y es
                probable que sea fraudulento.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Personas autorizadas</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Cada Usuario y Persona Autorizada reconoce haber recibido y
                aceptado las presentes Condiciones. Al solicitar que cualquier
                Persona Autorizada reciba acceso a la Plataforma y una Cuenta en
                su nombre, el Usuario reconoce y declara que la Persona
                Autorizada está debidamente autorizada para (i) acceder y
                utilizar la Plataforma en nombre del Usuario y, en su caso,
                ejercer las mismas facultades conferidas por el Usuario a la
                Persona Autorizada de conformidad con cualquier poder subyacente
                en la misma medida en que sea técnicamente factible y que los
                servicios ofrecidos en la Plataforma son análogos a los
                servicios que el Usuario puede utilizar a través de otros
                canales; (ii) aceptar cualquier modificación de estas
                Condiciones en nombre del Usuario; y (iii) solicitar o
                suscribirse a cualquiera de los servicios de la Plataforma que
                requieran una solicitud o suscripción por separado.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Cada Usuario se asegurará de que cada Persona Autorizada que
                actúe en su nombre esté informada, acepte y cumpla las presentes
                Condiciones y, en su caso, las Condiciones de Proveedores de
                Servicios de Terceros. Usted será plenamente responsable de
                todos los actos u omisiones o incumplimientos de su Persona
                Autorizada designada en el acceso y uso de la Plataforma y de
                cualquier transacción realizada a través de su Cuenta.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Usted declara, se compromete y confirma que ha obtenido el
                consentimiento de sus Personas Autorizadas para la recogida,
                uso, transferencia, divulgación y tratamiento de los Datos
                Personales de dichas Personas Autorizadas de conformidad con las
                presentes Condiciones y la Política de Privacidad.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Usted entiende y acepta que la información y los servicios
                proporcionados por la Plataforma no se facilitan a, ni pueden
                ser utilizados por o en beneficio de, cualquier persona o
                entidad en cualquier jurisdicción en la que la prestación o el
                uso de los mismos sea contrario a cualquier ley aplicable, o en
                la que no estemos autorizados a proporcionar dicha Plataforma o
                información y servicios.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Propiedad intelectual</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Todos los títulos, derechos de propiedad y derechos de propiedad
                intelectual en o relacionados con el Sitio y la Plataforma,
                cualquier información transmitida por, a o a través de la
                Plataforma y la información relativa al uso de la Plataforma
                permanecerán con la Empresa o sus licenciantes. Nada de lo
                contenido en la Plataforma se interpretará en el sentido de que
                confiere al Usuario licencia alguna, salvo lo expresamente
                establecido en el presente documento, sobre cualquiera de los
                títulos, derechos de propiedad y/o derechos de propiedad
                intelectual de la Empresa o de terceros, ya sea por preclusión,
                implicación o de otro modo.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                La Plataforma y la Aplicación pueden proporcionarle acceso a
                contenidos, información, citas, vídeos, fotos u otros materiales
                (los "Contenidos de Terceros") suministrados por determinados
                terceros (los "Proveedores de Contenidos de Terceros"). La
                Empresa no respalda ni recomienda, y no es responsable de
                verificar la exactitud, validez o integridad de ningún Contenido
                de Terceros proporcionado a través del Sitio o la Plataforma. El
                uso que usted haga o la confianza que deposite en dicho
                Contenido de Terceros es por su cuenta y riesgo. Todos los
                títulos, derechos de propiedad y derechos de propiedad
                intelectual relativos al Contenido de Terceros seguirán
                perteneciendo al Proveedor de Contenido de Terceros
                correspondiente. Nada de lo contenido en la Plataforma se
                interpretará en el sentido de que confiere al Usuario licencia
                alguna, salvo lo expresamente establecido en el presente
                documento, sobre la titularidad, los derechos de propiedad y/o
                los derechos de propiedad intelectual de los Proveedores de
                Contenidos de Terceros, ya sea por preclusión, implicación o de
                otro modo.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Siempre que cumpla las presentes Condiciones, podrá descargar y
                acceder al Sitio en un único dispositivo móvil y acceder a la
                Plataforma utilizando las Credenciales de Usuario debidamente
                emitidas. Todos los demás derechos sobre el Sitio están
                reservados por la Empresa. En caso de incumplimiento por su
                parte de las presentes Condiciones, tendremos derecho a poner
                fin inmediatamente a su uso y acceso al Sitio y a la Plataforma.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Usted se compromete a no hacerlo:
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                (a) modificar, adaptar, reproducir, traducir o crear trabajos
                derivados del Sitio o de la Plataforma, o de cualquier dato o
                contenido (incluido el Contenido de Terceros) proporcionado a
                través del Sitio o de la Plataforma, o de cualquier parte de los
                mismos, o intentar realizar ingeniería inversa, descompilar,
                desensamblar o intentar descubrir de cualquier otro modo el
                código fuente del Sitio o de la Plataforma;
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                (b) eliminar cualquier aviso de copyright, marca comercial,
                leyenda, logotipo o identificación de producto del Sitio o de la
                Plataforma;
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                (c) tergiversar los otros sitios como el Sitio de la Empresa
                mediante la cooptación de la "apariencia" visual o el texto del
                Sitio de la Empresa o violar de otro modo los derechos de
                propiedad intelectual de la Empresa, incluyendo, sin limitación,
                el "scraping" de texto o imágenes del Sitio de la Empresa o de
                los banners y/o enlaces de texto gestionados por la Empresa,
                marketing de búsqueda o cualquier otra campaña online y offline,
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                (d) editar, modificar, filtrar, truncar o cambiar el orden de la
                información contenida en cualquier parte de los Sitios de la
                Empresa, o eliminar, oscurecer o minimizar cualquier parte del
                Sitio de la Empresa de cualquier forma sin autorización de la
                Empresa; o
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                (e) hacer cualquier uso comercial del Sitio o de la Plataforma o
                del logotipo, marca comercial o nombre de marca de la Empresa de
                cualquier forma.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Cada Usuario autoriza a la Empresa a utilizar cualquier
                información o contenido proporcionado por el Usuario o procesado
                en relación con el uso del Sitio y la Plataforma (por ejemplo,
                Información Personal, información geográfica, información del
                dispositivo) en el contexto y con el fin de proporcionar
                servicios o productos en la Plataforma y el uso seguro del Sitio
                y la Plataforma.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Suministro de material e información</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Usted reconoce que ni la Empresa ni la Plataforma son sus
                asesores de viaje o fiduciarios. Asimismo, reconoce que ninguno
                de los Materiales que proporcionamos o ponemos a su disposición
                en la Plataforma constituye nuestra recomendación o solicitud de
                que tome una decisión concreta durante su viaje.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Usted reconoce que no tenemos el deber ni la obligación de
                verificar, corregir, completar o actualizar ningún Material que
                aparezca en la Plataforma. Los materiales, incluidos, entre
                otros, noticias e investigaciones, pueden ser elaborados por
                proveedores de información independientes de nosotros. No
                garantizamos que el Material sea exacto, completo o actualizado
                oportunamente. Antes de tomar una decisión, le recomendamos que
                investigue y analice la información o consulte a un asesor de
                viajes. El uso que usted haga de los materiales o la confianza
                que deposite en ellos será por su cuenta y riesgo.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Servicio y otras notificaciones</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Usted acepta recibir notificaciones relativas a la Aplicación,
                la Plataforma, su Cuenta y las Condiciones a través de las
                Notificaciones de Servicio. Acepta que dichas Notificaciones de
                Servicio constituirán una notificación efectiva en lugar de las
                notificaciones escritas, por correo u otras formas de
                notificación exigidas por la legislación aplicable.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Usted es el único responsable de supervisar la cuenta de correo
                electrónico aplicable sin más recordatorios ni notificaciones
                repetidas por parte de la Empresa. Deberá informar
                inmediatamente de cualquier uso o acceso no autorizado a la
                Plataforma.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Usted exime a la Empresa de cualquier responsabilidad por
                pérdidas o daños resultantes del uso de las Notificaciones del
                Servicio en la medida permitida por la ley. La Empresa no ofrece
                ninguna garantía ni acepta ninguna responsabilidad de que la
                información proporcionada a través de las Notificaciones de
                Servicio esté actualizada, sea correcta o esté completa.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Información personal</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Como parte de la Plataforma, los Datos Personales del Usuario
                pueden ser recogidos, utilizados, transferidos, divulgados o
                tratados de otro modo por la Empresa de conformidad con la
                Política de Privacidad. El usuario debe leer atentamente la
                Política de Privacidad antes de registrarse y utilizar el Sitio
                y la Plataforma. El usuario consiente la recopilación, el uso y
                la divulgación de sus Datos Personales de conformidad con las
                presentes Condiciones y la Política de Privacidad, incluida, sin
                limitación, la divulgación al Tercero Proveedor de Servicios a
                efectos de la prestación de servicios y la realización de
                transacciones en relación con la Cuenta.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Usted se compromete a proporcionar Datos Personales veraces,
                exactos, actuales y completos. Asimismo, se compromete a
                mantener y actualizar puntualmente los Datos Personales para que
                sigan siendo verdaderos, exactos, actuales y completos en todo
                momento durante la vigencia del presente Acuerdo.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Debe informarnos puntualmente de todos los cambios, incluidos,
                entre otros, los cambios en la Información personal en relación
                con la Plataforma. Si proporciona cualquier información que sea
                falsa, inexacta, no actual o incompleta, o si nosotros o
                cualquiera de nuestros agentes autorizados tiene motivos
                razonables para sospechar que dicha información es falsa,
                inexacta, no actual o incompleta, tenemos derecho a suspender o
                cancelar la Cuenta y rechazar cualquier uso actual o futuro de
                la Plataforma y del Sitio por su parte, así como a someterle a
                responsabilidad civil o remitirle a las autoridades policiales
                competentes para su enjuiciamiento penal. No seremos
                responsables de realizar ninguna compensación, monetaria o de
                otro tipo, tras dicha suspensión, rescisión o imposibilidad de
                que usted utilice la Plataforma o el Sitio.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Tenga en cuenta que podemos recopilar información utilizando
                tecnologías de seguimiento relativas a su dispositivo, como la
                dirección IP, el proveedor de red, el operador de telefonía
                móvil, el tipo de navegador móvil, la marca de tiempo, la zona
                horaria, información sobre la velocidad, el rumbo, la
                orientación y la altitud de un dispositivo, u otra información
                identificativa del dispositivo. El Usuario consiente el uso de
                estas tecnologías de seguimiento y reconoce que la información
                obtenida, incluida la Información Personal, puede compararse con
                información pública o privada a la que tenga acceso la Empresa o
                cualquier Tercero Proveedor de Servicios. El Usuario también
                consiente que dicha información sea compartida con los
                proveedores de servicios de la Empresa y de los Proveedores de
                Servicios de Terceros con el fin de proporcionar y mantener las
                tecnologías de rastreo y los servicios relacionados. También
                podemos recopilar datos precisos de geolocalización de o sobre
                su dispositivo, que pueden expresarse mediante coordenadas de
                latitud-longitud obtenidas a través de herramientas GPS, datos
                WiFi, triangulación de torres de telefonía móvil u otras
                técnicas. El uso que hacemos de esa información se describe en
                nuestra Política de Privacidad.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Cookies y cómo los utilizamos
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Las cookies son archivos de texto colocados en su computadora
                para recopilar información estándar de registro de Internet y
                sobre el comportamiento de los visitantes. Cuando visita
                nuestros sitios web, podemos recopilar información de usted
                automáticamente a través de cookies o tecnología similar.
                SafeTravy utiliza cookies de varias maneras para mejorar su
                experiencia en nuestro sitio web y otros servicios digitales.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Existen diferentes tipos de cookies; sin embargo, nuestro sitio
                utiliza:
            </p>
            <ul>
                <li>
                    <p
                        style={{
                            lineHeight: '125%',
                            marginTop: '0.17in',
                            marginBottom: '0in',
                        }}>
                        Información de cuenta e inicio de sesión
                    </p>
                </li>
                <li>
                    <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                        Suscripciones al boletín por correo electrónico
                    </p>
                </li>
                <li>
                    <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                        Encuestas y formularios
                    </p>
                </li>
                <li>
                    <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                        Preferencias del sitio
                    </p>
                </li>
                <li>
                    <p style={{ lineHeight: '125%', marginBottom: '0in' }}>
                        Funcionalidad – SafeTravy utiliza estas cookies para
                        reconocerle en nuestro sitio web y recordar sus
                        preferencias previamente seleccionadas. Esto puede
                        incluir el idioma que prefiere y su ubicación. Se usa
                        una combinación de cookies de primera y tercera parte.
                    </p>
                </li>
                <li>
                    <p style={{ lineHeight: '125%', marginBottom: '0.17in' }}>
                        Publicidad – SafeTravy utiliza estas cookies para
                        recopilar información sobre su visita a nuestro sitio
                        web, el contenido que vio, los enlaces que siguió y la
                        información sobre su navegador, dispositivo y dirección
                        IP. SafeTravy a veces comparte algunos aspectos
                        limitados de estos datos con terceros con fines
                        publicitarios. También podemos compartir los datos en
                        línea recopilados a través de cookies con nuestros
                        socios publicitarios. Esto significa que cuando visita
                        otro sitio, se le puede mostrar publicidad basada en sus
                        patrones de navegación en nuestro sitio.
                    </p>
                </li>
            </ul>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Puede configurar su navegador para rechazar todas o algunas
                cookies o para que le avise cuando los sitios web establezcan o
                accedan a cookies. Si desactiva o rechaza las cookies, tenga en
                cuenta que algunas partes de nuestros sitios, productos y
                servicios pueden volverse inaccesibles o no funcionar
                correctamente.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>
                    Descargo de responsabilidad y riesgos del uso de la
                    plataforma
                </b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                La Plataforma y el Sitio, incluido todo el contenido (incluido
                el Contenido de Terceros), las funciones y cualquier servicio
                relacionado se proporcionan "tal cual" y "según disponibilidad"
                por cuenta y riesgo exclusivos del Usuario y sin ninguna
                declaración ni garantía. No garantizamos que toda o parte de la
                Plataforma o de la Aplicación esté disponible o accesible para
                el Usuario en todo momento.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                El uso de la Plataforma, debido a la descarga, instalación o uso
                del Sitio y los puntos de referencia asociados con terceros (por
                ejemplo, proveedores de plataformas de distribución, proveedores
                de redes, fabricantes de dispositivos) implica riesgos, en
                particular:
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                (a) la divulgación a terceros de sus Datos Personales u otra
                información, así como de la existencia de su relación con el
                Tercero Proveedor de Servicios;
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                (b) las interrupciones del sistema, las restricciones
                relacionadas con la seguridad y la eliminación no autorizada de
                las restricciones de uso en el dispositivo final, así como otras
                perturbaciones que puedan imposibilitar el uso; y
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                (c) uso indebido debido a manipulación por malware o uso no
                autorizado, incluso en caso de pérdida o robo del dispositivo
                del Usuario utilizado para acceder al Sitio o a la Plataforma.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Tenemos derecho a bloquear o inhabilitar el uso del Sitio en
                dispositivos finales si las características de seguridad ideadas
                por el sistema operativo o el fabricante de dicho dispositivo en
                el que está instalado el Sitio han sido modificadas en algún
                momento (por ejemplo, un dispositivo que ha sido "jailbroken").
                En consecuencia, no garantizamos el funcionamiento y la
                operatividad de la App en dispositivos finales que hayan sido
                modificados de esta forma o en dispositivos finales más antiguos
                que ya no cumplan los requisitos técnicos para el uso del Sitio
                o el acceso a la Plataforma.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                QUEDAN EXPRESAMENTE EXCLUIDAS TODAS LAS GARANTÍAS, CONDICIONES O
                TÉRMINOS (YA SEAN EXPRESOS, IMPLÍCITOS, LEGALES O DE OTRO TIPO),
                INCLUIDOS, ENTRE OTROS, LOS RELATIVOS A LA CALIDAD,
                COMERCIABILIDAD, IDONEIDAD PARA UN FIN O ACCESO ININTERRUMPIDO Y
                LIBRE DE ERRORES, PARA EL SITIO Y LA PLATAFORMA EN LA MÁXIMA
                MEDIDA PERMITIDA POR LA LEY.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                No puede darse ninguna declaración ni garantía, expresa o
                implícita, sobre la exactitud o integridad de la información
                proporcionada en la Plataforma.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Cada Usuario reconoce y acepta los riesgos que puedan derivarse
                de la experiencia de viaje. También podemos utilizar servidores
                y otros equipos informáticos situados en cualquier jurisdicción
                del mundo para la prestación de cualquier parte de la
                Plataforma.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Excluimos toda responsabilidad por pérdidas o daños causados por
                errores de transmisión, fallos técnicos, averías, interrupciones
                de la actividad o intervenciones ilegales en las redes de
                transmisión, sistemas informáticos/ordenadores del Usuario o de
                terceros (incluidos los sistemas de dominio público).
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Terminación</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Podemos cancelar o suspender su Cuenta inmediatamente, sin
                previo aviso ni responsabilidad, por cualquier motivo,
                incluyendo, sin limitación, si incumple estos Términos y
                Condiciones.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                En caso de rescisión, su derecho a utilizar el Servicio cesará
                inmediatamente. Si desea cancelar Su Cuenta, puede simplemente
                dejar de utilizar el Servicio.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Publique</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                En la medida máxima permitida por la legislación aplicable, por
                el presente documento usted nos exime, absuelve y libera de
                cualquier otro modo a nosotros, a nuestra empresa matriz,
                filiales y subsidiarias y a cada uno de sus respectivos
                directivos, directores, accionistas, miembros, socios, abogados,
                empleados, contratistas independientes, proveedores de
                telecomunicaciones y agentes (colectivamente, las "Partes
                indemnizadas"), de todas y cada una de las alegaciones, cargos,
                acusaciones, deudas, causas de acción, reclamaciones y pérdidas,
                relacionadas de cualquier modo con el uso o las actividades
                relacionadas con el uso del Sitio, la Plataforma, cualquier
                Cuenta y cualquier servicio o Contenido de Terceros
                proporcionado a través del Sitio, la Plataforma o cualquier
                Cuenta, incluidas, a título meramente enunciativo y no
                limitativo, las reclamaciones relacionadas con lo siguiente
                negligencia, negligencia grave, interferencia intencionada con
                el contrato o la relación comercial ventajosa, difamación,
                privacidad, publicidad, tergiversación, identidades falsas,
                actos fraudulentos de terceros, invasión de la privacidad,
                divulgación de Información Personal, transacciones, compras o
                funcionalidad fallidas de la Plataforma, indisponibilidad del
                Sitio, la Plataforma, el Contenido de Terceros o cualquier
                Cuenta, sus funciones y cualquier otro fallo técnico que pueda
                resultar en la inaccesibilidad al Sitio, la Plataforma, los
                Contenidos de Terceros o cualquier Cuenta, o cualquier
                reclamación basada en responsabilidad indirecta por agravios
                cometidos por usted encontrados o transaccionados a través del
                Sitio, la Plataforma, los Contenidos de Terceros y cualquier
                Cuenta, incluyendo, pero sin limitarse a, fraude, piratería
                informática, robo o uso indebido de Información Personal,
                asalto, agresión, acoso, violación, engaño, perjurio, homicidio
                involuntario o asesinato. La lista anterior pretende ser
                meramente ilustrativa y no exhaustiva de los tipos o categorías
                de reclamaciones liberadas por nosotros. Las partes pretenden
                que esta exoneración se interprete en sentido amplio a nuestro
                favor, por lo que cualquier ambigüedad se interpretará de forma
                que se exonere de las reclamaciones más amplias. Esta
                exoneración pretende ser una exoneración completa de
                reclamaciones, y las partes reconocen la naturaleza legalmente
                vinculante de esta disposición, y la naturaleza de los derechos
                a los que se renuncia en relación con la misma.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Limitación de responsabilidad</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                SafeTravy no es responsable de la información disponible a
                través de los productos SafeTravy o integrada en ellos. En
                consecuencia, SafeTravy no se hace responsable de la exactitud,
                integridad, calidad, legalidad y fiabilidad de la información ni
                de los resultados que se obtengan mediante dicha información.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Los Servicios SafeTravy no constituyen herramientas de uso y
                apoyo a la gestión y evaluación de riesgos. SafeTravy no puede
                garantizar que toda la información y las actualizaciones sean
                completas, adecuadas y estén libres de errores. Los usuarios
                siempre deben evaluar los resultados y los datos del servicio de
                forma individual. SafeTravy pretende reducir el riesgo para los
                usuarios, pero en ningún caso será responsable de la seguridad
                de los usuarios y SafeTravy no asume ninguna responsabilidad por
                pérdidas, lesiones corporales o muerte de los usuarios.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                EN NINGÚN CASO Y BAJO NINGUNA TEORÍA JURÍDICA (EXTRACONTRACTUAL,
                CONTRACTUAL, DE RESPONSABILIDAD OBJETIVA O DE OTRO TIPO), NI
                NOSOTROS NI NINGUNO DE LOS INDEMNIZADOS SEREMOS RESPONSABLES
                ANTE USTED NI ANTE NINGUNA OTRA PERSONA POR NINGÚN DAÑO DERIVADO
                DEL USO O MAL USO, O DE LA IMPOSIBILIDAD DE USO, DE LA
                PLATAFORMA, EL SITIO, LOS CONTENIDOS DE TERCEROS O CUALQUIER
                CUENTA, INDEPENDIENTEMENTE DE QUE DICHOS DAÑOS SEAN DIRECTOS,
                INDIRECTOS, ESPECIALES, FORTUITOS O CONSECUENTES DE CUALQUIER
                CARÁCTER, PÉRDIDA DE INFORMACIÓN, INTERRUPCIÓN DE LA ACTIVIDAD
                EMPRESARIAL O LUCRO CESANTE, PÉRDIDA DE AHORROS O PÉRDIDA DE
                DATOS, O RESPONSABILIDADES BAJO CUALQUIER CONTRATO, NEGLIGENCIA,
                RESPONSABILIDAD ESTRICTA U OTRA TEORÍA QUE SURJA DE O ESTÉ
                RELACIONADA DE CUALQUIER MANERA CON EL SITIO, LA PLATAFORMA, EL
                CONTENIDO DE TERCEROS O CUALQUIER CUENTA O POR CUALQUIER
                RECLAMACIÓN O DEMANDA DE CUALQUIER TERCERO, INCLUSO SI SABÍAMOS
                O TENÍAMOS RAZONES PARA SABER DE LA POSIBILIDAD DE TALES DAÑOS,
                RECLAMACIÓN O DEMANDA SI LA RENUNCIA DE RESPONSABILIDAD ANTERIOR
                SE CONSIDERARA INVÁLIDA O INEFICAZ. ALGUNAS JURISDICCIONES NO
                PERMITEN LA EXCLUSIÓN O LIMITACIÓN DE DETERMINADAS GARANTÍAS Y/O
                RESPONSABILIDADES, POR LO QUE ES POSIBLE QUE ALGUNAS DE LAS
                LIMITACIONES O EXCLUSIONES ANTERIORES NO SEAN APLICABLES EN SU
                CASO.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                EN NINGÚN CASO NUESTRA RESPONSABILIDAD, INDEPENDIENTEMENTE DE LA
                FORMA DE LA ACCIÓN Y DE LOS DAÑOS SUFRIDOS POR USTED, EXCEDERÁ
                LAS TARIFAS DE SUSCRIPCIÓN AGREGADAS MÁS ALTAS PAGADAS POR USTED
                A NOSOTROS EN RELACIÓN CON LA PLATAFORMA, O EL SITIO, O 300 EUR
                ESTADOUNIDENSES, LO QUE SEA MAYOR.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                No seremos responsables del incumplimiento de cualquiera de
                nuestras obligaciones en virtud de las presentes Condiciones
                debido a acontecimientos que escapen a nuestro control, y el
                plazo previsto para el cumplimiento de dichas obligaciones se
                prorrogará por un período de tiempo igual a la duración de tales
                acontecimientos. Los acontecimientos que escapan a nuestro
                control incluyen, entre otros, casos fortuitos, guerras,
                disturbios, incendios provocados, embargos, conmociones civiles,
                huelgas, conflictos laborales, fallos en los equipos, fallos
                bancarios, colapso o fluctuaciones del mercado de divisas
                virtuales, fallos en el procesamiento de transacciones con
                tarjeta de crédito o débito, huelgas, incendios, inundaciones,
                terremotos, huracanes, tormentas tropicales u otros desastres
                naturales o siniestros, escasez de mano de obra o material,
                escasez de transporte, instalaciones, combustible, energía,
                regulaciones o restricciones gubernamentales, actos de autoridad
                civil o militar o terrorismo, cortes de fibra, condiciones
                meteorológicas, infracciones o incumplimientos por parte de
                terceros, problemas técnicos, incluidos bloqueos de hardware y
                software y otros fallos de funcionamiento, fallos de la
                infraestructura de telecomunicaciones o servicios de
                información, piratería informática, SPAM o fallos de cualquier
                ordenador, servidor o interrupciones de software a causa o
                provocados por vandalismo, robo, cortes del servicio telefónico,
                cortes de electricidad, interrupciones de Internet, virus y
                fallos mecánicos, eléctricos o de comunicaciones.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Modificaciones de las presentes condiciones</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Nos reservamos el derecho, a nuestra entera discreción, de
                modificar o sustituir estas Condiciones en cualquier momento. Si
                una revisión es sustancial, haremos todo lo razonablemente
                posible para notificarlo con al menos 30 días de antelación a la
                entrada en vigor de los nuevos términos. Lo que constituya un
                cambio sustancial se determinará a nuestra entera discreción.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Al continuar accediendo o utilizando nuestro Servicio después de
                que dichas revisiones entren en vigor, usted acepta quedar
                vinculado por los términos revisados. Si no está de acuerdo con
                las nuevas condiciones, en su totalidad o en parte, deje de
                utilizar el sitio web y el Servicio.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>General</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Las presentes Condiciones, incluida la Política de privacidad y
                otras políticas incorporadas en el presente documento,
                constituyen el acuerdo íntegro y único entre usted y la Empresa
                en relación con el objeto de estas Condiciones, y sustituyen a
                todos y cada uno de los acuerdos, manifestaciones, garantías y
                entendimientos anteriores o contemporáneos, escritos u orales,
                en relación con el objeto de estas Condiciones. Si alguna de las
                disposiciones de estas Condiciones resultara ilegal, nula o
                inaplicable por cualquier motivo, dicha disposición se
                considerará separable de estas Condiciones y no afectará a la
                validez y aplicabilidad de las disposiciones restantes. La
                Empresa no podrá cambiar, renunciar ni modificar las presentes
                Condiciones salvo en los casos previstos en el presente
                documento. Usted no podrá ceder, transferir, delegar ni
                sublicenciar ninguna de las presentes Condiciones, ni ningún
                derecho, obligación o recurso derivados de las mismas, salvo con
                nuestro consentimiento previo por escrito, y cualquier intento
                de cesión, transferencia, delegación o sublicencia será nulo y
                sin efecto. Ninguna renuncia por cualquiera de las partes de
                cualquier incumplimiento o falta en virtud del presente se
                considerará una renuncia de cualquier incumplimiento o falta
                anterior o posterior. Cualquier encabezamiento, título o título
                de sección contenidos en estas Condiciones se insertan
                únicamente por comodidad y no definen ni explican en modo alguno
                ninguna sección o disposición de las mismas.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Legislación aplicable y resolución de litigios</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Todas las disputas, desacuerdos o reclamaciones que surjan en
                virtud del presente acuerdo o en relación con el mismo,
                incluidas las relativas a su celebración, interpretación,
                ejecución, violación, rescisión o invalidez, serán resueltas por
                el Tribunal de Arbitraje Comercial Internacional de la Cámara de
                Comercio e Industria de Ucrania de conformidad con su
                Reglamento.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                La ley que rige este acuerdo es la ley sustantiva de Gibraltar.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                El Tribunal de Arbitraje está compuesto por un árbitro único. El
                lugar de reunión del Tribunal de Arbitraje es la ciudad de Kyiv,
                Ucrania. El idioma de los procedimientos de arbitraje es el
                inglés o el ucraniano.
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                <b>Contacte con nosotros</b>
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Si tiene alguna pregunta sobre estas Condiciones, puede ponerse
                en contacto con nosotros:
            </p>
            <p
                style={{
                    lineHeight: '125%',
                    textAlign: 'justify',
                    marginTop: '0.17in',
                    marginBottom: '0.17in',
                }}>
                Por correo electrónico:
                <a href='mailto:team@safetravy.com'>
                    <u>team@safetravy.com</u>
                </a>
            </p>
        </>
    )
}
