import { AccountGroupWidget, SearchCity, SearchCountry } from '@/core/widgets'
import styles from './styles.module.css'
import { AuthGroupAtom } from './atoms'
import { useNavigate } from 'react-router-dom'
import { useResizeWindow, useSession } from '@/core/hooks'
import { AuthStep } from '@/modules/auth/typing'
import { useEffect, useMemo, useState } from 'react'
import { useAccountStore, useCategoryRiskStore } from '@/store'
import { CategoryRisks, SubcategoryRisks } from '@/core/typing'
import { Icon } from '../../icons'

export const Header = () => {
    const navigate = useNavigate()
    const { token } = useSession()
    const { account } = useAccountStore()
    const { active } = useCategoryRiskStore()
    const { width: widthWindow } = useResizeWindow()
    const [showSearch, setShowSearch] = useState(false)
    const isOpenBar: boolean = JSON.parse(localStorage.getItem('BAR'))

    const headerGroup = useMemo(() => {
        if (token) {
            return <AccountGroupWidget />
        }
        return (
            <AuthGroupAtom
                goSignIn={() =>
                    navigate('auth', { state: { step: AuthStep.Login } })
                }
                goSignUp={() =>
                    navigate('auth', {
                        state: { step: AuthStep.Register },
                    })
                }
            />
        )
    }, [token, open, account])

    useEffect(() => {
        setShowSearch(false)
    }, [active])

    const searchGroup = (element: JSX.Element) => {
        if (showSearch) {
            return (
                <>
                    {element}
                    <Icon
                        className={styles.close_search_icon}
                        onClick={() => setShowSearch(false)}
                        name={'x'}
                        size={16}
                        color='#AFB1B6'
                    />
                </>
            )
        }
        return (
            <div
                className={styles.search}
                onClick={() => setShowSearch(!showSearch)}>
                <Icon name='search-normal' size={20} color='#5E626D' />
            </div>
        )
    }
    const searchCountry = useMemo(() => {
        if (
            !account ||
            (account && !account?.subscription) ||
            active?.category === CategoryRisks.Requirements ||
            active?.subcategory === SubcategoryRisks.SeaLevelRise
        ) {
            return null
        } else if (active?.subcategory === SubcategoryRisks.Weather) {
            return searchGroup(<SearchCity />)
        }
        return searchGroup(<SearchCountry />)
    }, [account, active, widthWindow, isOpenBar, showSearch])

    return (
        <>
            <div className={styles.container}>
                <div className={styles.layout}>
                    <>{searchCountry}</>
                    <>{headerGroup}</>
                </div>
            </div>
        </>
    )
}
