import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'

export const ContactUs = () => {
    const { t } = useTranslation()

    const sendEmail = () => {
        window.location.href = `mailto:support@safetravy.com`
    }
    return (
        <div>
            <p className={styles.contact_us_title}>{t('contactUsTitle')}</p>
            <p onClick={sendEmail} className={styles.contact_us_item}>
                support@safetravy.com
            </p>
        </div>
    )
}
