import styles from './styles.module.css'
import {
    AdvantesAtom,
    HeaderTeariffAtom,
    InterectiveBenefitsAtom,
    PriceAtom,
} from './atoms'
import hatSvg from '@/assets/images/hat-45.svg'
import { useTranslation } from 'react-i18next'

interface IProps {
    label: string
    isTrial: boolean
    price: string
    onClick: () => void
    advantes: string[]
    trialPeriod: string
    labelBtn: string
    isActive?: boolean
    includeBasic?: boolean
    type?: string
    isPrimary?: boolean
    isHaveHat?: boolean
}

export const Tariff = ({
    label,
    price,
    isPrimary,
    isHaveHat,
    type,
    onClick,
    advantes,
    includeBasic,
    trialPeriod,
    labelBtn,
    isActive,
    isTrial,
}: IProps) => {
    const { t } = useTranslation()

    const hat = () => {
        if (!isHaveHat) return null
        return <img src={hatSvg} className={styles.hat} />
    }
    return (
        <div className={`${styles.container} ${isActive ? styles.active : ''}`}>
            <>{hat()}</>
            <div className={styles.top_container}>
                <HeaderTeariffAtom label={label} type={type} />
                <div className={styles.full_benefits}>
                    <AdvantesAtom list={advantes} includeBasic={includeBasic} />
                </div>
                <InterectiveBenefitsAtom
                    includeBasic={includeBasic}
                    advantes={advantes}
                />
            </div>

            <PriceAtom
                hibeBtn={isActive && !isTrial}
                label={isTrial && isActive ? t('fullSubsctiption') : labelBtn}
                trialPeriod={trialPeriod}
                price={price}
                type={isPrimary ? 'full' : 'outlined'}
                onCklick={onClick}
            />
        </div>
    )
}
