import { useCallback, useState } from 'react'
import styles from './style.module.css'
import { InitConfig } from '@solidgate/react-sdk'
import { IApplyCouponError, IPaymentForm } from '../../typing/interfaces'
import { message } from 'antd'
import { PaymentCheckount } from '../../widgets'
import { useEventsListener } from '@/core/hooks'
import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import { useAccountStore } from '@/store'

export const PaymentPage = () => {
    const { account } = useAccountStore()
    const [merchantData, setMerchantData] =
        useState<InitConfig['merchantData']>(null)
    const [isShow, setIsShow] = useState(false)
    const [typeSubscription, setSubscriptionType] = useState<SubscriptionType>()

    useEventsListener('payment', data => {
        setMerchantData(data.intent)
        setSubscriptionType(data.subscription)
        setIsShow(data.isShow)
    })

    const [paymentForm, setPaymentForm] = useState<IPaymentForm>(null)

    const handleOnReadyPaymentInstance = useCallback((form: IPaymentForm) => {
        setPaymentForm(form)
    }, [])

    const applyCode = async (val: string) => {
        try {
            await paymentForm.applyCoupon(val)
            message.success(`Coupon applied`)
        } catch (e) {
            const error = e as IApplyCouponError
            if (error.details) message.error(error.details.message)
        }
    }
    if (!isShow) return null
    return (
        <div className={styles.container}>
            {merchantData && (
                <PaymentCheckount
                    canUsePromo={account?.canUseTrial}
                    applyCode={applyCode}
                    paymentInstance={handleOnReadyPaymentInstance}
                    merchantData={merchantData}
                    subscriptionPlan={typeSubscription}
                />
            )}
        </div>
    )
}
