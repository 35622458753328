import {
    SubscriptionAgreementModal,
    Tariff,
    UnpaidSubscriptionModal,
} from '../../components'
import styles from './styles.module.css'
import fullLogo from '@/assets/images/full_logo.png'
import { AnimationLoader, Icon } from '@/core/components'
import {
    base,
    basic3Month,
    premium,
    premium3Month,
    subscribeLabel,
} from '../../configs/advantes.config'
import { useState } from 'react'
import { useEventsListener } from '@/core/hooks'
import { appEvents } from '@/core/events'
import { saveSubscribeV2Req } from '../../api'
import { SubscriptionStatus, SubscriptionType } from '../../typing/enums'
import { useAccount } from '@/modules/user/hooks'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'
import { sendEvent } from '@/core/services'
import { useLanguageStore } from '@/store'

export const Subscribe = () => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()
    const [subscriptionAgreement, setSubscriptionAgreement] = useState<{
        isOpen: boolean
        subscriptionType: SubscriptionType
    }>(null)
    const [unpaidModal, setUnpaidModal] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const [modal, setModal] = useState<{
        isShow: boolean
        method: 'buy'
        isUpdate?: boolean
    }>({
        isShow: false,
        method: 'buy',
    })

    const { account } = useAccount()

    const close = () =>
        appEvents.emit('subsribe', {
            isShow: false,
            method: null,
        })

    useEventsListener(
        'subsribe',
        (data: any) => {
            setModal({
                isShow: data?.isShow,
                method: data?.method,
                isUpdate: data?.isUpdate,
            })

            if (data?.isShow) {
                sendEvent('subscribes', {
                    view_subscription_plans: data?.isShow,
                })
            }
        },

        [],
    )

    const chooseSubscribe = async (val: SubscriptionType) => {
        try {
            setIsLoading(true)
            const { data } = await saveSubscribeV2Req({
                subscriptionType: val,
                lang,
            })
            setIsLoading(false)
            if (data?.intent) {
                setSubscriptionAgreement({
                    isOpen: false,
                    subscriptionType: null,
                })
                appEvents.emit('payment', {
                    isShow: true,
                    intent: data?.intent,
                    subscription: val,
                })
            }

            if (data?.url) {
                window.open(data.url, '_self')
            }
            close()
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    const handleCliskTariff = async (val: SubscriptionType, cost: string) => {
        sendEvent('selected_subscription', {
            subscription_plan: subscribeLabel[val],
            subscription_price: cost,
        })

        if (account?.canUseTrial) {
            setSubscriptionAgreement({
                isOpen: true,
                subscriptionType: val,
            })
        } else if (
            account?.subscription?.status === SubscriptionStatus.Unpaid
        ) {
            setUnpaidModal(true)
        } else {
            await chooseSubscribe(val)
        }
    }

    const isTrial = account?.subscription?.status === SubscriptionStatus.Trial

    const labelBtn = t('buy')

    if (!modal.isShow) return null
    return (
        <div
            className={styles.container}
            style={{ overflow: isLoading ? 'hidden' : 'auto' }}>
            <div className={styles.logo_container}>
                <img
                    src={fullLogo}
                    style={{
                        height: 38,
                    }}
                />
            </div>

            <Icon
                name='x'
                size={24}
                color='#000000'
                onClick={close}
                className={styles.close}
            />
            <div style={{ position: 'relative' }}>
                {isLoading ? <AnimationLoader /> : null}

                <p className={styles.title}>{t('titleSubscribes')}</p>
                <div className={styles.subscribes}>
                    <Tariff
                        isTrial={isTrial}
                        isActive={
                            account?.subscription?.type ===
                                SubscriptionType.Basic_3m && modal?.isUpdate
                        }
                        labelBtn={labelBtn}
                        trialPeriod={t('freeTrial')}
                        advantes={basic3Month}
                        label={t('basic3')}
                        type={t('popular')}
                        isPrimary={false}
                        price='7,99'
                        onClick={() =>
                            handleCliskTariff(SubscriptionType.Basic_3m, '7,99')
                        }
                    />

                    <Tariff
                        isTrial={isTrial}
                        isActive={
                            account?.subscription?.type ===
                                SubscriptionType.Basic && modal?.isUpdate
                        }
                        labelBtn={labelBtn}
                        trialPeriod={t('freeTrial')}
                        advantes={base}
                        label={t('basic')}
                        isPrimary={false}
                        price='8,99'
                        onClick={() =>
                            handleCliskTariff(SubscriptionType.Basic, '8,99')
                        }
                    />

                    <Tariff
                        isTrial={isTrial}
                        isActive={
                            account?.subscription?.type ===
                                SubscriptionType.Premium_3m && modal?.isUpdate
                        }
                        labelBtn={labelBtn}
                        trialPeriod={t('freeTrial')}
                        includeBasic
                        advantes={premium3Month}
                        isHaveHat={true}
                        label={t('extra3')}
                        type={t('popular')}
                        isPrimary={true}
                        price='9,99'
                        onClick={() =>
                            handleCliskTariff(
                                SubscriptionType.Premium_3m,
                                '9,99',
                            )
                        }
                    />

                    <Tariff
                        isTrial={isTrial}
                        isActive={
                            account?.subscription?.type ===
                                SubscriptionType.Premium && modal.isUpdate
                        }
                        labelBtn={labelBtn}
                        trialPeriod={t('freeTrial')}
                        includeBasic
                        advantes={premium}
                        label={t('extra')}
                        isPrimary={true}
                        price='10,99'
                        onClick={() =>
                            handleCliskTariff(SubscriptionType.Premium, '10,99')
                        }
                    />
                </div>
            </div>
            <SubscriptionAgreementModal
                isOpen={subscriptionAgreement?.isOpen}
                close={() =>
                    setSubscriptionAgreement({
                        isOpen: false,
                        subscriptionType: null,
                    })
                }
                confirm={() =>
                    chooseSubscribe(subscriptionAgreement?.subscriptionType)
                }
            />
            <UnpaidSubscriptionModal
                isOpen={unpaidModal}
                close={() => setUnpaidModal(false)}
            />
        </div>
    )
}
